import { useFormik } from "formik";
import { useEffect, useState } from "react";
import ApiClient, { IUser } from "../../../../../services/apiclient";
import UploadBox from "../../../credit-forms/components/uploadBox";
import { FORM_SECTION_2 } from "../forms";
import Paper from "../../../../../assets/paper.png";
import { FileUpload } from "../../../../../interfaces/fileInterace";
import AlertMessage from "../../../alertNomina.component";
import Error from "../../../../../assets/error.png";
import GreenChecked from "../../../../../assets/green-cheked.png";
import Warning from "../../../../../assets/warning.png";
import React from "react";
import { UserContext } from "../../../../../context/UserProvider";
import { ProgressCircular } from "../../Progress";

export default function PersonalForm({
  callback: sendData,
  setPersonalData,
  personaldata,
  loading,
  firstFile,
  secondFile,
}) {
  const apiClient = ApiClient.getInstance();
  const formSection2 = useFormik(FORM_SECTION_2);
  const { userMain, updateUser } = React.useContext(UserContext);

  const [paises, setPaises] = useState([]);
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [estados, setEstados] = useState([]);
  const [userData, setUserData] = useState<IUser>({
    birthDate: "",
    email: "",
    firstName: "",
    lastFName: "",
    lastMName: "",
    phoneNumber: userMain.telefono,
    sex: "",
    userId: "",
    employeeNumber: "",
  });
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [error, setError] = useState(false);
  const [incomplete, setIncomplete] = useState(true);

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  useEffect(() => {
    apiClient.getCountries().then((data) => setPaises(data.countries));
    apiClient.getCountryStates().then((data) => setEstados(data.states));
    apiClient.getUserData().then((data) => {
      setUserData(data);
    });
    apiClient.getEmpleadoNumber().then((res) => {
      setEmployeeNumber(res.employeeCode);
    });
  }, []);

  const submitData = () => {
    if (!formSection2.isValid) {
      Object.keys(formSection2.errors).forEach((key) => {
        setMessage(formSection2.errors[key] as string);
        setSeverity("error");
        setOpenMessage(true);
      });
      setPersonalData(undefined);
      setError(true);
      setIncomplete(true);
      return;
    }

    if (Object.values(formSection2.values).every((value) => value === "")) {
      setMessage("Los datos de dirección son obligatorios.");
      setSeverity("error");
      setOpenMessage(true);
      setIncomplete(true);
      setError(false);
      setPersonalData(undefined);
      return;
    }
    console.log("formSection2.values: ", formSection2.values);
    setPersonalData(formSection2.values);
  };
  useEffect(() => {
    if (personaldata?.rfc !== "") {
      sendData();
    }
    console.log(personaldata, "form");
  }, [personaldata]);

  useEffect(() => {
    apiClient.getUserPreInfo().then((data) => {
      if (!data.error) {
        formSection2.setFieldValue("rfc", data.rfc);
        formSection2.setFieldValue("nationality", data.nationality);
        formSection2.setFieldValue("educationLevel", data.educationLevel);
        formSection2.setFieldValue("countryOfBirth", data.countryOfBirth);
        formSection2.setFieldValue("stateOfBirth", data.stateOfBirth);
        formSection2.setFieldValue(
          "economicDependents",
          data.economicDependents
        );
        //formSection2.setFieldValue('identificationNumber',data.identificationNumber)
        formSection2.setFieldValue("employeeNumber", data.employeeNumber);
        formSection2.setFieldValue("civilStatus", data.civilStatus);
        formSection2.setFieldValue("curp", data.curp);
      }
    });
  }, []);

  return (
    <div className="solicitud-b__container">
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />

      <div className="incisio-header">
        <div className="inciso-header-text"></div>
        {error && collapsed ? (
          <img src={Error} alt="Error icon" />
        ) : !error && collapsed && incomplete ? (
          <img src={Warning} alt="Warning icon" />
        ) : !error && collapsed && !incomplete ? (
          <img src={GreenChecked} alt="Success icon" />
        ) : (
          ""
        )}
      </div>
      <div className="solicitud-4__input-group informacionC">
        <div>
          <label htmlFor="firstName">Nombre(s) *</label>
          <input
            tabIndex={2}
            type="text"
            id="firstName"
            name="firstName"
            placeholder="Ejemplo: Humberto"
            disabled
            value={userData.firstName}
          />
        </div>

        <div>
          <label htmlFor="Segundo nombre">Segundo nombre(s) *</label>
          <input
            tabIndex={2}
            type="text"
            id="secondName"
            name="secondName"
            placeholder="Ejemplo: Humberto"
            disabled
            value={userData.secondName}
          />
        </div>

        <div>
          <label htmlFor="apellidoPaterno">Apellido Paterno *</label>
          <input
            tabIndex={5}
            type="text"
            id="apellidoPaterno"
            name="apellidoPaterno"
            placeholder="Ej."
            disabled
            value={userData.lastFName}
          />
        </div>

        <div>
          <label htmlFor="apellidoMaterno">Apellido Materno *</label>
          <input
            tabIndex={6}
            type="text"
            id="apellidoMaterno"
            name="apellidoMaterno"
            placeholder="Ej."
            disabled
            value={userData.lastMName}
          />
        </div>

        <div>
          <label htmlFor="sex">Genero *</label>
          <select
            tabIndex={7}
            className="input-select"
            id="sex"
            name="sex"
            disabled
            value={userData.sex}
          >
            <option value="" disabled>
              Selecciona una opción
            </option>
            <option value="Masculino">Masculino</option>
            <option value="Femenino">Femenino</option>
          </select>
        </div>

        <div>
          <label htmlFor="nationality">Nacionalidad *</label>
          <select
            tabIndex={8}
            className="input-select"
            id="nationality"
            name="nationality"
            onChange={formSection2.handleChange}
            value={formSection2.values.nationality}
          >
            <option value="">Seleccione una opción</option>
            <option value="MX">Mexicana</option>
          </select>
        </div>

        <div>
          <label htmlFor="fechaNacimiento">Fecha Nacimiento *</label>
          <input
            tabIndex={9}
            type="date"
            id="fechaNacimiento"
            name="fechaNacimiento"
            defaultValue={userData.birthDate.substr(0, 10)}
            disabled
          />
        </div>

        <div>
          <label htmlFor="rfc">RFC *</label>
          <input
            tabIndex={2}
            type="text"
            id="rfc"
            name="rfc"
            onChange={(e) => {
              const value = e.target.value || "";
              formSection2.setFieldValue(
                "rfc",
                value
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toUpperCase()
              );
            }}
            value={formSection2.values.rfc}
            placeholder="Ejemplo: PARUOA9308ASFHG"
          />
        </div>

        <div>
          <label htmlFor="email">Correo Electrónico *</label>
          <input
            tabIndex={3}
            type="text"
            id="email"
            name="email"
            onChange={formSection2.handleChange}
            value={userData.email}
            placeholder="Ejemplo: probenso@fin.com"
            disabled
          />
        </div>

        <div>
          <label htmlFor="phoneNumber">Número Teléfono *</label>
          <input
            tabIndex={4}
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Ejemplo: 9999999999"
            pattern="[0-9]{0,10}"
            value={userData.phoneNumber}
            disabled
          />
        </div>

        <div>
          <label htmlFor="curp">CURP *</label>
          <input
            tabIndex={1}
            type="text"
            id="curp"
            name="curp"
            onChange={(e) => {
              const value = e.target.value || "";
              formSection2.setFieldValue(
                "curp",
                value
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toUpperCase()
              );
            }}
            value={formSection2.values.curp}
            placeholder="Ejemplo: PARUOA9308ASFHG"
          />
        </div>

        <div>
          <label htmlFor="stateOfBirth">Estado de Nacimiento *</label>
          <select
            tabIndex={5}
            className="input-select"
            id="stateOfBirth"
            name="stateOfBirth"
            onChange={formSection2.handleChange}
            value={formSection2.values.stateOfBirth}
          >
            <option value="">Seleccione un estado</option>
            <option value="AGUASCALIENTES">AGUASCALIENTES</option>
            <option value="BAJA CALIFORNIA">BAJA CALIFORNIA</option>
            <option value="BAJA CALIFORNIA SUR">BAJA CALIFORNIA SUR</option>
            <option value="CAMPECHE">CAMPECHE</option>
            <option value="CHIAPAS">CHIAPAS</option>
            <option value="CHIHUAHUA">CHIHUAHUA</option>
            <option value="COAHUILA DE ZARAGOZA">COAHUILA DE ZARAGOZA</option>
            <option value="COLIMA">COLIMA</option>
            <option value="DISTRITO FEDERAL">DISTRITO FEDERAL</option>
            <option value="DURANGO">DURANGO</option>
            <option value="GUANAJUATO">GUANAJUATO</option>
            <option value="GUERRERO">GUERRERO</option>
            <option value="HIDALGO">HIDALGO</option>
            <option value="JALISCO">JALISCO</option>
            <option value="MÉXICO">MÉXICO</option>
            <option value="MICHOACÁN DE OCAMPO">MICHOACÁN DE OCAMPO</option>
            <option value="MORELOS">MORELOS</option>
            <option value="NAYARIT">NAYARIT</option>
            <option value="NUEVO LEÓN">NUEVO LEÓN</option>
            <option value="OAXACA">OAXACA</option>
            <option value="PUEBLA">PUEBLA</option>
            <option value="QUERÉTARO">QUERÉTARO</option>
            <option value="QUINTANA ROO">QUINTANA ROO</option>
            <option value="SAN LUIS POTOSÍ">SAN LUIS POTOSÍ</option>
            <option value="SINALOA">SINALOA</option>
            <option value="SONORA">SONORA</option>
            <option value="TABASCO">TABASCO</option>
            <option value="TAMAULIPAS">TAMAULIPAS</option>
            <option value="TLAXCALA">TLAXCALA</option>
            <option value="VERACRUZ DE IGNACION DE LA LLAVE">
              VERACRUZ DE IGNACION DE LA LLAVE
            </option>
            <option value="YUCATÁN">YUCATÁN</option>
            <option value="ZACATECAS">ZACATECAS</option>
          </select>
        </div>

        <div>
          <label htmlFor="countryOfBirth">País de nacimiento *</label>
          <select
            tabIndex={6}
            className="input-select"
            id="countryOfBirth"
            name="countryOfBirth"
            onChange={formSection2.handleChange}
            value={formSection2.values.countryOfBirth}
          >
            <option value="">Seleccione un país</option>
            {paises.map((pais: any) => (
              <option key={pais} value={pais}>
                {pais.toUpperCase()}
              </option>
            ))}
          </select>
        </div>

        {/* <div>
              <label htmlFor="IDType">
                Tipo de identificación
              </label>
              <select
                id="IDType"
                name="IDType"
                className="input-select"
                onChange={formSection2.handleChange}
                value={formSection2.values.IDType}
                required
              >
                <option value="">Selecciona una opcion</option>
                <option value="INE">INE</option>
              </select>
            </div>

            <div>
              <label htmlFor="identificationNumber">
                Número de INE o IFE *
              </label>
              <input
                onChange={formSection2.handleChange}
                type="text"
                id="identificationNumber"
                name="identificationNumber"
              />
            </div> */}

        <div>
          <label htmlFor="civilStatus">Estado civil</label>
          <select
            id="civilStatus"
            tabIndex={7}
            name="civilStatus"
            className="input-select"
            onChange={formSection2.handleChange}
            value={formSection2.values.civilStatus}
          >
            <option value="">Seleccione una opción</option>
            <option value="Soltero">Soltero</option>
            <option value="Casado">Casado</option>
            <option value="Divorciado">Divorciado</option>
            <option value="Separado">Separado</option>
            <option value="Soltero">Soltero</option>
            <option value="Unión Libre">Unión Libre</option>
            <option value="Viudo">Viudo</option>
            <option value="Desconocido">Desconocido</option>
          </select>
        </div>

        <div>
          <label htmlFor="educationLevel">Nivel de estudios</label>
          <select
            id="educationLevel"
            tabIndex={8}
            className="input-select"
            name="educationLevel"
            onChange={formSection2.handleChange}
            value={formSection2.values.educationLevel}
          >
            <option value="">Seleccione una opción</option>
            <option value="Ninguna">Ninguna</option>
            <option value="Primaria">Primaria</option>
            <option value="Secundaria">Secundaria</option>
            <option value="Preparatoria">Preparatoria</option>
            <option value="Tecnico">Tecnico</option>
            <option value="Profesional">Profesional</option>
            <option value="Maestria">Maestria</option>
            <option value="Doctorado">Doctorado</option>
            <option value="Otros">Otros</option>
            <option value="Desconocido">Desconocido</option>
          </select>
        </div>

        {/* <div>
              <label htmlFor="employeeNumber">Número de Empleado</label>
              <input
                tabIndex={5}
                type="text"
                id="employeeNumber"
                name="employeeNumber"
                placeholder="Ej."
                disabled
                value={employeeNumber}
              />
            </div> */}
        {/*             
            <div>
              <label htmlFor="phoneNumber">Número Teléfono *</label>
              <input
                tabIndex={3}
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Ejemplo: 9999999999"
                pattern="[0-9]{0,10}"
                value={userData.phoneNumber}
              />
            </div> */}

        {/* <div>
              <label htmlFor="economicDependents">
                Dependientes Económicos *
              </label>
              <input
                tabIndex={13}
                type="text"
                id="economicDependents"
                name="economicDependents"
                onChange={formSection2.handleChange}
                value={formSection2.values.economicDependents}
                placeholder="Ej."
              />
            </div> */}
      </div>
      <div className="solicitud-4__input-group"></div>
      <div
        id="2"
        className={
          collapsed
            ? "solicitud-b__inciso-content collapsed"
            : "solicitud-b__inciso-content"
        }
      ></div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button className="blue" onClick={submitData} disabled={loading}>
          <ProgressCircular loading={loading} title="Continuar" />
        </button>
      </div>
    </div>
  );
}
