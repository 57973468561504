import UploadBox from "./components/uploadBox";
import "./documentos.css";
import creditCard from "../../../../../assets/creditcard.png";
import image from "../../../../../assets/image.png";
import { useState, useEffect } from "react";
import ApiClient from "../../../../../services/apiclient";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { ProgressCircular } from "../../../Progress";
import React from "react";
import { TramiteCreditoContext } from "../../../../../context/TramiteCreditoProvider";
import Popup from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import AlertMessage from "../../../../alert.component";

interface UserDocument {
  image: string;
  description: string;
  fileKey: string;
  setter: React.Dispatch<React.SetStateAction<File>>;
}

export default function Documentos({ userData1, productName, setProductName, prevStep }) {
  const { credito, updateCredito } = React.useContext(TramiteCreditoContext);

  const [complete, setComplete] = useState(false);
  const [titlePABS, setTitlePABS] = useState<File>();
  const [ifeIne, setIfeIne] = useState<File>();
  const [ifeReverso, setIfeReverso] = useState<File>();
  const [selfieIne, setSelfieIne] = useState<File>();
  const [proofAddress, setProofAddress] = useState<File>();
  const [proofIncome, setproofIncome] = useState<File>();
  const [loading, setLoading] = useState(false);

  /**
   * Iterable data for creating the upload boxes components
   */
  const data: UserDocument[] = [
    {
      image: creditCard,
      description: "Foto frontal de tu INE o IFE",
      fileKey: "ifeIne",
      setter: setIfeIne
    },
    {
      image: creditCard,
      description: "Foto trasera de tu INE o IFE",
      fileKey: "ifeReverso",
      setter: setIfeReverso
    },
    {
      image: image,
      description: "Selfie con tu INE/IFE",
      fileKey: "selfieIne",
      setter: setSelfieIne
    },
    {
      image: image,
      description: "Comprobante de domicilio",
      fileKey: "proofAddress",
      setter: setProofAddress
    },
    {
      image: image,
      description: "Comprobante de ingresos",
      fileKey: "proofIncome",
      setter: setproofIncome
    },
    {
      image: image,
      description: "Título PABS",
      fileKey: "titlePABS",
      setter: setTitlePABS
    },
  ];
  const files = new FormData();

  const apiClient = ApiClient.getInstance();

  const history = useHistory();

  useEffect(() => {

  }, []);

  /**
   * Callback that sets the complete state as true when all the files 
   * were loaded into memory.
   */
  const isComplete = () => {
    let found = 0;
    data.forEach((fileData) => {
      if (files.get(fileData.fileKey)) {
        found++;
      }
    });

    if (found >= 6) {
      setComplete(true);
    }
  };

  /**
   * Function that is executed when the user has uplaoded all files and 
   * wants to upload them into the server.
   */
  const onSubmit = () => {
    if (ifeIne == undefined || ifeReverso == undefined || selfieIne == undefined || proofAddress == undefined || proofIncome == undefined || titlePABS == undefined) {
      setOpenMessage(true);
      setMessage("Completar la informacion para poder continuar.");
      setSeverity("info");
      return;

    }
    if (avisoDePrivacidad == false) {
      setOpenMessage(true);
      setMessage("Para poder continuar necesitas aceptar el aviso de privacidad.");
      setSeverity("info");
      return;
    }
    setLoading(true);
    const body = new FormData();
    body.append('ifeIne', ifeIne);
    body.append('ifeReverso', ifeReverso);
    body.append('selfieIne', selfieIne);
    body.append('proofAddress', proofAddress);
    body.append('proofIncome', proofIncome);
    body.append('titlePABS', titlePABS);

    apiClient.uploadPabsFiles(body).then(async (_res) => {
      //      history.push('/step5');
      const activeCredit = await apiClient.getActiveCredit();
      const credit = await apiClient.getCredit(activeCredit.creditType);
      console.log('[uploadPabsFiles]: ', activeCredit, credit);
      updateCredito({
        ...credito,
        idPrestamo: credit.id,
        fecha_solicitud: credit.creationDate,
        isDelivered: credit.delivered,
        isApproved: credit.status === 'ACCEPTED' ? true : false,
        importe: credit.amount,
        estatusSolicitud: credit.status === 'IN_PROGRESS' ? 'revision' : credit.status === 'ACCEPTED' ? 'aprobado' : credit.status === 'DENIED' ? 'solicitud-no-aprobada' : 'sin-solicitud'
      });


      history.push('/proceso-credito');
      // handle upload logic
    }).catch(err => {
      console.error(err);
      setLoading(false);
      // handle error
    });

  }
  const [avisoDePrivacidad, setavisoDePrivacidad] = useState(false);
  const handleChange = () => {
    setavisoDePrivacidad(!avisoDePrivacidad);
  };

  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);

  };
  const [isPopupVisible, setPopupVisibility] = useState(false);

  const abrirModal = () => {
    if (avisoDePrivacidad === true) {
      if (ifeIne == undefined || ifeReverso == undefined || selfieIne == undefined || proofAddress == undefined || proofIncome == undefined || titlePABS == undefined) {
        setOpenMessage(true);
        setMessage("Completar la informacion para poder continuar.");
        setSeverity("info");
        return;
  
      }
      setLoading(true);
      const body = new FormData();
      body.append('ifeIne', ifeIne);
      body.append('ifeReverso', ifeReverso);
      body.append('selfieIne', selfieIne);
      body.append('proofAddress', proofAddress);
      body.append('proofIncome', proofIncome);
      body.append('titlePABS', titlePABS);
  
      apiClient.uploadPabsFiles(body).then(async (_res) => {
        //      history.push('/step5');
        const activeCredit = await apiClient.getActiveCredit();
        const credit = await apiClient.getCredit(activeCredit.creditType);
        console.log('[uploadPabsFiles]: ', activeCredit, credit);
        updateCredito({
          ...credito,
          idPrestamo: credit.id,
          fecha_solicitud: credit.creationDate,
          isDelivered: credit.delivered,
          isApproved: credit.status === 'ACCEPTED' ? true : false,
          importe: credit.amount,
          estatusSolicitud: credit.status === 'IN_PROGRESS' ? 'revision' : credit.status === 'ACCEPTED' ? 'aprobado' : credit.status === 'DENIED' ? 'solicitud-no-aprobada' : 'sin-solicitud'
        });
  
  
        history.push('/proceso-credito');
        // handle upload logic
      }).catch(err => {
        console.error(err);
        setLoading(false);
        // handle error
      });
    } else {
      setOpenMessage(true);
      setMessage("Para poder continuar necesitas certificar que los datos proporcionados son verídicos y que actúo en nombre propio al solicitar este crédito.");
      setSeverity("info");
    }
  }

  return (
    <div className="solicitud__container">
      <Popup
        visible={isPopupVisible}
        closeOnOutsideClick={false}
        width={1000}
        height={340}
        showTitle={false}
      >
        <AlertMessage
          open={openMessage}
          message={message}
          handleClose={handleClose}
          severity={severity}
        />
        <ScrollView width="100%" height="100%">
          <div className="avisoContent">
            Probenso Group, Sociedad Anónima, Sociedad Financiera  de Objeto Múltipe, Entidad No Regulada (E.N.R.), con domicilio en Ures Sur 137, Colonia Centro, Tepic, 63000, Nayarit, México es el responsable de sus datos personales.Utilizaremos tus datos personales para los siguientes fines indispensables: (i) Crear tu cuenta de usuario en la plataforma alojada en https://percapita.mx/; (ii) Identificarte como usuario de la plataforma alojada en https://percapita.mx/; (iii) Generar el expediente de solicitud de tu crédito; (iv) Consultar tu historial y capacidad crediticia;  (v) Decidir si se aprueba o rechaza tu solicitud  de crédito, y; (vi) Firmar los contratos de crédito que sean aprobados.Para conocer el aviso de privacidad integral, por favor haz <a href="/aviso-de-privacidad" target="_blank" style={{ fontSize: 14, marginLeft: 5 }}>click aquí.</a>
            <div className="solicitud-b__bottom button-center">
              <button className="btn blue marginAviso" type="submit" onClick={() => setPopupVisibility(false)} disabled={loading}>
                Cerrar
              </button>
              <button className="btn blue" type="submit" onClick={onSubmit} disabled={loading}>
                <ProgressCircular loading={loading} title='Aceptar' />
              </button>
            </div>
          </div>
        </ScrollView>
      </Popup>
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <div className="solicitud-b__container">
        <div className="navbar5">
          <a className="hg15">1. Validación</a>
          <a className="hg25">2. Información personal</a>
          <a className="hg35">3. Referencias</a>
          <a className="hg45">4. Perfil</a>
          <a className="hg55 ">5. Titulo PABS</a>
        </div>
        <p>
          {" "}

          <b>
            Aquí se subiran los documentos necesarios para iniciar el trámite
          </b>{" "}
        </p>

        <div className="uploadBoxes">
          {data.map((item) => {
            return (
              <UploadBox
                key={item.fileKey}
                image={item.image}
                descripton={item.description}
                fileSetter={item.setter}
                fileKey={item.fileKey}
                callback={isComplete}
              />
            );
          })}
        </div>

          <div style={{marginBottom: 10, marginTop: 5}}>
            <label>
              <input style={{ float: 'left', width: '15px' }} type="checkbox"
                checked={avisoDePrivacidad}
                onChange={handleChange}
              />
            </label>
            <strong style={{ fontSize: 16, marginLeft: 5 }}>
              Certifico que los datos proporcionados son verídicos y que actúo en nombre propio al solicitar este crédito.
            </strong>

          </div>
          <div>
            <div className="avisoContent">
              <span style={{ fontSize: 14, marginLeft: 5 }}>
                Probenso Group, Sociedad Anónima, Sociedad Financiera  de Objeto Múltipe, Entidad No Regulada (E.N.R.), con domicilio en Ures Sur 137, Colonia Centro, Tepic, 63000, Nayarit, México es el responsable de sus datos personales.Utilizaremos tus datos personales para los siguientes fines indispensables: (i) Crear tu cuenta de usuario en la plataforma alojada en https://percapita.mx/; (ii) Identificarte como usuario de la plataforma alojada en https://percapita.mx/; (iii) Generar el expediente de solicitud de tu crédito; (iv) Consultar tu historial y capacidad crediticia;  (v) Decidir si se aprueba o rechaza tu solicitud  de crédito, y; (vi) Firmar los contratos de crédito que sean aprobados.Para conocer el aviso de privacidad integral, por favor haz <a href="/aviso-de-privacidad" target="_blank" style={{ fontSize: 14, marginLeft: 5 }}>click aquí.</a>
              </span>
            </div>
          </div>


        <div className="Button-cpr">
          <button /* disabled={!complete} */ className="btn blue" onClick={abrirModal} disabled={loading}>
            <ProgressCircular loading={loading} title='Continuar' />
          </button>
        </div>
      </div>
    </div>
  );
}
