import { connect }    from "react-redux";
import { useState }   from "react";
import { useHistory } from "react-router-dom";

import ApiClient      from "../../../../../services/apiclient";
import UploadBox      from "../../../../credit-forms/components/uploadBox";
import { FileUpload } from "../../../../../interfaces/fileInterace";
import { ProgressCircular } from "../../../Progress";
import "./styles.scss";
import Menu from "../Menu/Menu";

const IneForm = ({
    nominaId,
    setMessage,
    setOpenMessage,
    setSeverity
}) => {

    const history = useHistory();
    const apiClient = ApiClient.getInstance();

    const [isLoading, setIsLoading] = useState(false);

    const [ineFile, setIneFile] = useState<FileUpload>();//Line for upload
    const [ineSelfieFile, setIneSelfieFile] = useState<FileUpload>();//Line for upload

    const [proofAddress, setProofAddress] = useState<FileUpload>();
    const [proofIncome, setProofIncome] = useState<FileUpload>();


    const [error, setError] = useState(false);
    const [incomplete, setIncomplete] = useState(true);

    const onSubmit = () => {

        if (!ineFile && !ineSelfieFile) {//Line for upload
            setMessage('Las foto de INE son requeridas');//Line for upload
            setSeverity('error');//Line for upload
            setOpenMessage(true);//Line for upload
            setError(true);//Line for upload
            return;//Line for upload
        }//Line for upload

        if (!ineFile || !ineSelfieFile) {//Line for upload
            setMessage('Debes subir ambos archivos.');//Line for upload
            setSeverity('error');//Line for upload
            setOpenMessage(true);//Line for upload
            setIncomplete(true);//Line for upload
            return;//Line for upload
        }//Line for upload

        if (!proofAddress && !proofIncome) {
            setMessage('Las comprobantes son necesarios');
            setSeverity('error');
            setOpenMessage(true);
            setError(true);
            return;
        }

        if (!proofAddress || !proofIncome) {
            setMessage('Debes subir ambos comprobantes');
            setSeverity('error');
            setOpenMessage(true);
            setIncomplete(true);
            return;
        }

        setError(false);
        setIncomplete(false);

        setIsLoading(true)

        const body = new FormData();

        body.append("ifeIne", ineFile?.file);//Line for upload
        body.append("selfieIne", ineSelfieFile?.file);//Line for upload
        body.append("proofAddress", proofAddress?.file);
        body.append("proofIncome", proofIncome?.file);

        apiClient.finishedForm(body, nominaId)
        .then(res => {
            console.log(res)
            history.push("/credito-config/personal");
        })
        .catch(e => {
            console.log(e)
            setIsLoading(false);
        })
    }

    return (
        <div className="solicitud-b__inciso">
        
            <Menu step="finish" />
            <div className="solicitud-b__inciso-content">
                <div className="files-accordeon">                
                    <UploadBox propKey="ine" mimeType="image/*" file={ineFile} setter={setIneFile} message="Foto de tu INE o IFE *" />
                    <UploadBox propKey="ineSelfie" mimeType="image/*" file={ineSelfieFile} setter={setIneSelfieFile} message="Selfie con tu INE o IFE *" />
                    <UploadBox propKey="proofAddress" mimeType="image/*" file={proofAddress} setter={setProofAddress} message="Comprobante de domicilio *" />
                    <UploadBox propKey="proofIncome" mimeType="image/*" file={proofIncome} setter={setProofIncome} message="Comprobante de ingresos *" />
                </div>

            </div>


            <div className="btnSubmitWrapFiles">
                <button
                    className="btn blue"
                    type="button"
                    onClick={onSubmit}
                    disabled={isLoading}
                >
                    <ProgressCircular loading={isLoading} title='Siguiente'/>
                </button>
            </div> 
        </div>
    )
};

const mapStateToProps = ({ personalCreditReducer }) => ({
    nominaId: personalCreditReducer?.nominaId ?? "",
});
  
export default connect(mapStateToProps)(IneForm);
  
