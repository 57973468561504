import Slider from '@material-ui/core/Slider';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '40px',
    },
    colorPrimary: {
      color: '#FFD466',
    },
    rail: {
      height: '10px',
      borderRadius: '5px',
      color: '#567384',
    },
    track: {
      height: '10px',
      borderRadius: '5px',
    },
    thumb: {
      width: '30px',
      height: '30px',
      marginLeft: '-15px',
      marginTop: '-10px',
    },
    thumbColorPrimary: {
      color: '#FBC02A',
    },

    valueLabel: {
      boxShadow: ' 0px 2.58832px 9.70621px rgba(0, 0, 0, 0.1)',
      left: 'calc(-50% + -25px)',
      fontSize: '22px',
      fontFamily: 'Raleway',
      fontStyle: 'normal',
      borderRadius: '5px',

      fontWeight: 'bold',
      top: '-45px',
      '& *': {
        borderRadius: '5px',
        margin: '0px',

        background: '#fff',
        width: '100px',
        transform: 'none',
        padding: '5px',

        color: '#194b74',
      },
    },
    margin: {
      height: theme.spacing(3),
    },
  }),
);

export default function CustomSlider(props: any) {
  const classes = useStyles();

  const {
    customLabelColor,
    value,
    defaultValue,
    min,
    max,
    maxLabel,
    minLabel,
    setLoanAmount,
    step,
    valueText,
    headerText,
    valueLabelFormat,
  } = props;

  return (
    <div className="custom-slider">
      <h4>{headerText}</h4>

      <Slider
        classes={classes}
        defaultValue={defaultValue}
        aria-labelledby="discrete-slider-always"
        step={step}
        getAriaValueText={valueText}
        min={min}
        max={max}
        valueLabelFormat={valueLabelFormat}
        // marks={marks}
        valueLabelDisplay="on"
      />

      <div className="custom-slider__range-text">
        <span style={customLabelColor && {color: customLabelColor}}>
          {minLabel}
        </span>
        &nbsp;&nbsp;&nbsp;
        <span style={customLabelColor && {color: customLabelColor}}>
          {maxLabel}
        </span>
      </div>
    </div>
  );
}
