import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import BlueVector from "../assets/BlueVector.png";
import daddaughter from "../assets/dad-daughter.png";
import eye from "../assets/eye.png";
import eyeHide from "../assets/visibilidad-24.png";
import { DomicilioContext } from "./../context/DomicilioProvider";
import { TramiteCreditoContext } from "./../context/TramiteCreditoProvider";
import { UserContext } from "./../context/UserProvider";
import AlertMessage from "./alert.component";
import Header from "./header.component";
import ApiClient from "../services/apiclient";
import {NOMINA_ACTIVE, PABS_ACTIVE, PERSONAL_ACTIVE} from '../utils/config'

function Login() {
  const history = useHistory();

  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const { userMain, updateUser } = React.useContext(UserContext);

  const { domicilioMain, updateDomicilio } = React.useContext(DomicilioContext);

  const { credito, updateCredito } = React.useContext(TramiteCreditoContext);

  const [shown, setShown] = React.useState(false);

  const [user, setUser] = useState({
    user: "",
    password: "",
  });

  const apiClient = ApiClient.getInstance();

  const [verificarSolicitud, setVerificarSolicitud] = React.useState({
    userId: "",
    userLoanId: "",
    isActive: false,
  });

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const userLogin = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (user.user === "") {
      setOpenMessage(true);
      setMessage(
        "El número de teléfono del usuario es requerido. Favor de verificar"
      );
      setSeverity("error");
      return;
    }

    if (user.password === "") {
      setOpenMessage(true);
      setMessage("La contraseña del usuario es requerido. Favor de verificar");
      setSeverity("error");
      return;
    }

    apiClient
      .login(user.user, user.password)
      .then((res) => res.json())
      .then(async (data) => {
        if (data.error) {
          localStorage.clear();
          setOpenMessage(true);
          const statusCode = data.error?.status || data.error.statusCode;
          setMessage(statusCode === 401 ? 'Telefono o contraseña invalidos' : data.error.message);
          setSeverity("error");

          if(statusCode === 403){
            localStorage.setItem('cxdc',user.user)
            localStorage.setItem('fwcd',user.password)
            return history.push("/step2");
          } else{
            return history.push("/login");
          } 
        }

        localStorage.setItem("TOKEN", data.token);
        localStorage.setItem("USER_ID", data.userId);

        apiClient.token = data.token;
        apiClient.userId = data.userId;
        if (data.fullName) {
          updateUser({
            ...userMain,
            nombre: data.fullName || data.fullName,
            telefono: data.phone,
          });
        } else {
          updateUser({
            ...userMain,
            telefono: data.phone,
          });
        }
        if (!(await apiClient.userHasUserDetails())) {
          return history.push("/register-form");
        }

        try {
          const activeCredit = await apiClient.getActiveCredit();
          if (activeCredit != null) {
            const credit = await apiClient.getCredit(activeCredit.creditType);

            console.log(data);

            updateUser({
              ...userMain,
              nombre: data.fullName,
              curp: credit.curp,
              rfc: credit.rfc,
            });

            updateCredito({
              ...credito,
              idPrestamo: credit.id,
              fecha_solicitud: credit.creationDate,
              isDelivered: credit.delivered,
              isApproved: credit.status === "ACCEPTED" ? true : false,
              importe: credit.amount,
              estatusSolicitud:
                credit.status === "IN_PROGRESS"
                  ? "revision"
                  : credit.status === "ACCEPTED"
                  ? "aprobado"
                  : credit.status === "DENIED"
                  ? "solicitud-no-aprobada"
                  : "sin-solicitud",
            });

            if (!credit && (await apiClient.userHasUserDetails())) {
              return history.push("/register-form");
            }

            
            if (credit.status === "ACCEPTED" && credit.acceptContract == null) {
              return history.push("/proceso-credito");
            } else if (
              credit.status === "ACCEPTED" &&
              credit.acceptContract != null
            ) {
              return history.push("/dashboard2");
            } else if (
              credit.status === "IN_PROGRESS" &&
              (credit.questionsPepsId == null || credit.filesLoanId == null) &&
              activeCredit.creditType == "pabs" &&
              PABS_ACTIVE
            ) {
              return history.push("/PABS");
            } else if (
              credit.status === "IN_PROGRESS" &&
              (credit.filesLoanId!==null) &&
              activeCredit.creditType == "nomina" &&
              NOMINA_ACTIVE
            ) {
              return history.push("/Nomina");
            } else if (
              credit.status === "IN_PROGRESS" &&
              (credit.amount == null || credit.numberPayments == null) &&
              activeCredit.creditType == "personal" && 
              PERSONAL_ACTIVE
            ) {
              return history.push("/credito-config/personal");
            } 
            // else if (credit.status === "IN_PROGRESS") {
            //   return history.push("/proceso-credito");
            // }
             else {
              return history.push("/productos");
            }
          } else {
            return history.push("/productos");
          }
        } catch (e) {
          if (e.message === "The given user doesn´t have any credit") {
            return history.push("/productos");
          }

          return history.push("/register-form");
        }
      });
  };

  const handleClose = () => {
    setOpenMessage(false);
  };

  const switchShown = () => setShown(!shown);

  return (
    <div className="appContainer">
      <Header />

      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <div className="login__content">
        <img className="login__blue-vector" src={BlueVector} />
        <img className="login__family" src={daddaughter} />
        <div className="login__card">
          <h1>Iniciar Sesión</h1>
          <h3>
            Bienvenido a Percapita. <br />
            Ingresa tus datos para entrar al espacio de información de tu
            crédito.
          </h3>
          <form onSubmit={userLogin} className="login__input-group">
            <label htmlFor="phone">Número de teléfono</label>
            <input name="user" onChange={handleInputChange} />
            <label htmlFor="password">Contraseña</label>
            <div className="password-input">
              <input
                id="password"
                type={shown ? "text" : "password"}
                name="password"
                onChange={handleInputChange}
              />
              <Link to="#">
                {" "}
                <img src={shown ? eyeHide : eye} onClick={switchShown} />
              </Link>
            </div>

            <Link style={{ fontSize: "12px" }} to="/request-forgot-password">
              Olvide mi contraseña
            </Link>
            <div style={{textAlign: 'center'}}>
            <button className="blue" onClick={userLogin}>
              Iniciar Sesión
            </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
