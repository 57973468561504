import React, { useEffect, useState, useCallback, useRef } from "react";
import AlertMessage from "../../../../alert.component";
import ApiClient, { IAddress } from "../../../../../services/apiclient";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../informacion.css";

export default function Domicilio({
  userData,
  setUserData,
  nextStep,
  prevStep,
}) {
  const [openMessage, setOpenMessage] = useState(false);
  const apiClient = ApiClient.getInstance();

  const [zipData, setZipData] = useState<IAddress>({
    ciudad: "",
    estado: "",
    municipio: "",
    colonias: [],
  });

  const [zip, setZip] = useState({
    postalCode: "",
  });

  useEffect(() => {
    console.log("userdata limpio", userData);

    if(userData.address.postalCode){
      apiClient
          .getAdrressFromZipCode(userData.address.postalCode)
          .then((data) => {
            console.log(data);
            setZip(userData.address.postalCode);
            setZipData(data);
          })
          .catch((err) => console.error(err));

    }
  }, []);

  const formikRef = React.useRef(null);

  const inputChange = useCallback((e) => {
    if (e.target.name === "address.postalCode") {
      if (e.target.value.length !== 5) {
        formikRef.current?.handleChange(e);
        return;
      }

      apiClient
        .getAdrressFromZipCode(e.target.value)
        .then((data) => {
          console.log(data);
          setZip(e.target.value);
          setZipData(data);
        })
        .catch((err) => console.error(err));
    }

    formikRef.current?.handleChange(e);
  }, []);

  const onsubmitForm = (values: any, actions: any) => {
    values.address.city = zipData.ciudad || zipData.municipio;
    values.address.state = zipData.estado;
    values.address.country = "Mexico";
    
    if(!values.address.antiquityMonth){
      values.address.antiquityMonth = 0;
    }

    setUserData(values);
    nextStep();
  };

  const validationSchema = Yup.object().shape({
    address: Yup.object({
      street: Yup.string().required("Requerido"),
      interiorNumber: Yup.string(),
      exteriorNumber: Yup.string().required("Requerido"),
      suburb: Yup.string().required("Requerido"),
      postalCode: Yup.string().required("Requerido"),
      homePhone: Yup.string().required("Requerido"),
      antiquity: Yup.number().nullable().required("Requerido"),
      antiquityMonth: Yup.number().nullable().required("Requerido"),
      propertyType: Yup.string().required("Requerido"),
    }),
  });

  return (
    <div>
      <AlertMessage open={openMessage} />
      <div className="solicitud__container">
        <div className="solicitud-b__container">
          <div className="navbar2">
            <a className="hg11">1. Validación</a>
            <a className="hg21">2. Información personal</a>
            <a className="hg31">3. Referencias</a>
            <a className="hg31">4. Perfil</a>
            <a className="hg41 ">5. Titulo PABS</a>
          </div>
          <p>
            {" "}
            <b>2.2 Domicilio</b>{" "}
          </p>

          <Formik
            initialValues={userData}
            onSubmit={onsubmitForm}
            validationSchema={validationSchema}
            innerRef={formikRef}
          >
            {({ handleSubmit, errors, touched, handleChange }) => (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="solicitud-4__input-group informacionC">
                  <div>
                    <label htmlFor="street">Calle</label>
                    <Field type="text" id="street" name="address.street" />
                    <ErrorMessage name={`address.street`}>
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="exteriorNumber">Numero exterior</label>
                    <Field
                      type="text"
                      id="exteriorNumber"
                      name="address.exteriorNumber"
                    />
                    <ErrorMessage name={`address.exteriorNumber`}>
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="interiorNumber">Número interior</label>
                    <Field
                      type="text"
                      id="interiorNumber"
                      name="address.interiorNumber"
                    />
                    <ErrorMessage name={`address.interiorNumber`}>
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="propertyType">Tipo de propiedad</label>
                    <Field
                      type="text"
                      id="propertyType"
                      name="address.propertyType"
                      min="0"
                      as="select"
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="PROPIA">Propia</option>
                      <option value="ALQUILER">Alquiler</option>
                      <option value="DESCONOCIDO">Familiar</option>
                      <option value="EMPRESA">Empresa</option>
                      <option value="FAMILIAR">Familiar</option>
                      <option value="PROPIETARIO">Propietario</option>
                      <option value="PROPIETARIO HIPOTECA">Propietario hipoteca</option>
                      <option value="SIN DOMICILIO PROPIO">Sin domicilio propio</option>
                    </Field>
                    <ErrorMessage name={`address.propertyType`}>
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="postalCode">Codigo Postal</label>
                    <Field
                      type="text"
                      id="postalCode"
                      name="address.postalCode"
                      min="0"
                      onChange={inputChange}
                    />
                    <ErrorMessage name={`address.postalCode`}>
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="suburb">Colonia</label>
                    <Field
                      as="select"
                      type="text"
                      id="suburb"
                      name="address.suburb"
                    >
                      <option value="">Seleccione una opción</option>

                      {zipData.colonias.map((colonia) => (
                        <option key={colonia.colonia} value={colonia.colonia}>
                          {colonia.colonia}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name={`address.suburb`}>
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="city">Ciudad</label>
                    <Field
                      type="text"
                      id="city"
                      name="address.city"
                      placeholder={zipData.ciudad || zipData.municipio}
                      disabled
                    ></Field>
                    <ErrorMessage name={`address.city`}>
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="state">Estado</label>

                    <Field
                      type="text"
                      id="state"
                      name="address.state"
                      placeholder={zipData.estado}
                      disabled
                    ></Field>
                    <ErrorMessage name={`address.state`}>
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="country">País</label>
                    <Field
                      type="text"
                      id="country"
                      name="address.country"
                      value="Mexico"
                      disabled
                    ></Field>
                    <ErrorMessage name={`address.country`}>
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="homePhone">Teléfono casa</label>
                    <Field
                      type="text"
                      id="homePhone"
                      name="address.homePhone"
                    />
                    <ErrorMessage name={`address.homePhone`}>
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>


                  <div>
                    <div >
                    <label htmlFor="antiquity" className="DomicilioL" >
                        ¿Cuantos años has vivido en el domicilio?
                      </label>

                      <div className="domicilioTime">
                      
                      <Field
                      className="domicilioTimeI" 
                        type="number"
                        min="0"
                        placeholder="Años"
                        id="antiquity"
                      
                        name="address.antiquity"
                      />
                      
                      <Field 
                      className="domicilioTimeI" 
                    
                        type="number"
                        id="antiquityMonth"
                        min="0"
                        placeholder="Meses"
                        name="address.antiquityMonth"
                      />
                      </div>

                      <div className="domicilioTimeE">
                        
                      <ErrorMessage  name={`address.antiquity`}>
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                      
                      <ErrorMessage name={`address.antiquityMonth`}>
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                        
                        </div>
                      
                      
                    </div>
                  </div>

                  
                </div>

                <div className="Button-cpr">
                  <button onClick={prevStep} className="btn blue">
                    Regresar
                  </button>
                  <button type="submit" className="btn blue">
                    Continuar
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
