import React from 'react';
import {UserContext} from '../../../context/UserProvider';
import HeaderDashboard from '../../headerDashboard.component';

const style = {
  content: {
    marginTop: '3vw',
  },
  menosContent: {
    marginTop: '1vw',
  },
};

function ApprovedCredit() {
  const {userMain, updateUser} = React.useContext(UserContext);
  return (
    <div>
      <HeaderDashboard />
      <div className="dashboard__container">
        <div className="dashboard-user__header">
          <div>
            <h1>¡Hola {userMain.nombre}! Bienvenido a tu espacio</h1>
            <span>2 Noviembre 2020</span>
          </div>
          <div className="dashboard-usuario-1__header-banner-en-proceso">
            <div>
              <i className="far fa-calendar-alt"></i>
              <label> Próxima fecha de pago: </label>
              <span>4 Noviembre 2020</span>
            </div>
            <div>
              <i className="fas fa-exclamation-triangle"></i>
              <label>Pago para no generar intereses:</label>
              <span>$1888 </span>
            </div>
          </div>
        </div>

        <div className="dashboard-en-proceso__content" style={style.content}>
          <h2>¡Felicidades por su aprobación de crédito!</h2>
        </div>

        <div className="dashboard-usuario-1__content">
          <span>¿A dónde quieres que te depositemos tu crédito?</span>
          <div
            className="dashboard-usuario-1__small-cards-container"
            style={style.menosContent}
          >
            <div className="dashboard-usuario-1__small-cards">
              <span>Cuenta bancaria</span>
              <span>CLABE interbancaria</span>
            </div>
            <div className="dashboard-usuario-1__small-cards">
              <span>Número de tarjeta</span>
              <span>123456789012345</span>
            </div>
          </div>
        </div>

        <button className="blue">Confirmar</button>

        <div className="dashboard-usuario-1__content">
          <div className="dashboard-usuario-1__small-cards-container">
            <div className="dashboard-usuario-1__small-cards">
              <h3>$17, 500</h3>
              <span>Préstamo total</span>
            </div>
            <div className="dashboard-usuario-1__small-cards">
              <h3>$4, 500</h3>
              <span>Intereses totales a pagar</span>
            </div>
            <div className="dashboard-usuario-1__small-cards">
              <h3>12 pagos</h3>
              <span>de Intereses Pendiente</span>
            </div>
          </div>
        </div>

        <progress max="100" value="0"></progress>
        <div className="progress-labels">
          <span>$0</span>
          <span>
            $22,000 <i className="far fa-question-circle"></i>{' '}
          </span>
        </div>
        <span>
          <b>0 de 12 plazos pagados</b>
        </span>
        <div className="tabla-pagos">
          <h4>Desgloce de pagos</h4>
        </div>
      </div>
    </div>
  );
}

export default ApprovedCredit;
