import "./styles.scss";

const Menu = ({
    step
}) => {
    return (
        <div className="menuPersonalCredit">
            <ul>
                <li className={step === "personal" ? "activeStep" : ""}>1. Información personal</li>
                <li className={step === "address" ? "activeStep" : ""}>2. Dirección</li>
                <li className={step === "labor" ? "activeStep" : ""}>3. Informacion de trabajo</li>
                <li className={step === "circulo_credito" ? "activeStep" : ""}>4. Círculo de crédito</li>
                <li className={step === "references" ? "activeStep" : ""}>5. Referencias</li>
                <li className={step === "peps" ? "activeStep" : ""}>6. Preguntas PEPS</li>
                <li className={step === "finish" ? "activeStep" : ""}>7. Comprobantes</li>
            </ul>
        </div>
    )
};

export default Menu;
