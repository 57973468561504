import React, { useEffect, useState } from 'react';
import {TramiteCreditoContext} from '../../../context/TramiteCreditoProvider';
import {UserContext} from '../../../context/UserProvider';
import HeaderDashboard from '../../headerDashboard.component';

function CreditDetail() {
  const {userMain, updateUser} = React.useContext(UserContext);

  const {credito, updateCredito} = React.useContext(TramiteCreditoContext);

  const [pagoProximo, setPagoProximo] = useState({
    fechaPago: '',
    importe: 0
  })

  const content = credito.detalles.map((post: any) =>

    <tr key={post.idLoanPaymentBreakdown}>
      <td>{post.payment_date}</td>
      <td>{post.payment_number}</td>
      <td>{post.concept}</td>
      <td>{post.amount_payable}</td>
      <td>{post.begining_of_period}</td>
    </tr>
  );

  useEffect(() => {

    if (credito.idPrestamo != '') {
     
      fetch(process.env.REACT_APP_URL_API + '/next-payment/' + credito.idPrestamo, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
        .then(data => {
          if (data.error) {
          } else {
          
            var fechaPago : Date = new Date(data.payment_date);
            
            var descripcionFecha = formatFecha(fechaPago);

            setPagoProximo({...pagoProximo, fechaPago: descripcionFecha,
            importe: data.amount_payable});
          }
        }).catch(error => console.log(error));
    }
  }, [pagoProximo])

  function formatFecha(fecha: Date) {
    var mes = fecha.getMonth();
    var descripcion = '';
    switch (mes) {
      case 0:
        descripcion = 'Enero'
        break;
      case 1:
        descripcion = 'Febrero'
        break;
      case 2:
        descripcion = 'Marzo'
        break;
      case 3:
        descripcion = 'Abril'
        break;
      case 4:
        descripcion = 'Mayo'
        break;
      case 5:
        descripcion = 'Junio'
        break;
      case 6:
        descripcion = 'Julio'
        break;
      case 7:
        descripcion = 'Agosto'
        break;
      case 8:
        descripcion = 'Septiembre'
        break;
      case 9:
        descripcion = 'Octubre'
        break;
      case 10:
        descripcion = 'Noviembre'
        break;
      case 11:
        descripcion = 'Diciembre'
        break;

      default:
        descripcion = 'Desconocido';
    }

    var day = fecha.getDay();

    var anio = fecha.getFullYear();

    var formato : string = day + ' de ' +descripcion+" "+anio;

    return formato;
  }

  return (
    <div>
      <HeaderDashboard />
      <div className="dashboard__container">
        <div className="dashboard-user__header">
          <div>
            <h1>¡Hola {userMain.nombre}! Bienvenido a tu espacio</h1>
            <span>2 Noviembre 2020</span>
          </div>
          <div className="dashboard-usuario-1__header-banner-en-proceso">
            <div>
              <i className="far fa-calendar-alt"></i>
              <label> Próxima fecha de pago: </label>
              <span>{pagoProximo.fechaPago}</span>
            </div>
            <div>
              <i className="fas fa-exclamation-triangle"></i>
              <label>Pago para no generar intereses:</label>
              <span>${pagoProximo.importe} </span>
            </div>
          </div>
        </div>
        <div className="dashboard-usuario-1__content">
          <div className="dashboard-usuario-1__small-cards-container">
            <div className="dashboard-usuario-1__small-cards">
              <h3>${credito.importe}</h3>
              <span>Préstamo total</span>
            </div>
            <div className="dashboard-usuario-1__small-cards">
              <h3>${credito.importe_interes}</h3>
              <span>Intereses totales a pagar</span>
            </div>
            <div className="dashboard-usuario-1__small-cards">
              <h3 className="success-text">${credito.importe_liquidar}</h3>
              <span>Saldado al día de hoy</span>
            </div>
            <div className="dashboard-usuario-1__small-cards">
              <h3 className="error-text">${credito.importe_pendiente}</h3>
              <span>Pendiente de pago</span>
            </div>
            <div className="dashboard-usuario-1__small-cards">
              <h3>{credito.numero_plazo} pagos</h3>
              <span>de Interés Pendientes</span>
            </div>
          </div>
          <progress max="100" value="80"></progress>
          <div className="progress-labels">
            <span>$0</span>
            <span>
              ${credito.importe} <i className="far fa-question-circle"></i>{' '}
            </span>
          </div>
          <span>
            <b>0 de {credito.numero_plazo} plazos pagados</b>
          </span>
          <div className="tabla-pagos">
            <h4>Desgloce de pagos</h4>
            <table className="blueTable">
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>N&uacute;mero</th>
                  <th>Concepto</th>
                  <th>Cantidad a pagar</th>
                  <th>Monto total acumulado</th>
                </tr>
              </thead>
              <tfoot>

              </tfoot>
              <tbody>
                {content}
              </tbody>
            </table>
          </div>
        </div>

        <div>
        </div>
      </div>
    </div>
  );
}

export default CreditDetail;
