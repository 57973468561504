import React from "react";
import Header from "../header.component";
import Footer from "./footer";
export default function TerminosCondicionesView() {
  return (
    <>
      <div className="termsConditions">
        <div className="avisoPrivacidad">
          <h1>CONTRATO DE LICENCIA</h1>
        </div>
        <p>
          <b>
            {" "}
            EL PRESENTE DOCUMENTO ES UN CONTRATO DE LICENCIA DE USO CELEBRADO
            ENTRE USTED (EN ADELANTE “EL USUARIO”) Y PROBENSO GROUP, SOCIEDAD
            ANÓNIMA, SOCIEDAD FINANCIERA DE OBJETO MÚLTIPLE, ENTIDAD NO REGULADA
            (EN ADELANTE “LA EMPRESA”). EN CASO DE QUE EL USUARIO UTILICE
            CUALQUIERA DE LOS RECURSOS EN LÍNEA, SE OBLIGA A CUMPLIR EL PRESENTE
            DOCUMENTO EN SU TOTALIDAD.{" "}
          </b>{" "}
          <br />
          <br />
          <ol typeof="number">
            <li>
              {" "}
              <b>Definiciones.</b> Para efectos del presente documento, las
              palabras que a continuación se señalan, tendrán los significados
              siguientes:
              <br /> <br /> <b>1.1. Aviso de Privacidad.</b> El documento
              publicado en: https://app.percapita.mx/terminos-y-condiciones.
              <br /> <br /> <b>1.2. Claves de Acceso. </b> El nombre de usuario
              y contraseña que utiliza un <b>Usuario </b>para acceder a su{" "}
              <b>Cuenta de Usuario</b> asociada a él.
              <br /> <br /> <b>1.3. Cuenta de Usuario. </b> Credencial de
              identificación compuesta por Claves de Acceso y demás datos
              personales, que identifica al Usuario como usuario de la
              Plataforma.
              <br /> <br /> <b>1.4. Dispositivos.</b> Cualquier tipo de
              ordenador, equipo de cómputo, teléfono inteligente, tableta
              electrónica u otro de naturaleza similar o análoga.
              <br /> <br /> <b>1.5. Elementos de Propiedad Industrial.</b>{" "}
              Cualquier signo distintivo, nombre o aviso comercial, patente,
              diseño industrial o modelo de utilidad protegido en términos de la
              Ley de Propiedad Industrial vigente en la República Mexicana.
              <br /> <br /> <b>1.6. Marcas.</b> Los signos distintivos
              identificados como “Percapita”.
              <br /> <br /> <b>1.7. Medios de contacto.</b> i) El teléfono de
              contacto para usuarios es el (33) 36361935 y; iii) El correo
              electrónico de contacto es atnclientes@probenso.com.
              <br /> <br /> <b>1.8. Nombres de dominio.</b>{" "}
              https://app.percapita.mx.
              <br /> <br /> <b>1.9. Obras Protegidas.</b> Cualquier elemento de
              creación original, susceptible de ser divulgadas o reproducidas en
              cualquier forma o medio y que se encuentren protegidas en términos
              de la Ley Federal del Derecho de Autor, incluyendo, pero sin
              limitar, fotografías, obras de diseño gráfico, textos,
              redacciones, imágenes, videos, “gifs”, “memes”, “meta-memes”,
              obras musicales y obras audiovisuales.
              <br /> <br /> <b>1.10. Plataforma.</b> Programa de cómputo
              denominado “Percapita” que le permite realizar las funciones que
              se describen en el punto 3 del presente documento.
              <br /> <br /> <b>1.11. Recursos en Línea.</b> La Plataforma y el
              Sitio Web.
              <br /> <br /> <b>1.12. Sitio Web:</b> La información consultable a
              través del nombre de dominio denominado: https://app.percapita.mx.{" "}
              <br />
              <br />
              <br />
            </li>
            <li>
              <b>Declaraciones</b> . El Usuario declara, bajo protesta de decir
              verdad, que:
              <br /> <br /> <b>2.1. En caso de ser persona física:</b> (i) es
              mayor de edad y plenamente capaz para celebrar el presente
              contrato y obligarse en sus términos, de conformidad con las leyes
              de su país de residencia o; (ii) en caso de ser menor de edad, o
              no contar con la capacidad plena para la celebración del presente
              contrato, es su representante legal quien acepta el presente
              acuerdo en su nombre y representación y; (iii) su información es
              la que ha proporcionado al momento de crear su Cuenta de Usuario,
              la misma es verídica y, que cuenta con todos los medios de prueba
              para acreditarlo.
              <br /> <br /> <b>2.2. En caso de ser persona moral:</b> que de
              conformidad con las leyes de su país de constitución: (i) está
              legalmente constituida y plenamente facultada para la celebración
              del presente acuerdo; (ii) que su representante legal cuenta con
              las facultades suficientes para la celebración del presente
              acuerdo en su nombre y representación y que las mismas no le han
              sido revocadas o en forma alguna modificadas con anterioridad a la
              celebración del presente contrato y; (iii) que su información es
              la que ha proporcionado al momento de crear su Cuenta de Usuario,
              que la misma es verídica y, que cuenta con todos los medios de
              prueba para acreditarlo.
              <br /> <br /> <b>2.3.</b> Que cuenta con todos los permisos,
              autorizaciones y certificaciones requeridas en su lugar de
              residencia para poder utilizar cualquiera de los Recursos en
              Línea.
            </li>
            <br />
            <br />
            <li>
              <b>Licencia de Uso.</b> Por medio del presente, la Empresa le
              otorga al Usuario una licencia NO exclusiva, limitada, revocable e
              intransferible que le permitirá utilizar la Plataforma,
              exclusivamente para los siguientes fines:
              <br /> <br /> <b>3.1. </b>Llenar y presentar solicitudes de
              créditos, mutuos, préstamos y/o financiamientos a la Empresa.
              <br /> <br /> <b>3.2. </b>Cargar diversos documentos, con el
              objetivo de que la Empresa pueda: (i) evaluar el perfil de riesgo
              para el otorgamiento de créditos o financiamientos y, (ii) cumplir
              con las regulaciones aplicables a la Empresa.
            </li>
            <br /> <br />
            <li>
              <b>Uso permitido.</b> El Usuario se obliga a: i) utilizar los
              Recursos en Línea, exclusivamente, para los fines autorizados en
              el presente documento; ii) cumplir con las Obligaciones de
              Productos y Servicios; iii) abstenerse de violar alguna de las
              disposiciones establecidas en el presente documento o en las leyes
              aplicables al correcto uso de Internet en el lugar en donde acceda
              a los Recursos en Línea.
            </li>
            <br />
            <br />
            <li>
              <b>5. Uso no autorizado:</b> De igual forma, al usar la
              plataforma, el Usuario se obliga a abstenerse de:
              <br /> <br /> <b>5.1. </b> Otorgar información falsa a la Empresa
              a través de la Plataforma, incluyendo pero sin limitar, datos
              personales que no correspondan al Usuario o al solicitante de
              créditos, préstamos, mutuos o financiamientos y documentos
              oficiales apócrifos.
              <br /> <br /> <b>5.2. </b> Utilizar la Plataforma o cualquiera de
              los Recursos en Línea como medio para cometer algún delito o hecho
              ilícito, como puede ser, evasión fiscal, homicidio, secuestro,
              daño en propiedad ajena o cualquier incuplimiento a las leyes
              financieras aplicables;
              <br /> <br /> <b>5.3. </b> Deshabilitar, romper, cancelar,
              desprogramar o vulnerar cualquier requisito de seguridad digital,
              tecnológica o informática que contenga cualquiera de los Recursos
              en Línea o cualquiera de las bases de datos que la Empresa
              administra;
              <br /> <br /> <b>5.4. </b> Borrar, alterar o modificar cualquier
              información contenida en cualquiera de los Recursos en Línea o sus
              bases de datos asociadas, incluyendo el código fuente, de
              cualquiera de los Recursos en Línea o cualquiera de las bases de
              datos que la Empresa administra;
              <br /> <br /> <b>5.5. </b> Copiar, divulgar, modificar o eliminar
              cualquier información, dato, Elemento de Propiedad Industrial,
              Obra Protegida o elemento de naturaleza similar o análoga que se
              encuentre en los Recursos en Línea o en cualquiera de las bases de
              datos que la Empresa administra;
              <br /> <br /> <b>5.6. </b> Descompilar o realizar técnicas de
              ingeniería inversa para obtener acceso al código fuente de
              cualquiera de los Recursos en Línea;
              <br /> <br /> <b>5.7. </b> Evitar, impedir y/o imposibilitar,
              total o parcialmente, que la Empresa o un tercero pueda obtener
              acceso o utilizar cualquiera de los Recursos en Línea o sus bases
              de datos asociadas;
              <br /> <br /> <b>5.8. </b> Causar un daño a cualquiera de los
              Recursos en Línea o a cualquiera de las bases de datos que la
              Empresa administra;
              <br /> <br /> <b>5.9. </b> Obtener acceso, de cualquier forma, al
              código fuente de cualquiera de los Recursos en Línea;
              <br /> <br /> <b>5.10. </b>Crear una obra derivada del código
              fuente o del código objeto de cualquiera de los Recursos en Línea;
              <br /> <br /> <b>5.11. </b>Modificar, en cualquier forma, el
              código fuente de cualquiera de los Recursos en Línea;
              <br /> <br /> <b>5.12. </b>Eliminar, total o parcialmente, el
              código fuente de cualquiera de los Recursos en línea;
              <br /> <br /> <b>5.13. </b>Obtener acceso a los Recursos en línea
              o a las bases de datos que la Empresa administra, sin estar
              autorizado para ello o excediendo la autorización otorgada;
              <br /> <br /> <b>5.14. </b> Utilizar cualquier tipo de programa de
              cómputo o recurso digital (incluidas las técnicas conocidas como
              raspado de pantalla) para copiar la información contenida en los
              Recursos en Línea o sus bases de datos asociadas, incluyendo, pero
              sin limitar Obras Protegidas, Marcas, información comercial, datos
              personales, datos agregados, secretos industriales, código fuente,
              etc.
              <br /> <br /> <b>5.15. </b> Utilizar cualquier tipo de programa de
              cómputo o recurso digital (incluidos virus, trojanos, malware,
              spyware, ransomware, descompiladores, o similares) para; i) causar
              un daño a cualquiera de los Recursos en Línea o sus bases de datos
              asociadas; ii) deshabilitar, romper, cancelar, desprogramar o
              vulnerar cualquier requisito de seguridad digital, tecnológica o
              informática que contenga cualquiera de los Recursos en Línea o sus
              bases de datos asociadas; iii) borrar, alterar o modificar
              cualquier información contenida en cualquiera de los Recursos en
              Línea o sus bases de datos asociadas, incluyendo el código fuente
              de cualquiera de los Recursos en Línea; iv) evitar, impedir y/o
              imposibilitar, total o parcialmente, que la Empresa o un tercero
              pueda obtener acceso o utilizar cualquiera de los Recursos en
              Línea o sus bases de datos asociadas; v) descompilar o realizar
              técnicas de ingeniería inversa para obtener acceso al código
              fuente de cualquiera de de los Recursos en Línea;
              <br /> <br /> <b>5.16. </b> Las demás de naturaleza similar o
              análoga a juicio de la Empresa.
            </li>
            <br />
            <br />
            <li>
              <b>Acceso. </b>El acceso a la Plataforma se rige por lo siguiente:
              <br /> <br /> <b>6.1. Acceso. </b>Para poder acceder a la
              Plataforma el Usuario deberá: i) crear una Cuenta de Usuario e;
              ii) iniciar sesión en la Plataforma, siguiendo, en ambos casos,
              los procedimientos que para tal efecto se establecen en los
              Recursos en Línea. El Usuario se obliga a no obtener un acceso a
              la Plataforma, las bases de datos o demás sistemas informáticos de
              la Empresa, sin que haya sido previamente autorizado expresamente
              para ello.
              <br /> <br /> <b>6.2. Acceso no autorizado. </b> El Usuario se
              obliga a abstenerse de obtener o de intentar obtener, acceso a la
              Plataforma, sin cumplir con lo establecido en el punto anterior.
              Así mismo, el Usuario se obliga a abstenerse de implementar
              cualquier tipo de técnicas para obtener acceso a la Plataforma,
              bases de datos y/o sistemas de seguridad informática de la
              Empresa, sin estar debidamente autorizado para obtener dicho
              acceso. El Usuario sólo está autorizado para acceder a la
              Plataforma, siguiendo las reglas establecidas en este punto.
              <br /> <br /> <b>6.3. Cuenta de Usuario. </b> Para crear una
              Cuenta de Usuario, el Usuario deberá seleccionar y asignar sus
              Claves de Acceso, siguiendo el procedimiento que se establece en
              el apartado de “Registro” de la Plataforma. La Cuenta de Usuario,
              servirá para identificar al Usuario, en consecuencia, se entenderá
              que cualquier acto jurídico celebrado a través de la Plataforma
              mientras exista iniciada una sesión con su Cuenta de Usuario, ha
              sido celebrado por el Usuario. La Cuenta de Usuario será
              considerada como una firma electrónica en términos del Código de
              Comercio vigente en la República Mexicana. Se recomienda hacer un
              uso responsable de su Cuenta de Usuario.
              <br /> <br /> <b>6.4. Claves de Acceso. </b> El uso de las Claves
              de Acceso es absoluta responsabilidad del Usuario. Es obligación
              del Usuario guardar las Claves de Acceso en un lugar seguro y no
              compartirlas con nadie. En caso de pérdida o extravío de
              cualquiera de las Claves de Acceso (incluida la contraseña), el
              Usuario podrá modificarlas, cambiarlas o reponerlas, siguiendo los
              procesos que para tal efecto se establezcan en cualquiera de los
              Recursos en Línea. Es probable que, para garantizar la seguridad
              informática de una Cuenta de Usuario, el Usuario sea requerido
              para proporcionar ciertos datos personales (los que serán tratados
              de conformidad con nuestro Aviso de Privacidad) para verificar que
              el Usuario es quien está solicitando la modificación, cambio o
              reposición de sus Claves de Acceso.
              <br /> <br /> <b>6.5. Inicio de sesión. </b> Para iniciar sesión
              en una Cuenta de Usuario, el Usuario deberá de seguir el
              procedimiento establecido para tal efecto en la Plataforma. El
              Usuario se obliga a abstenerse de iniciar sesión en la Plataforma
              a través de un procedimiento distinto. Para poder cumplir con el
              procedimiento mencionado, el Usuario deberá proporcionar a la
              Empresa sus Claves de Acceso de manera correcta. Si el Usuario
              proporciona de manera incorrecta sus Claves de Acceso en más de 3
              ocasiones la Empresa podrá bloquear la Cuenta de Usuario, a la que
              se esté intentando tener acceso. La Empresa se obliga a notificar
              al Usuario, por correo electrónico, el bloqueo de una Cuenta de
              Usuario asociada con el Usuario y en dicho correo electrónico se
              proporcionarán los medios para desbloquearla.
              <br /> <br /> <b>6.6. Cierre de sesión. </b> El Usuario podrá
              cerrar cualquier sesión iniciada en la Plataforma en cualquier
              tiempo, siguiendo el procedimiento para tal efecto establecido en
              dicha Plataforma. Una vez que haya cerrado una sesión, se deberá
              de volver a iniciar sesión para poder acceder nuevamente a la
              Plataforma.
              <br /> <br /> <b>6.7. Conexión a internet.</b> Para poder acceder
              a la Plataforma o a cualquiera de los Recursos en Línea, el
              Usuario se obliga a contar con una conexión a Internet estable. La
              Empresa no será responsable en caso de que el Usuario no pueda
              acceder a la Plataforma o a cualquiera de los Recursos en Línea
              por fallas de conexión imputables a su servicio de internet.
              <br /> <br /> <b>6.8. Dispositivo. </b> Para acceder a la
              Plataforma o a cualquiera de los Recursos en Línea, el Usuario se
              obliga a utilizar un Dispositivo con un sistema operativo
              compatible con la Plataforma o con cualquiera de los Recursos en
              Línea a los que intente acceder. El Usuario se obliga a contar con
              un Dispositivo adecuado que tenga instalado un sistema operativo
              compatible y que cuente con las actualizaciones y parches
              necesarios para poder ejecutar la Plataforma o cualquiera de los
              Recursos en Línea a los que intente acceder. La Empresa no será
              responsable en caso de que el Usuario no pueda acceder a la
              Plataforma o cualquiera de los Recursos en Línea a los que intente
              acceder por no contar con un Dispositivo que cumpla con las
              características antes señaladas o por cualquier otra causa similar
              o análoga. La Empresa podrá avisarle al Usuario, mediante una
              publicación en cualquiera de los sitios web y/o páginas de
              internet administrados por aquella u otros medios de comunicación,
              cuáles son los sistemas operativos compatibles con la Plataforma.
              Así mismo, la Empresa se reserva el derecho de modificar o
              actualizar la Plataforma o cualquiera de los Recursos en Línea
              para que sea compatible con los sistemas operativos que la Empresa
              elija, discrecionalmente.
              <br /> <br /> <b>6.9. Bloqueo de Cuenta de Usuario. </b> La
              Empresa se reserva el derecho de bloquear la Cuenta de Usuario
              asociada al Usuario, de manera temporal, en caso de que el Usuario
              incumpla cualquiera de las obligaciones que asume por virtud del
              presente contrato. Una vez bloqueada la Cuenta de Usuario, el
              Usuario no podrá tener acceso a la Plataforma. Para desbloquear la
              cuenta, el Usuario deberá contactar a la Empresa a través de los
              Medios de Contacto, solicitando expresamente el desbloqueo de su
              cuenta y comprometiéndose a no volver a incumplir el presente
              contrato. La Empresa se reserva el derecho de no desbloquear la
              Cuenta de Usuario, en cuyo caso, se procederá a la cancelación de
              ésta en términos del punto siguiente.
              <br /> <br /> <b>6.10. Cancelación de Cuenta de Usuario.</b> La
              Empresa se reserva el derecho de cancelar la Cuenta de Usuario
              asociada al Usuario, en caso de que el Usuario incumpla cualquiera
              de las obligaciones que asume por virtud del presente contrato.
              Una vez cancelada la Cuenta de Usuario, la misma será eliminada de
              las bases de datos administradas por la Empresa y el Usuario no
              podrá volver a acceder a la Plataforma. El Usuario podrá apelar
              cualquier cancelación de Cuenta de Usuario a través de los
              procedimientos a que se refiere el punto de Solución de
              Controversias del presente documento.
            </li>
            <br />
            <br />
            <li>
              <b>Propiedad Intelectual</b>
              <br /> <br /> <b>7.1. Propiedad de los Recursos en Línea.</b> La
              Empresa será la única titular de cualquier derecho de propiedad
              intelectual, industrial o derecho de autor relacionado o asociado
              con los Recursos en Línea y sus bases de datos asociadas. El
              presente documento únicamente otorga al Usuario una licencia para
              utilizar los Recursos en Línea y para descargar una copia del App.
              Nada en el presente documento faculta al Usuario para ser
              considerado como propietario o titular de los Recursos en Línea,
              ni para adquirir derecho de autor (ya sea moral o patrimonial) o
              de propiedad industrial alguno sobre los mismos. El presente
              documento tampoco autoriza al Usuario para crear obras derivadas
              de cualquiera de los Recursos en Línea. Así mismo, el Usuario
              tampoco tendrá derecho a sublicenciar, transmitir, ceder,
              enajenar, vender, donar, transferir, arrendar, subarrendar, o de
              cualquier otra forma traspasar a favor de terceros la propiedad,
              titularidad, el uso, goce o el disfrute de cualquiera de los
              Recursos en Línea. El Usuario se obliga a abstenerse de cometer o
              celebrar cualquiera de los hechos o actos descritos en el presente
              párrafo.
              <br /> <br /> <b>7.2. Contenidos.</b> Todas las Obras Protegidas
              que se publiquen en cualquiera de los Recursos en Línea se
              sujetarán a lo siguiente:
              <br /> <br />{" "}
              <b>
                {" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.2.1. Contenido
                de la Empresa.{" "}
              </b>
              El Usuario acepta y reconoce que los Recursos en Línea pueden
              contener Obras Protegidas o Elementos de Propiedad Industrial
              propiedad de la Empresa. El Usuario se obliga a no descargar,
              copiar, utilizar para beneficio personal (tenga ganancia económica
              o no), divulgar o comunicar públicamente cualquiera de las Obras
              Protegidas o Elementos de Propiedad Industrial propiedad de la
              Empresa.
              <br /> <br />{" "}
              <b>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.2.2. Contenido
                de terceros.{" "}
              </b>{" "}
              El Usuario acepta y reconoce que, en cualquiera de los Recursos en
              Línea, puede haber publicada información que contenga Obras
              Protegidas o Elementos de Propiedad Industrial propiedad de
              terceros, sobre la cual la Empresa tiene licencias de uso y/o
              publicación. El Usuario se obliga a no descargar, copiar, utilizar
              para beneficio personal (tenga ganancia económica o no), divulgar
              o comunicar públicamente cualquiera de las Obras Protegidas o
              Elementos de Propiedad Industrial propiedad de terceras personas
              que se encuentren publicadas en cualquiera de los Recursos en
              Línea.
              <br /> <br />{" "}
              <b>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.2.3.Contenido de Usuario.
              </b>{" "}
              El Usuario podrá publicar información que contenga Obras
              Protegidas o Elementos de Propiedad Industrial en la Plataforma,
              siempre y cuando la Plataforma se lo permita técnicamente. El
              Usuario se obliga a abstenerse de publicar en la Plataforma
              aquellas Obras Protegidas o Elementos de Propiedad Industrial
              sobre las cuales el Usuario: (i) no sea el propietario o no tenga
              derecho de propiedad intelectual, industrial o Derecho de Autor o;
              (ii) no tenga un derecho o licencia para publicarlas, comunicarlas
              o divulgarlas a través de Internet. La propiedad intelectual de
              las Obras Protegidas o Elementos de Propiedad Industrial que se
              publiquen en la Plataforma será de aquellas personas a quienes les
              corresponda dicha titularidad de conformidad con la Ley Federal de
              Derechos de Autor o la Ley Federal de Protección a la Propiedad
              Industrial aplicable en la República Mexicana. Así mismo, por
              medio de este acto, el Usuario otorga a la Empresa una licencia
              gratuita, revocable y transferible sobre cualquiera de las Obras
              Protegidas o Elementos de Propiedad Industrial que el Usuario
              publique en la Plataforma, para que la Empresa pueda publicar,
              comunicar y presentar dichas Obras Protegidas o Elementos de
              Propiedad Industrial al público en general, en la Plataforma. Para
              revocar la licencia a que se hace referencia en el enunciado
              anterior, el Usuario simplemente deberá de eliminar las Obras
              Protegidas o los Elementos de Propiedad Industrial que fueron
              publicadas, siguiendo para tal efecto los procesos que se detallan
              en la Plataforma para eliminar contenido publicado.
              <br /> <br />{" "}
              <b>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.2.4.
                Restricciones al contenido de Usuario:
              </b>{" "}
              El Usuario se obliga a abstenerse de publicar contenido: i) que
              pueda ser considerado racista, xenófobo, homófobo, sexista o
              discriminatorio contra cualquier grupo marginalizado; ii) que
              contenga insultos, injurias, vejaciones, en contra de otro usuario
              de la Plataforma o en contra de grupos generalmente
              marginalizados; iii) que contenga mensajes de odio u opresión a
              gurpos marginalizados; iv) que haga apología de cualquier delito o
              hecho ilícito; v) que contenga imágenes pornográficas, éroticas,
              sexualizada o de desnudo de cualquier persona, con o sin
              consentimiento de la persona cuya imagen se ha publicado; vi) que
              promueva pronografía infantil, trata de personas, narcotráfico,
              venta de todo tipo de armas, incluyendo armas de fuego y
              explosivos; vii) que contenga imágnes explícitas de personas que
              hayan sido o que esten siendo lastimadas, heridas, asesinadas o
              que reciban cualquier daño físico, cualquiera sea su naturaleza o;
              viii) que contenga contenido lesivo, ilegal o perturbador a juicio
              de la Empresa. El Usuario acepta y reconoce que la Empresa podrá
              eliminar o bloquear el acceso a cualquier contenido publicado por
              el Usuario, que incumpla con lo establecido en este punto, sin que
              se considere que dicha eliminación o bloqueo causa un daño al
              Usuario.
              <br /> <br />{" "}
              <b>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.2.5.
                Notificación de protección de contenido.
              </b>{" "}
              En caso de que el Usuario considere que alguna persona ha
              publicado en la Plataforma alguna de sus Obras Protegidas o
              Elementos de Propiedad Industrial sin su consentimiento, podrá
              enviar un correo electrónico a la dirección denominada mencionada
              en las Medios de Contacto, solicitando a la Empresa la remoción de
              dichas Obras Protegidas o Elementos de Propiedad Industrial. Una
              vez recibida la notificación a que se hace referencia en el
              enunciado anterior, la Empresa deberá notificar al presunto
              infractor de la existencia de su notificación y de la procedencia
              de la remoción del contenido, dándole un período de 3 días hábiles
              para manifestar lo que a su derecho convenga. No procederá la
              remoción del contenido si el presunto infractor demuestra que ha
              iniciado una demanda o denuncia ante alguna corte o tribunal,
              judicial o administrativa, reclamando la propiedad intelectual de
              las Obras Protegidas o los Elementos de Propiedad Industrial en
              cuestión. En todo momento, la Empresa dejará a salvo los derechos
              del Usuario para que éste los haga valer de la forma que considere
              pertinente. La Empresa se reserva el derecho de coadyuvar, o no
              coadyuvar, con el Usuario para la defensa de dichos derechos. La
              Empresa notificará al Usuario, en todo momento, las decisiones que
              aquélla tome con respecto de la queja presentada. En caso de que
              algún tercero presente una queja de violación de derechos de
              propiedad intelectual o industrial en contra del Usuario, se
              aplicarán las reglas establecidas en el presente punto para su
              desahogo y resolución. La decisión de la Empresa con respecto a la
              remoción de Obras Protegidas o Elementos de Propiedad Industrial
              en términos de este punto será inapelable. Cualquier reclamo que
              el Usuario tenga sobre la remoción que hagamos de su contenido se
              resolverá a través del procedimiento detallado en el punto de
              Solución de Controversias del presente documento.
              <br /> <br />{" "}
              <b>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7.2.6. Marcas,
                nombres y avisos comerciales.
              </b>{" "}
              El Usuario acepta y reconoce que las Marcas son propiedad
              exclusiva de la Empresa. El Usuario se obliga a abstenerse de: i)
              usar, descargar, copiar, reproducir, utilizar, cualquiera de
              nuestras marcas sin contar con consentimiento por escrito y; ii)
              licenciar, sublicenciar, enajenar, ceder, transferir y/o
              transmitir a terceros, cualquier de las Marcas o sus derechos de
              propiedad y/o uso exclusivo.
            </li>
            <br />
            <br />
            <li>
              <b>Nombres de dominio.</b>La Empresa es la única propietaria de
              los Nombres de Dominio. Nada de los establecido en el presente
              documento le da al Usuario una licencia o permiso de uso de los
              Nombres de Dominio, en consecuencia, el Usuario se obliga a
              abstenerse de: (i) invocar algún derecho moral, patrimonial, real,
              de propiedad, posesión o titularidad sobre los Nombres de Dominio;
              (ii) inducir a terceras personas a creer que el Usuario es el
              dueño, titular o licenciatario de los Nombres de Dominio; (iii)
              presentar reclamación, denuncia, demanda o interpelación ante la
              Corporación de Internet para la Asignación de Nombres y Números
              (en adelante ICANN) o cualquier otra corporación u organismo
              similar o análogo, alegando que el Usuario es el propietario o
              titular de los Nombres de Dominio; (iv) Realizar cualquier acto
              tendiente a bloquear el acceso de terceras personas a cualquier
              sitio de internet vinculado a los Nombres de Dominio; (v) Iniciar,
              coordinar, organizar, proponer o efectuar un “ataque de denegación
              de servicio” (DoS) o cualquier otro similar, que pudiera tener por
              objeto evitar que cualquier sitio de internet vinculado con los
              Nombres de Dominio pueda desplegarse en algún navegador de
              internet y; (vi) Obtener un lucro o ganancia económica del uso del
              citado nombre de dominio.
            </li>
            <br />
            <br />
            <li>
              <b>Hipervínculos y funciones.</b> Es posible que la Empresa
              incluya, en cualquiera de los Recursos en Línea, uno o varios
              hipervínculos y/o funciones que dirijan al Usuario o lo permitan
              interactuar con sitios web, sistemas, programas de cómputo o bases
              de datos, propiedad de terceras personas. En consecuencia, la
              Empresa no será responsable: i) por el contenido que se encuentre
              publicado en los sitios web, sistemas, programas de cómputo o
              bases de datos a los que le dirijan los hipervínculos mencionados;
              ii) de cualquier daño, cualquiera sea su naturaleza, que los
              sitios web, sistemas, programas de cómputo o bases de datos de
              terceras personas o los contenidos en él publicados causen al
              Usuario, a sus Dispositivos, o a terceros o; iii) cumplir con
              cualquier obligación que el Usuario haya contraído con las
              personas que administren los sitios web, sistemas, programas de
              cómputo o bases de datos a los que se tenga acceso.
            </li>
            <br />
            <br />
            <li>
              <b>Alertas.</b> El Usuario acepta que la Empresa podrá enviarle
              alertas a su correo electrónico, su Dispositivo o su número de
              teléfono celular, en cualquier momento. Estas alertas podrán
              incluir, notificaciones sobre actualizaciones en cualquiera de los
              Recursos en Línea, actividad en su Cuenta de Usuario, inicio de
              sesiones en la misma, el uso, modificación o cambio de sus Claves
              de Acceso o cualquier otra de manera similar o análoga. La Empresa
              se obliga a cumplir con todas las leyes aplicables en la República
              Mexicana para cuidar su privacidad e intimidad digital.
            </li>
            <br />
            <br />
            <li>
              <b>Confidencialidad y datos personales.</b>La Empresa se obliga a
              tratar la información del Usuario de conformidad con lo siguiente:
              <br /> <br /> <b>11.1. Confidencialidad.</b> La Empresa se obliga
              a guardar la más estricta confidencialidad sobre la información
              del Usuario. La Empresa únicamente divulgará la información del
              Usuario en caso de que (i) el Usuario lo autorice expresamente;
              (ii) lo permita cualquier legislación aplicable y vigente en la
              República Mexicana o; (iii) lo requiera alguna autoridad
              competente.
              <br /> <br /> <b>11.2. Datos personales.</b> Los datos personales
              del Usuario, que la Empresa obtenga a través de los Recursos en
              Línea, serán tratados conforme al Aviso de Privacidad, mismo que
              se tiene aquí por reproducido como si a la letra se insertase. Al
              usar cualquiera de los Recursos en Línea, el Usuario acepta el
              Aviso de Privacidad y otorga su consentimiento para que la Empresa
              pueda tratar sus datos personales de conformidad con dicho aviso.
            </li>
            <br />
            <br />
            <li>
              <b>Protección al consumidor.</b>
              <br /> <br /> <b>12.1. Información del proveedor.</b> La Empresa
              manifiesta: i) que su domicilio físico es el ubicado en Calle Ures
              Sur 137, Colonia Centro, Tepic, Nayarit, México ; ii) su teléfono
              de contacto es (33) 36361935 y; iii) su correo electrónico de
              contacto es atnclientes@probenso.com. El Usuario podrá hacer uso
              de estos medios para presentar reclamaciones o solicitar
              aclaraciones en cualquier tiempo.
              <br /> <br />{" "}
              <b>12.2. Información de los productos y servicios. </b>La Empresa
              se obliga a ser lo más transparente posible con respecto a la
              calidad, características, precios y demás información relacionada
              con los Recursos en Línea. Si el Usuario tiene alguna duda deberá
              contactar a la Empresa.
              <br /> <br /> <b>12.3. Precios.</b> Todos los precios de los
              productos o servicios que la Empresa ofrece, publicita y cobra por
              el uso de los Recursos en Línea son veraces, comprobables, claros
              y exentos de cualquier información que pueda causar confusión. Si
              el Usuario tiene alguna duda deberá contactar a la Empresa.
              <br /> <br /> <b>12.4. Precios de terceros.</b> La Empresa no será
              responsable de garantizar que los precios de los productos o
              servicios que terceras personas ofrezcan a través de cualquiera de
              los Recursos en Línea, sean veraces, comprobables, claros y
              exentos de cualquier información que pueda causar confusión.
              <br /> <br /> <b>12.5. Competencia de Profeco.</b> Si el Usuario
              se encuentra en la República Mexicana, la Procuraduría Federal del
              Consumidor (PROFECO) será competente, en la vía administrativa,
              para resolver cualquier controversia que se suscite sobre la
              interpretación o cumplimiento del presente contrato.
            </li>
            <br /> <br />
            <li>
              <b>Disponibilidad del servicio. </b>La Empresa no garantiza ningún
              nivel de funcionalidad o disponibilidad de los Recursos en Línea.
              El Usuario acepta y reconoce que existen diversos factores, que no
              dependen de la Empresa y que pueden afectar el funcionamiento de
              los Recursos en Línea, como, por ejemplo, fallas generalizadas en
              el servicio de acceso a internet, desconexión o conexión
              intermitente de los sistemas informáticos o vulneraciones de
              seguridad. Por tal virtud, en la medida máxima permitida por la
              legislación aplicable, la Empresa no se hace responsable de
              cualquier daño o perjuicio que el Usuario sufra en caso de que
              cualquiera de los Recursos en Línea no estén disponibles al
              momento en que el Usuario quiera utilizarlos.
            </li>
            <br /> <br />
            <li>
              <b>Modificaciones.</b> La Empresa se reserva el derecho de
              modificar el presente contrato en cualquier tiempo. Sin embargo,
              para que el Usuario quede obligado a cumplir con los términos
              modificados del presente contrato, este deberá manifestar su
              consentimiento en obligarse con dichos términos. La Empresa podrá
              poner a disposición del Usuario los medios oportunos para que este
              pueda manifestar su consentimiento. El Usuario estará aceptando de
              manera expresa, obligarse en los términos de cualquier cambio o
              modificación realizada al presente contrato, si utiliza cualquiera
              de los Recursos en Línea con posterioridad a que los cambios y/o
              modificaciones hayan sido realizados.
            </li>
            <br /> <br />
            <li>
              <b>Limitante de responsabilidad.</b>En la celebración del presente
              contrato, la Empresa no le otorga al Usuario ninguna garantía, ni
              implícita o explícita, sobre la calidad, contenido,
              funcionamiento, desempeño o viabilidad de los Recursos en Línea.
              El Usuario acepta que los Recursos en Línea se ofrecen ad-corpus,
              es decir, tal cual se encuentran y tal cual funcionan. El Usuario
              acepta y reconoce que la Empresa, únicamente será responsable de
              cumplir con la responsabilidad contractual que se deriva de un
              contrato de licencia de programa de cómputo. La Empresa será
              responsable de pagar al Usuario los daños y perjuicios que se
              deriven del incumplimiento del presente contrato en los términos y
              hasta los límites que permite la legislación vigente en la
              República Mexicana, salvo que dicho incumplimiento se origine por
              caso fortuito, fuerza mayor o por cualquier situación fuera del
              control de la Empresa. El Usuario acepta y reconoce que la Empresa
              no tendrá responsabilidad penal por cualquier delito que el
              Usuario o cualquier tercero cometan, a través o mediante el uso de
              cualquiera de los Recursos en Línea. El Usuario se compromete a no
              demandar, denunciar ni presentar querella alguna en contra de la
              Empresa en caso de que el Usuario sea víctima de algún delito o
              hecho ilícito cometido en su contra por un tercero, a través de
              cualquiera de los Recursos en Línea. El Usuario entiende que la
              Empresa no puede responder por los hechos cometidos o por actos
              celebrados por terceras personas. El Usuario acepta y reconoce que
              la Empresa no será responsable de indemnizar por cualquier daño o
              perjuicio, sea moral o material, que sufra o pudiera sufrir
              derivado de:
              <br /> <br /> <b>15.1. </b>El incumplimiento, o cumplimiento
              deficiente, de cualquier obligación que el Usuario celebre con un
              tercero, a través de la Plataforma;
              <br /> <br /> <b>15.2.</b> La cancelación, suspensión o bloqueo de
              su Cuenta de Usuario;
              <br /> <br /> <b>15.3.</b> La pérdida o extravío de Claves de
              Acceso;
              <br /> <br /> <b>15.4. </b>La no disponibilidad o el
              funcionamiento deficiente de cualquiera de los Recursos en Línea;
              <br /> <br /> <b>15.5.</b> El uso incorrecto, inadecuado o en
              violación al presente contrato, que el Usuario haga de los
              Recursos en Línea;
              <br /> <br /> <b>15.6.</b> El impedimento que el Usuario tenga de
              acceder a o de utilizar los Recursos en Línea o Cuenta de Usuario
              derivados de: (i) su falta de acceso Internet; (ii) las fallas
              generalizadas en su servicio de acceso a Internet; (iv) su falta
              de un Dispositivo adecuado para acceder a los Recursos en Línea;
              (v) la no compatibilidad de su Dispositivo o de el sistema
              operativo de este con cualquiera de los Recursos en Línea o; (vi)
              cualquier otra situación, cualquiera sea su naturaleza.
              <br /> <br /> <b>15.7.</b> El uso que un tercero haga de las
              Claves de Acceso del Usuario estando o no autorizado para usarlas;
              <br /> <br /> <b>15.8. </b> La remoción que la Empresa haga de
              cualquier contenido de conformidad con lo establecido en el
              presente contrato;
              <br /> <br /> <b>15.9.</b> El uso, autorizado o no autorizado, que
              haga un tercero de cualquiera de los Recursos en Línea;
              <br /> <br /> <b>15.10.</b> La comisión de cualquier hecho
              ilícito, o delito en su contra o la de terceros a través de los
              Recursos en Línea o cualquier otro de los sistemas tecnológicos o
              informáticos de la Empresa, como pueden ser, el acceso no
              autorizado o excediendo la autorización conferida para usar un
              sistema informático, robo o suplantación de identidad, fraude,
              extorsión, abuso de confianza, revelación de secretos (incluidos
              los industriales), infracciones en materia de comercio, robo de
              información bancaria o de números de tarjetas de crédito o débito;
              <br /> <br /> <b>15.11.</b> La comisión, a través de los Recursos
              en Línea, de cualquier hecho o acto, lícito o ilícito, que le
              cause al Usuario o a un tercero un daño o un perjuicio, ya sea
              moral o material;
              <br /> <br /> <b>15.12.</b> La vulneración de seguridad que sufra
              su Cuenta de Usuario, cualquiera de los Recursos en Línea, las
              bases de datos o cualquiera de los sistemas o registros
              informáticos de la Empresa;
              <br /> <br /> <b>15.13.</b> Los ataques informáticos, cualquiera
              sea su naturaleza o causa, que reciban los Recursos en Línea, las
              bases de datos o cualquiera de los sistemas o registros
              informáticos de la Empresa;
            </li>
            <br /> <br />
            <li>
              <b>Indemnidad. </b> El Usuario acepta y reconoce que el uso de
              cualquiera de los Recursos en Línea es su absoluta
              responsabilidad. En consecuencia, el Usuario se obliga a sacar en
              paz y a salvo a la Empresa, así como a coadyuvar con la Empresa en
              la defensa efectiva de sus derechos en cualquier juicio, proceso,
              procedimiento, demanda, denuncia, querella, reclamo, interpelación
              o cualquier otro tipo de reclamación o proceso de naturaleza
              similar o análoga, ya sea judicial o extrajudicial, que cualquier
              tercero ejerza contra la Empresa derivado: i) del uso que el
              Usuario haya dado a los Recursos en Línea o; ii) de cualquier
              incumplimiento al presente documento que el Usuario comenta.
            </li>
            <br /> <br />
            <li>
              <b>Terminación de la licencia.</b> El Usuario podrá terminar el
              presente contrato en cualquier tiempo sin necesidad de declaración
              judicial o administrativa para tal efecto, mediante la eliminación
              de su Cuenta de Usuario, siguiendo el procedimiento para tal
              efecto establecido en la Plataforma.
              <br />
              La Empresa podrá terminar el presente contrato y revocarle
              cualquiera de las licencias otorgadas en el presente documento, en
              cualquier tiempo y sin necesidad de declaración judicial o
              administrativa para tal efecto, en caso de que el Usuario incumpla
              con cualquiera de las obligaciones que asume por medio de este
              documento.
            </li>
            <br /> <br />
            <li>
              <b>Notificaciones. </b>Todas las notificaciones relacionadas con
              el presente contrato y con el uso de cualquiera de los Recursos en
              Línea deberán realizarse de la siguiente manera:
              <br /> <br /> <b>18.1.</b> El Usuario deberá notificar a la
              Empresa a través de cualquiera de los Medios de Contacto;
              <br /> <br /> <b>18.2.</b> La Empresa le notificará al Usuario
              cualquier información relacionada con el presente contrato en
              cualquiera de los siguientes medios:
              <br />
              <br />
              <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;18.2.1.</b>A la
              dirección de correo electrónico con la que el Usuario se haya
              registrado en la Plataforma;
              <br />
              <br />
              <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;18.2.2.</b>A
              cualquier otra dirección de correo electrónico que el Usuario haya
              proporcionado para tal efecto o;
              <br />
              <br />
              <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;18.2.3.</b>A
              través de la Plataforma, siempre y cuando dicha plataforma permita
              la referida funcionalidad.
            </li>
            <br /> <br />
            <li>
              <b>Acuerdo legal.</b>Este documento es un contrato vinculante
              entre las partes y tiene validez legal plena. En caso de dudas, se
              recomienda que el Usuario consulte su contenido con un licenciado
              en derecho de su confianza. Salvo que las partes acuerden lo
              contrario, este es el acuerdo final, totalmente integrado y único
              entre las partes referente al uso de los Recursos en Línea. La
              nulidad de alguna cláusula del presente contrato no invalidará la
              totalidad del presente contrato. En caso de que alguna de las
              cláusulas del presente contrato sea declarada nula por algún
              tribunal o autoridad competente, dicha cláusula se separará del
              presente contrato y este continuará su vigencia aplicándose
              aquellas disposiciones que no hayan sido invalidadas.
            </li>
            <br /> <br />
            <li>
              <b>Jurisdicción y competencia</b> Salvo que se establezca lo
              contrario en el presente documento: (i) para la interpretación y
              aplicación del presente contrato se aplicarán las leyes vigentes
              en la República Mexicana y; (ii) sin perjuicio de lo dispuesto en
              la cláusula de Solución de Controversias, los tribunales con sede
              en la Ciudad de México serán competentes para resolver cualquier
              controversia que se suscite con motivo de la ejecución del laudo
              arbitral.
            </li>
          </ol>
          <b></b>
          <br /> <br /> <b></b>
        </p>
      </div>
      <Footer />
    </>
  );
}
