import { useState,useEffect, } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { bindActionCreators } from "redux";

import ApiClient from "../../../../../services/apiclient";
import { nextStep } from "../../../../../store/actions/PrestamosPersonalesActions";
import { ProgressCircular } from "../../../Progress";
import actividadesEconomicas from "../../../../credit-forms/utils/actividadesEconomicas";
import "./styles.css";
import Menu from "../Menu/Menu";
import { useHistory } from "react-router-dom";

const EmployeesForm = ({
  nextStep,
  nominaId,
}) => {

  const apiClient = ApiClient.getInstance();

  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit,reset, formState: { errors } } = useForm();
  const[preInfo,setPreInfo] = useState(null);
  const history = useHistory();

  const onSubmit = values => {

    const data = {
      companyName: values?.companyName,
      salary: Number(values?.salary),
      jobPosition: values?.jobPosition,
      antiquity: Number(values?.antiquity),
      antiquityMonth: Number(values?.antiquityMonth),
      economicActivity: values?.economicActivity,
      phone: values?.phone
    };

    setIsLoading(true)

    apiClient.laborForm(data, nominaId)
      .then(res => {
        if(res.error?.message?.rechazado){
          history.push("/RECHAZADO/personal/loan-denied")
        } 
        else{
          apiClient.sendSmsCdc();
          nextStep("CDC")//("PERSONAL-REFERENCES")
        }
      })
      .catch(e => {
        console.log(e)
        
      })
  };

  useEffect(() => {

    apiClient.getUserPreInfo().then(data => {
      const userPreInfo = data;
      
      setPreInfo(userPreInfo);
      reset(userPreInfo);
     
      
    });

  }, []);

  return (
    <div className="solicitud__container">
      <div className="solicitud-b__container">
        <form onSubmit={handleSubmit(onSubmit)} id="div-perfil">

          <Menu step="labor" />

          <div id="4" className="solicitud-4__input-group informacionC">


            <div>
              <label htmlFor="companyName">Nombre de la compañía</label>
              <input
                tabIndex={1}
                type="text"
                {...register("companyName", { required: true })}
                className={errors.companyName ? "inputErr" : ""}

                placeholder="Ej."
              />
            </div>

            <div>
              <label htmlFor="salary">Sueldo *</label>
              <input
                tabIndex={2}
                type="number"
                id="salary"
                name="salary"
                placeholder="Ej."
                {...register("salary", { required: true })}
                className={errors.salary ? "inputErr" : ""}
              />
            </div>

            <div>
              <label htmlFor="jobPosition">Puesto de trabajo *</label>
              <input
                type="text"
                id="jobPosition"
                name="jobPosition"
                placeholder="Ej."
                {...register("jobPosition", { required: true })}
                className={errors.jobPosition ? "inputErr" : ""}
              />
            </div>

            <div>
              <label htmlFor="antiquity">Antigüedad *</label>
              <div className="domicilioTime">

                <input
                  tabIndex={25}
                  type="number"
                  id="antiquity"
                  name="antiquity"
                  placeholder="Años"
                  min="0"
                  max="99"
                  {...register("antiquity", { required: true, pattern: /[0-9]+/ })}
                  className={errors.antiquity ? "domicilioTimeI inputErr" : "domicilioTimeI"}
                />
                <input
                  tabIndex={25}
                  type="number"
                  placeholder="Meses"
                  min="0"
                  max="12"
                  {...register("antiquityMonth", { required: true, pattern: /[0-9]+/ })}
                  className={errors.antiquityMonth ? "domicilioTimeI inputErr" : "domicilioTimeI"}
                />

              </div>
            </div>

            <div>
              <label htmlFor="economicActivity">Actividad Económica *</label>
              <select
                tabIndex={26}
                id="economicActivity"
                name="economicActivity"
                {...register("economicActivity", { required: true })}
                className={errors.economicActivity ? "input-select inputErr" : "input-select"}
              >
                <option value="">Seleccione una opción</option>
                {actividadesEconomicas.map((actividad: any) => (
                  <option key={actividad} value={actividad}>
                    {actividad}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="phone">Telefono *</label>
              <input
                tabIndex={6}
                type="text"
                id="phone"
                name="phone"
                placeholder="Ejemplo: 9999999999"
                {...register("phone", { required: true, pattern: /[0-9]+/ })}
                className={errors.phone ? "inputErr" : ""}
              />
            </div>

          </div>

          <div className="btnSubmitWrapEmployees">
            <button
              className="btn blue"
              type="submit"
              disabled={isLoading}
            >
              <ProgressCircular loading={isLoading} title='Siguiente' />
            </button>
          </div>
        </form>

      </div>
    </div>
  )
};

const mapStateToProps = ({ personalCreditReducer }) => ({
  user: personalCreditReducer?.user ?? {},
  nominaId: personalCreditReducer?.nominaId ?? "",
});
console.log("DDDDDDDDDDDDDDDDDDDDDDDDD",nextStep);
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    nextStep,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesForm);
