import React, { useEffect, useState } from "react";

import AlertMessage from "../../../../alert.component";
import "../informacion.css";
import ApiClient from "../../../../../services/apiclient";

import Popup from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import actividadesEconomicas from "../../../../credit-forms/utils/actividadesEconomicas";
import { ProgressCircular } from "../../../Progress";
import logo from '../../../../../assets/1.png';
import logo2 from '../../../../../assets/2.png';
import logo3 from '../../../../../assets/3.png';
import logo4 from '../../../../../assets/4.png';

export default function DatosLaborales({ userData, setUserData, nextStep, setCurrentPage, productName,prevStep }) {
  const [openMessage, setOpenMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.log(userData);
  }, []);
  const apiClient = ApiClient.getInstance();
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const onsubmitForm = (values: any, actions: any) => {
    setLoading(true);

    if(!values.laborData.antiquityMonth){
      values.laborData.antiquityMonth = 0;
    }
    
    setUserData(values);
    console.log(values);
    apiClient.createPABSloan(values).then(data => {
      apiClient.setProductName(productName).then(data => {
        console.log(data);
        nextStep();
      }).catch(err => console.error(err));
    }).catch(err => {
      console.log('error', err);
      if(err === 'Sin permiso'){
        // setOpenMessage(true);
        // setMessage('Se necesita activar el permiso de Geolocalizacion para poder continuar.');
        // setSeverity('error');
        setPopupVisibility(true);
      }else{
        setOpenMessage(true);
        setMessage('No se puede continuar al siguiente paso, verifique la informacion.');
        setSeverity('error');
      }
      /*setUserData({...userData, address: {
        street: "",
        interiorNumber: "",
        exteriorNumber: "",
        suburb: "",
        postalCode: "",
        city: "",
        state: "",
        country: "",
        homePhone: "",
        antiquity: 0,
        propertyType: ""
      }})*/
      setLoading(false);
      /*setTimeout(
        () => setCurrentPage(1),
        4000
    );*/
    })

  };



  const validationSchema = Yup.object().shape({

    laborData: Yup.object({
      companyName: Yup.string().required("Requerido"),
      salary: Yup.number().required("Requerido"),
      jobPosition: Yup.string().required("Requerido"),
      antiquity: Yup.number().nullable().required("Requerido"),
      antiquityMonth: Yup.number().nullable().required("Requerido"),
      economicActivity: Yup.string().required("Requerido"),
      phone: Yup.string().required("Requerido"),

    })

  });

  const handleClose = () => {
    setOpenMessage(false);
  };
  const [isPopupVisible, setPopupVisibility] = useState(false);

  return (
    <div>
      <Popup
        visible={isPopupVisible}
        closeOnOutsideClick={false}
        width={1000}
        height={700}
        showTitle={false}
      >
        <ScrollView width="100%" height="100%">
        <div style={{textAlign: 'center'}}>
          <h3>Para poder continuar necesitas permitar acceso a la Geolocalizacion.</h3>
          <h3>Sigue los siguientes pasos para poder continuar.</h3>
          <br></br>
          <p>Dirigete en la parte superior izquierda de tu navegador. </p>
          <br></br>
          <img style={{width: 320}} src={logo} />
          <br></br>
          <br></br>
          <p>Haz click sobre el candado(informacion de la pagina). </p>
          <br></br>
          <img style={{width: 320}} src={logo2} />
          <br></br>
          <br></br>
          <p>Ubicate en la seccion de "Ubicacion". </p>
          <br></br>
          <img style={{width: 320}} src={logo3} />

          <br></br>
          <br></br>
          <p>Y selecciona la opcion de "Permitir". </p>
          <br></br>
          <img style={{width: 320}} src={logo4} />

          <br></br>
          <br></br>
          <p>Actualiza la pagina para guadar cambios. </p>

          <div className="">
            <button className="btn blue" type="submit" onClick={() => setPopupVisibility(false)} disabled={loading}>
              Cerrar 
            </button>
        </div>
        </div>
        </ScrollView>
      </Popup>
      <AlertMessage
      open={openMessage}
      message={message}
      handleClose={handleClose}
      severity={severity} />
      <div className="solicitud__container">
        <div className="solicitud-b__container">
          <div className="navbar2">
            <a className="hg11">1. Validación</a>
            <a className="hg21">2. Información personal</a>
            <a className="hg31">3. Referencias</a>
            <a className="hg31">4. Perfil</a>
            <a className="hg41 ">5. Titulo PABS</a>
          </div>
          <p>
            {" "}
            <b>2.3 Datos laborales</b>{" "}
          </p>

          <Formik
            initialValues={userData}
            onSubmit={onsubmitForm}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, errors, touched }) => (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="solicitud-4__input-group informacionC">
                  <div>
                    <label htmlFor="companyName">
                      Nombre de la compañia en que trabajas
                    </label>
                    <Field

                      type="text"
                      id="companyName"
                      name="laborData.companyName"
                      maxlength="50"

                    />
                    <ErrorMessage name={`laborData.companyName`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="salary">Sueldo Mensual</label>
                    <Field

                      type="number"
                      id="salary"
                      name="laborData.salary"
                      min="0"
                    />
                    <ErrorMessage name={`laborData.salary`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="jobPosition">Puesto de trabajo</label>
                    <Field

                      type="text"
                      id="jobPosition"
                      name="laborData.jobPosition"
                      maxlength="50"

                    />

                    <ErrorMessage name={`laborData.jobPosition`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>

                  </div>

                  <div>
                    <div >
                    <label htmlFor="antiquity" className="DL" >
                        ¿Cuanto tiempo llevas trabajando ahí?
                      </label>

                      <div className="DTime">
                      
                      <Field
                      className="DTimeI" 
                        type="number"
                        min="0"
                        placeholder="Años"
                        id="antiquity"
                      
                        name="laborData.antiquity"
                      />
                      
                      <Field 
                      className="DTimeI" 
                    
                        type="number"
                        id="antiquityMonth"
                        min="0"
                        placeholder="Meses"
                        name="laborData.antiquityMonth"
                      />
                      </div>

                      <div className="DTimeE">
                        
                      <ErrorMessage  name={`laborData.antiquity`}>
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                      
                      <ErrorMessage name={`laborData.antiquityMonth`}>
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                        
                        </div>
                      
                      
                    </div>
                  </div>

                  <div>
                    <label htmlFor="economicActivity">Actividad económica</label>
                    <Field
                      as="select"
                      type="text"
                      id="economicActivity"
                      name="laborData.economicActivity"
                      maxlength="20"

                    >
                      <option value="">Seleccione una opción</option>
                      {actividadesEconomicas.map((actividad: any) => (
                        <option key={actividad} value={actividad}>
                          {actividad}
                        </option>
                      ))}
                    </Field>

                    <ErrorMessage name={`laborData.economicActivity`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>

                  </div>

                  <div>
                    <label htmlFor="phone">Teléfono</label>
                    <Field

                      type="text"
                      id="phone"
                      name="laborData.phone"
                      maxlength="15"

                    />

                    <ErrorMessage name={`laborData.phone`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>

                  </div>


                </div>

               
                <div className="Button-cpr">
                <button onClick={prevStep} className="btn blue" disabled={loading}>
                    Regresar
                  </button>
                  <button type="submit" className="btn blue" disabled={loading}>
                    <ProgressCircular loading={loading} title='Continuar'/>
                  </button>
                </div>

              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
