import HeaderDashboard from "../../headerDashboard.component";

import savelogo from "../../../assets/save-money_1.png";
import pabs from "../../../assets/PABS.png";
import nomina from "../../../assets/nomina.png";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import {NOMINA_ACTIVE, PABS_ACTIVE, PERSONAL_ACTIVE} from '../../../utils/config'

function CreditType() {
  
  const [creditsTypes, setCreditsTypes] = useState([]);
  const history = useHistory()

  useEffect(() => {
    let activeCredits = []

    if(PABS_ACTIVE) activeCredits = [
      ...activeCredits,
      {
        type: 'pabs',
        url: '/PABS',
        title: 'Crédito personal con garantía',
        img: pabs
      },
    ]

    if(NOMINA_ACTIVE) activeCredits = [
      ...activeCredits,
      {
        type: 'nomina',
        url: '/Nomina',
        title: 'Crédito personal sin garantía',
        img: nomina
      },
    ]
    
    if(PERSONAL_ACTIVE) activeCredits = [
      ...activeCredits,
      {
        type: 'personal',
        url: '/prestamo-personal',
        title: 'Crédito personal',
        img: savelogo
      },
    ]
    
    if(activeCredits.length === 1) history.push(activeCredits[0].url)
    else setCreditsTypes(activeCredits);
  },[]);
  
  return (
    <div>
      <HeaderDashboard />

      <div className="dashboard__container">
        <div className="dashboard-usuario-1__content">
          <div className="dashboard-usuario-2">
            <div className="dashboard-user__header credit-type-header">
              <div>
                <h1>¡Hola! Elige el tipo de credito que te interesa</h1>
              </div>
            </div>


            <div className="dashboard-banck__small-cards-container">
              {
                creditsTypes.map(({url, title, img}, i) =>
                  <Link key={i} to={url}>
                    <div >
                      <img src={img} alt="" />
                      <p style={{textAlign: 'center'}}>
                        <b>{title}</b>
                      </p>
                    </div>
                  </Link> )
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditType;
