import React, { useEffect,useState } from 'react';
import Header from '../header.Nomina.component';
import Footer from './footerNomina';
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import avisopng from './avisoimg.png'
import AvisoPrivacidadView from './avisoPrivacidadView';

export default function AvisoPrivacidad() {

  useEffect(() => {
    document.querySelector('body').scrollTo(0,0)
  }, []);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  

  return (
    <div>
      <Header />

        <AvisoPrivacidadView />

      <Footer />
    </div>
  );
}
