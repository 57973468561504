import {Container} from '@material-ui/core';
import React from 'react';
import Triangle1 from '../../assets/Triangle1.png';
import Header from '../header.component';

const EDF = () => {
  return (
    <div className="cultura-financiera">
      <img className="back-image" src={Triangle1} />
      <Header />
      <Container>
        <div className="imagecalc">.</div>
        <div className="textocalc">
          <h1 className="h1jm">Cultura Financiera</h1>
          <h2>Aprende los tips básicos de finanzas</h2>
          <ul>
            <li>Tasas de interés</li>
            <li>Como funciona un préstamo</li>
            <li>Como puedo hacer crecer un préstamo</li>
            <li>Crédito personal</li>
            <li>Buró de crédito</li>
            <li>Como mejorar mi cultura financiera</li>
          </ul>
          <div className="prestamos-personales">
            ​<h2> PRÉSTAMOS PERSONALES</h2>
            <p>
              Con demasiada frecuencia solemos recurrir a las entidades
              bancarias para conseguir préstamos personales. Se tratan de
              acuerdos a través de los cuales el banco se compromete al adelanto
              de una cantidad de dinero a una persona que recibe la denominación
              de prestatario. Dicha persona tiene la obligación de devolver ese
              importe y pagar además unos intereses pactados previamente y unos
              gastos vinculados a la transacción.
            </p>
            <p>
              Los bancos ofrecen una gran cantidad de préstamos personales,
              también conocidos como préstamos de consumo, que en ocasiones
              aparecen con nombres comerciales del tipo ‘créditos vacaciones’,
              ‘préstamo coche’, etc.
            </p>
            <p>
              Antes de decantarse por un préstamo personal hay que reparar en
              una serie de aspectos que son comunes a todos:
            </p>
            <ol>
              <li>Tipo interés</li>
              <li>Comisiones de aperturación y cancelación</li>
              <li>Otros gastos</li>
              <li>Plazo de amortización</li>
              <li>Importe de la cuota mensual</li>
            </ol>
            <p>
              En lo referente a los tipos de interés en los préstamos
              personales, suelen ser de interés fijo o variable. Un tipo fijo se
              mantendrá siempre al margen de las fluctuaciones en los tipos de
              mercado, mientras que el interés variable varía con el mercado. La
              elección entre ambas opciones se limita a la capacidad del usuario
              para lidiar con los posibles cambios en la deuda.
            </p>
            <p>
              Además de reparar en el tipo de interés nominal, también hay que
              hacerlo en la TAE. Se trata de un cálculo un poco más más complejo
              que incluye el tipo de interés nominal y las comisiones que puedan
              aplicarle a su préstamo, valorando el plazo de la operación. Es un
              indicador más fiable del coste real del préstamo.
            </p>
            <p>
              Aunque a simple vista algunos préstamos presenten un tipo de
              interés nominal bajo, sí que pueden acumular distintas comisiones
              por diferentes conceptos, como pueden ser la apertura, cancelación
              o amortización parcial.
            </p>
          </div>
          <h2>Funcionamiento De Los Créditos Personales</h2>
          <p>
            En los préstamos personales, a diferencia de los préstamos
            hipotecarios, la entidad financiera dispone de una garantía de
            producirse un impago. La persona encargada de contratar el préstamo
            personal debe poner como garantía sus bienes, mientras que en el
            caso de un préstamo hipotecario, además de la garantía personal
            también estará el inmueble hipotecado, que en caso de impago pasará
            a manos del banco.
          </p>
          <p>
            Los personales suelen contar con un tipo de interés más alto y un
            plazo de amortización más pequeño respecto a los hipotecarios, lo
            que implica que sean más caros y que haya que devolverlos en un
            menor plazo de tiempo. La cantidad prestada también será inferior.
          </p>
          <p>
            Por lo general, aquellos clientes que dispongan productos
            contratados con una entidad bancaria y saldos elevados tendrán más
            facilidades para obtener créditos con condiciones más ventajosas.
          </p>
        </div>
      </Container>
      ​ ​
    </div>
  );
};
export default EDF;
