import React, {useEffect, useState} from 'react';
import LOGO from '../../assets/lOGO.png';
import {Link} from 'react-router-dom';
import { TramiteCreditoContext } from '../../context/TramiteCreditoProvider';
import {useHistory} from 'react-router';
import { UserContext } from '../../context/UserProvider';
import facebook from '../../assets/facebook.png';
import question from '../../assets/question.png';

function Footer(props){
    const {credito, updateCredito} = React.useContext(TramiteCreditoContext);
    const {userMain, updateUser} = React.useContext(UserContext);
    const user = userMain.nombre;
    const history = useHistory();
    var classe =  props.class;
    function redirecComponent(e: {preventDefault: () => void}) {
        e.preventDefault();

        if (credito.estatusSolicitud === 'aprobado') {
            if (credito.isDelivered) {
                return history.push('/credito-detalle');
            } else if (!credito.isDelivered) {
                return history.push('/dashboard');
            }
        } else if (credito.estatusSolicitud === 'revision') {
            return history.push('/proceso-credito');
        } else {
            return history.push('/sin-credito');
        }
    }


    function redirecEspacioSolicitud(e: {preventDefault: () => void}) {
        e.preventDefault();
    
        if (user != 'Iniciar Sesión') {
          redirecComponent(e);
        } else {
          return history.push('/step1');
        }
    }

    return(
        <footer >
        <div className="footer__first-container">
          <div>
            <img className="logo" src={LOGO} />
            <Link to="/aviso-de-privacidad">Aviso de privacidad</Link>
            <Link to="/terminos-y-condiciones">Términos y condiciones</Link>
            <Link to="/faq">Preguntas frecuentes</Link>
            <Link to="/derechos-arco">Derechos ARCO</Link>
            <a href="#" onClick={redirecEspacioSolicitud}>
              Quiero solicitar mi crédito
            </a>
          </div>
          <div className="footer__social-icons">
            <a
              target="_blank"
              href="https://www.facebook.com/PercapitaPrestamos"
            >
              <img src={facebook} />
            </a>
            <img src={question} />
          </div>
        </div>
        <div>
          <p>
            El Buró de Entidades Financieras contiene información de ADVANTECH
            SERVICIOS FINANCIEROS SAPI DE CV SOFOM ENR sobre nuestro desempeño
            frente a los Usuarios, por la prestación de productos y servicios.
            Te invitamos a consultarlo también en la página
            https://www.buro.gob.mx ó a través de
            https://www.percapita.mx/buropercapita/. De conformidad con lo
            dispuesto por el Artículo 87-J de la Ley General de Organizaciones
            y Actividades Auxiliares del Crédito, ‘Advantech Servicios
            Financieros’, Sociedad Anónima Promotora de Inversión de Capital
            Variable, Sociedad Financiera de Objeto Múltiple, Entidad No
            Regulada, no requiere de autorización de la Secretaria de Hacienda
            y Crédito Público para su constitución ni para la celebración del
            presente contrato, y está sujeta a la supervisión o vigilancia de
            la Comisión Nacional Bancaria y de Valores, únicamente en materia
            de prevención y detección de Operaciones con Recursos de
            Procedencia Ilícita, Terrorismo y Terrorismo Internacional, de
            conformidad a lo establecido en el artículo 56 de la Ley antes
            citada.
          </p>
        </div>
      </footer>
    )
}

export default Footer;