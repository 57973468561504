import React, { useEffect, useState } from "react";
import Carousel from "react-elastic-carousel";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import ahorros_de_hasta_mensuales from "../assets/ahorros_de_hasta_mensuales.png";
import BlueVector from "../assets/BlueVector.png";
import facebook from "../assets/facebook.png";
import familia1 from "../assets/familia1.png";
import icon_casa from "../assets/icon_casa.png";
import icon_confianza from "../assets/icon_confianza.png";
import icon_facilidad_prestamo from "../assets/icon_facilidad_prestamo.png";
import icon_lealtad from "../assets/icon_lealtad.png";
import icon_respaldo from "../assets/icon_respaldo.png";
import icon_servicio_express from "../assets/icon_servicio_express.png";
import LOGO from "../assets/lOGO.png";
import oxxo from "../assets/oxxo.png";
import question from "../assets/question.png";
import sin_comisiones_por_apertura from "../assets/sin_comisiones_por_apertura.png";
import Triangle1 from "../assets/Triangle1.png";
import user1 from "../assets/user1.png";
import user2 from "../assets/user2.png";
import user3 from "../assets/user3.png";
import user4 from "../assets/user4.jpg";
import user5 from "../assets/user5.jpg";
import user6 from "../assets/user6.jpg";
import { TramiteCreditoContext } from "../context/TramiteCreditoProvider";
import { UserContext } from "../context/UserProvider";
import CreditLoanService from "../utils/CreditLoanService";
import AlertMessage from "./alert.component";
import FormRegister from "./form.register.component";
import Header from "./header.component";
import Footer from "./pages/footer";
import CustomSlider from "./slider.component";
import ApiClient from "../services/apiclient";

function MainHome() {
  const { userMain, updateUser } = React.useContext(UserContext);
  const { credito, updateCredito } = React.useContext(TramiteCreditoContext);
  const [showSolicita, setShowSolicita] = useState(false);
  const apiClient = ApiClient.getInstance();

  const [statusPrestamo, setStatusPrestamo] = useState("");

  const user = userMain.nombre;
  const breakDownCalculator = new CreditLoanService();
  const history = useHistory();
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [loanAmount, setLoanAmount] = useState(0);
  const [pagoQuincenal, setPagoQuincenal] = useState(0);

  function redirecComponent(e: { preventDefault: () => void }) {
    e.preventDefault();

    if (credito.estatusSolicitud === "aprobado") {
      if (credito.isDelivered) {
        return history.push("/credito-detalle");
      } else if (!credito.isDelivered) {
        return history.push("/dashboard2");
      }
    } else if (credito.estatusSolicitud === "revision") {
      apiClient.getSteps().then((res) => {
        if (res.step === "FINISHED") {
          return history.push("/proceso-credito");
        } else {
          return history.push("/productos");
        }
      });
    } else if (credito.estatusSolicitud === "enviar-solicitud") {
      return history.push("/credito-config");
    } else {
      // return history.push('/sin-credito');
      return history.push("/productos");
    }
  }

  const requestLoan = () => {
    setOpenMessage(true);
    setMessage("Mensaje de prueba");
  };

  const handleClose = () => {
    setOpenMessage(false);
  };

  function valuetext(value: number) {
    setLoanAmount(value);
    return `${value}`;
  }

  const calcularPagoQuinal = () => {
    let solicitud = {
      loan_amount: loanAmount, // prestamo
      number_term_payment: 12, //numero de pagos a realizar
      form_of_payment: 2, // proceso semanal de pagos (3). proceso Quinsenal de pagos (2)
      score: 725, //646,
    };

    //console.log(loanAmount)

    let amortizar_prestamo = new CreditLoanService();

    const calculo_riesgo = amortizar_prestamo.getLoanBreakdown(solicitud);
    //console.log(calculo_riesgo);
    ///return calculo_riesgo;

    /* var principal = loanAmount;
    var interest = 110 / 100 / 26;
    console.log(interest);

    var payments = 26;

    var x = Math.pow(1 + interest, payments); //Math.pow computes powers
    var total = (principal * x * interest) / (x - 1); */

    setPagoQuincenal(calculo_riesgo.payment_breakdown[0].amount_payable);
  };

  useEffect(() => {
    if (user === "Iniciar Sesión") {
      setShowSolicita(true);
    } else {
      setShowSolicita(false);
    }
  }, [user]);

  var creditoLocal = "";
  if (localStorage.getItem("creditoLocal")) {
    var userLocal = JSON.parse(localStorage.getItem("creditoLocal"));
    creditoLocal = userLocal.estatusSolicitud;
  }
  useEffect(() => {
    //console.log(creditoLocal)
    setStatusPrestamo(creditoLocal);
  }, []);

  useEffect(() => {
    setLoanAmount(10000);
    calcularPagoQuinal();
  }, []);
  useEffect(() => {
    calcularPagoQuinal();
  }, [loanAmount]);

  function redirecEspacioSolicitud(e: { preventDefault: () => void }) {
    e.preventDefault();

    if (user != "Iniciar Sesión") {
      redirecComponent(e);
    } else {
      return history.push("/step1");
    }
  }

  //carrousel

  const [index, setIndex] = useState(0);

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1 },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 3, itemsToScroll: 2 },
  ];

  const statusPrestamoButton = () => {
    if (credito.estatusSolicitud === "enviar-solicitud") {
      return history.push("/credito-config");
    } else if (credito.estatusSolicitud === "revision") {
      return history.push("/proceso-credito");
    } else if (credito.estatusSolicitud === "aprobado") {
      return history.push("/dashboard2");
    }
  };

  const clickSinPrestamo = () => {
    if (credito.estatusSolicitud === "sin-solicitud") {
      return history.push("/type-credit-application");
    } else if (creditoLocal == "solicitud-no-aprobada") {
      return history.push("/sin-permiso-tramite");
    }
  };

  return (
    <div>
      <Header />
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
      />
      <div className="content">
        <section className="landing-section__container">
          <img src={Triangle1} className="background-triangle" />
          <div className="landing-section__first-column">
            <div className="landing-section__first-column--info">
              <h3>Te prestamos desde</h3>
              <h2>
                <span>$2,000</span> hasta <span>$20000</span> pesos
              </h2>
              <ul>
                <li>Plazo de hasta 1 año*</li>
                <li>Tu préstamo listo y el dinero en tu cuenta en 48 horas*</li>
                <li>
                  Paga tus comisiones en <img src={oxxo} /> o a domicilio
                </li>
              </ul>
            </div>
            {showSolicita ? (
              <div className="form-container">
                <h3>¡Solicita tu préstamo!</h3>
                <FormRegister type="landing" />
              </div>
            ) : null}

            {statusPrestamo == "enviar-solicitud" ? (
              <button
                className="blue landing-first-section-btn"
                type="button"
                onClick={statusPrestamoButton}
              >
                Completar solicitud
              </button>
            ) : statusPrestamo == "revision" ? (
              <button
                className="blue landing-first-section-btn"
                type="button"
                onClick={statusPrestamoButton}
              >
                Estatus de mi prestamo
              </button>
            ) : statusPrestamo == "sin-solicitud" ||
              statusPrestamo == "solicitud-no-aprobada" ? (
              <button
                className="blue landing-first-section-btn"
                type="button"
                onClick={clickSinPrestamo}
              >
                Solicitar prestamo
              </button>
            ) : statusPrestamo == "aprobado" ? (
              <button
                className="blue landing-first-section-btn"
                type="button"
                onClick={statusPrestamoButton}
              >
                Ver mi prestamo
              </button>
            ) : null}
          </div>
          <div className="landing-section__second-column">
            <div className="title-mobile-landing">
              <h1>
                Te prestamos desde <br />
                <strong>$2,000 </strong> <span>hasta</span> <br />
                <strong>$20,000 </strong>
                <span>pesos</span>
              </h1>
            </div>
            <div className="landing-section__second-column-texto">
              <h2>¿Cuánto dinero necesitas?</h2>
              <CustomSlider
                customLabelColor={"white"}
                defaultValue={10000}
                valueText={valuetext}
                step={500}
                min={2000}
                minLabel={"$2,000"}
                maxLabel={"$20,000"}
                max={20000}
                valueLabelFormat={(value: number) =>
                  value.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                }
              />

              <span>
                Pagos de{" "}
                <b>
                  {pagoQuincenal.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </b>{" "}
                quincenales por un año
              </span>
              <span>
                <b>Los montos son de carácter informativo.</b>
              </span>
              <button
                onClick={redirecEspacioSolicitud}
                className={
                  credito.estatusSolicitud === "aprobado" ? "gray" : "yellow"
                }
                disabled={credito.estatusSolicitud === "aprobado"}
              >
                Solicitar mi préstamo
              </button>
            </div>
            <img src={familia1} />
          </div>
        </section>

        <section className="como-funciona">
          <img src={BlueVector} />
          <div className="como-funciona__container">
            <h2>¿Cómo funciona Percapita?</h2>
            <span>
              Los apoyamos a salir adelante ante sus compromisos económicos y su
              desarrollo patrimonial, por medio de préstamos personales.
            </span>
            <div className="como-funciona__info">
              <div>
                <img src={icon_facilidad_prestamo} />
                <h4>Facilidad de préstamo</h4>
                <span>Sin trámites burocráticos o complicados</span>
              </div>
              <div>
                <img src={icon_respaldo} />
                <h4>Respaldo en todo momento</h4>
                <span>Somos una empresa legalmente constituida</span>
              </div>
              <div>
                <img src={icon_confianza} />
                <h4>Confianza</h4>
                <span>¡No tenemos cobros ocultos!</span>
              </div>
              <div>
                <img src={icon_servicio_express} />
                <h4>Servicio Express</h4>
                <span>Autorizaciones en minutos</span>
              </div>
              <div>
                <img src={icon_casa} />
                <h4>Seguro</h4>
                <span>Podemos ir a cobrarte directamente a tu domicilio</span>
              </div>
              <div>
                <img src={icon_lealtad} />
                <h4>Lealtad a tu economía</h4>
                <span>Aportaciones congeladas </span>
              </div>
            </div>
            <button
              onClick={redirecEspacioSolicitud}
              className={
                credito.estatusSolicitud === "aprobado" ? "gray" : "yellow"
              }
              disabled={credito.estatusSolicitud === "aprobado"}
            >
              Solicitar mi préstamo
            </button>
          </div>
        </section>

        <section className="banner-section__container">
          <div>
            <h3>Sin comisiones por apertura</h3>
          </div>
          <img src={sin_comisiones_por_apertura} />
          <div>
            <h3>Ahorros de hasta $1400 mensuales*</h3>
          </div>
          <img src={ahorros_de_hasta_mensuales} />
        </section>

        <section className="tienes-dudas-section__container">
          <h2>¿Aún tienes dudas?</h2>
          <span>
            Te invitamos a leer nuestra sección completa de
            <Link to="#"> Preguntas Frecuentes</Link> en donde explicamos a
            detalle las cosas que más dudas han tenido otras personas.
          </span>
          <div className="tienes-dudas-section__button-group">
            <Link to="/faq" className="blue btn">
              ¿Qué necesito para solicitar mi préstamo?
            </Link>
            <Link to="/faq" className="blue btn">
              ¿Cómo son los pagos?
            </Link>
            <Link to="/faq" className="blue btn">
              ¿Cuánto se tarda en ver reflejado mi préstamo?
            </Link>
            <Link to="/faq" className="blue btn">
              ¿Qué pasa si no puedo pagar una mensualidad?
            </Link>
            <Link to="/faq" className="blue btn">
              ¿Qué tasa de interés manejan?{" "}
            </Link>
          </div>
        </section>

        <section className="reviews__container">
          <h2>¡Ellos ya confiaron en Percapita! Conoce su opinión</h2>
          <div className="reviews__cards-container">
            <Carousel
              pagination={false}
              breakPoints={breakPoints}
              isRTL={false}
              itemsToShow={3}
            >
              <div className="card">
                <img src={user1} />
                <span className="descripcion_carta">
                  Pude comprar mi título funerario y ahora que lo tengo
                  Percapita me apoyo con una emergencia de salud con mi familia.
                </span>
                <span className="nombre_carta">Maria Guadalupe</span>
                <span className="edad_carta">53 años</span>
              </div>

              <div className="card">
                <img src={user2} />
                <span className="descripcion_carta">
                  Percapita me ayudo para iniciar mi negocio, ahora ya creció y
                  pude sostener a mi familia.
                </span>
                <span className="nombre_carta">Pedro Gaitán</span>
                <span className="edad_carta">62 años</span>
              </div>
              <div className="card">
                <img src={user3} />
                <span className="descripcion_carta">
                  Llegaron los XV años de mi nieta y pudimos completar lo que
                  faltaba para la fiesta gracias a Percapita.
                </span>
                <span className="nombre_carta">María Lourdes del Rosario</span>
                <span className="edad_carta">58 años</span>
              </div>
              <div className="card">
                <img src={user4} />
                <span className="descripcion_carta">
                  Nos fuimos de vacaciones toda la familia, gracias a Percapita
                  pudimos ir y disfrutar de la familia y descansar.
                </span>
                <span className="nombre_carta">Patricia Ruelas</span>
                <span className="edad_carta">56 años</span>
              </div>

              <div className="card">
                <img src={user5} />
                <span className="descripcion_carta">
                  Tener nuestro crédito percapita nos ha ayudado a comprar y
                  amueblar nuestro hogar.
                </span>
                <span className="nombre_carta">Marta Reyes</span>
                <span className="edad_carta">61 años</span>
              </div>

              <div className="card">
                <img src={user6} />
                <span className="descripcion_carta">
                  Con percapita pude cubrir gastos de un accidente familiar y
                  poder cubrir los gastos médicos.
                </span>
                <span className="nombre_carta">Juan Carlos Ruiz</span>
                <span className="edad_carta">66 años</span>
              </div>
            </Carousel>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default MainHome;
