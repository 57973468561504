import { useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ApiClient from "../../../../../services/apiclient";
import { nextStep } from "../../../../../store/actions/PrestamosPersonalesActions";
import { ProgressCircular } from "../../../Progress";
import Menu from "../Menu/Menu";
import "./styles.scss";

const QuestionPeps = ({
  nominaId,
  nextStep,
}) => {

  const apiClient = ApiClient.getInstance();

  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = values => {

    const data = {
      publicOffice: Boolean(values?.publicOffice),
      specifyPublicOffice: values?.specifyPublicOffice,
      conyugeIsPEP: Boolean(values.conyugeIsPEP),
      specifyConcuyeIsPEP: values?.specifyConcuyeIsPEP,
      thirdWillGetBenefits: Boolean(values?.thirdWillGetBenefits),
      specifyThirdWillGetBenefits: values?.specifyThirdWillGetBenefits,
      thirdProvidesResources: Boolean(values?.thirdProvidesResources),
      nameWillProvideResources: values?.nameWillProvideResources,
      titleNumber: "",
      sourcesOfResources: values?.sourcesOfResources,
      destinationOfResources: values?.destinationOfResources,
      natureOfResources: values?.natureOfResources,
      numberCheque: "",
      expirationDateINE: values?.expirationDateINE,
      expirationDateProofAddress: values?.expirationDateProofAddress,
      wayToPay: values?.wayToPay
    };

    setIsLoading(true);

    apiClient.pepbsForm(data, nominaId)
      .then(res => {
        console.log(res)
        nextStep("FINISHED")
      })
      .catch(e => {
        console.log(e)
        setIsLoading(false)
      })
  }

  return (
    <div className="solicitud__container">
      <div className="solicitud-b__container">
        <form onSubmit={handleSubmit(onSubmit)}>

          <Menu step="peps" />

          <div className="solicitud-4__input-group informacionC">


            <div >
              <label htmlFor="publicOffice">
                ¿Desempeña o desempeñó cargo público?
              </label>
              <select
                defaultValue="false"
                {...register("publicOffice", { required: true })}
                className={errors.publicOffice ? "select-peps inputErr" : "select-peps"}
              >
                <option value="">Seleccione una opción</option>
                <option value="true">Si</option>
                <option value="false">No</option>
              </select>
            </div>

            <div >
              <label htmlFor="thirdWillGetBenefits">
                ¿Algún tercero obtendrá los beneficios?
              </label>
              <select
                defaultValue="false"
                {...register("thirdWillGetBenefits", { required: true })}
                className={errors.thirdWillGetBenefits ? "select-peps inputErr" : "select-peps"}

              >
                <option value="">Seleccione una opción</option>
                <option value="true">Si</option>
                <option value="false">No</option>
              </select>
            </div>

            <div >
              <label htmlFor="expirationDateINE">
                Fecha de vencimiento INE
              </label>
              <input
                type="date"
                max={"9999-12-31"}
                id="expirationDateINE"
                name="questionsPeps.expirationDateINE"
                {...register("expirationDateINE", { required: true })}
                className={errors.expirationDateINE ? "inputErr" : ""}
              />
            </div>


            <div >
              <label htmlFor="specifyPublicOffice">
                Especificar dependecia, puesto, periodo y funciones
              </label>
              <input
                maxLength={50}
                type="text"
                {...register("specifyPublicOffice")}
              />
            </div>

            <div >
              <label htmlFor="nameWillProvideResources">
                Especificar el nombre del propietario real
              </label>
              <input
                maxLength={50}
                type="text"
                id="nameWillProvideResources"
                name="questionsPeps.nameWillProvideResources"
                {...register("specifyThirdWillGetBenefits")}
              />
            </div>

            <div >
              <label htmlFor="sourcesOfResources">
                Origen de los recursos
              </label>

              <select
                defaultValue="INGRESOS PROPIOS DE SU ACTIVIDAD"
                {...register("sourcesOfResources", { required: true })}
                className={errors.sourcesOfResources ? "select-peps inputErr" : "select-peps"}
              >
                <option value="">Seleccione una opción</option>
                <option value="INGRESOS PROPIOS DE SU ACTIVIDAD">INGRESOS PROPIOS DE SU ACTIVIDAD</option>
                <option value="GASTO FAMILIAR">GASTO FAMILIAR</option>
                <option value="OTROS">OTROS</option>
                <option value="PENSION">PENSION</option>
              </select>
            </div>

            <div >
              <label htmlFor="expirationDateProofAddress">
                Vencimiento comprobante de domicilio
              </label>
              <input
                type="date"
                max={"9999-12-31"}
                {...register("expirationDateProofAddress", { required: true })}
                className={errors.expirationDateProofAddress ? "inputErr" : ""}
              />
            </div>



            <div >
              <label htmlFor="conyugeIsPEP">
                ¿Cónyuge o pariente a segundo grado es PEP?
              </label>
              <select
                defaultValue="false"
                {...register("conyugeIsPEP", { required: true })}
                className={errors.conyugeIsPEP ? "select-peps inputErr" : "select-peps"}
              >
                <option value="">Seleccione una opción</option>
                <option value="true">Si</option>
                <option value="false">No</option>
              </select>
            </div>
            <div >
              <label htmlFor="thirdProvidesResources">
                ¿Algún tercero aportará recursos para cumplimiento?
              </label>

              <select
                defaultValue="false"
                {...register("thirdProvidesResources", { required: true })}
                className={errors.thirdProvidesResources ? "select-peps inputErr" : "select-peps"}
              >
                <option value="">Seleccione una opción</option>
                <option value="true">Si</option>
                <option value="false">No</option>
              </select>
            </div>

            <div >
              <label htmlFor="destinationOfResources">
                Destino de los recursos
              </label>

              <select
                defaultValue="PAGO DE DEUDA"
                {...register("destinationOfResources", { required: true })}
                className={errors.destinationOfResources ? "select-peps inputErr" : "select-peps"}
              >
                <option value="">Seleccione una opción</option>
                <option value="PAGO DE DEUDA">PAGO DE DEUDA</option>
                <option value="COMPRA DE ARTICULOS PERSONALES">COMPRA DE ARTICULOS PERSONALES</option>
                <option value="COMPRA DE BIENES DE CONSUMO DURARERO">COMPRA DE BIENES DE CONSUMO DURARERO</option>
                <option value="COMPRA DE INMUEBLES">COMPRA DE INMUEBLES</option>
                <option value="CAPITAL DE TRABAJO">CAPITAL DE TRABAJO</option>
              </select>

            </div>

            <div >
              <label htmlFor="wayToPay">Forma de pago</label>

              <select
                defaultValue="EFECTIVO"
                {...register("wayToPay", { required: true })}
                className={errors.wayToPay ? "select-peps inputErr" : "select-peps"}
              >
                <option value="">Seleccione una opción</option>
                <option value="EFECTIVO">EFECTIVO</option>
                <option value="CHEQUE">CHEQUE</option>
                <option value="SPEI">SPEI</option>
                <option value="VENTANILLA BANCARIA (DAP/DIE)">VENTANILLA BANCARIA (DAP/DIE)</option>
              </select>
            </div>


            <div >
              <label htmlFor="specifyConcuyeIsPEP">
                Especificar dependecia, puesto, periodo y funciones
              </label>
              <input
                maxLength={50}
                type="text"
                id="specifyConcuyeIsPEP"
                name="questionsPeps.specifyConcuyeIsPEP"
                {...register("specifyConcuyeIsPEP")}
              />
            </div>

            <div >
              <label htmlFor="nameWillProvideResources">
                Especificar el nombre del propietario real
              </label>
              <input
                maxLength={50}
                type="text"
                id="nameWillProvideResources"
                name="questionsPeps.nameWillProvideResources"
                {...register("nameWillProvideResources")}
              />
            </div>


            <div >
              <label htmlFor="natureOfResources">
                Naturaleza de los recursos
              </label>

              <select
                defaultValue="CREDITO SIMPLE"
                {...register("natureOfResources", { required: true })}
                className={errors.natureOfResources ? "select-peps inputErr" : "select-peps"}
              >
                <option value="">Seleccione una opción</option>
                <option value="CREDITO SIMPLE">CREDITO SIMPLE</option>
              </select>

            </div>

          </div>
          <div className="btnSubmitWrapReferences">
            <button
              className="btn blue"
              type="submit"
              disabled={isLoading}
            >
              <ProgressCircular loading={isLoading} title='Siguiente' />
            </button>
          </div>
        </form>

      </div>
    </div>
  );
}

const mapStateToProps = ({ personalCreditReducer }) => ({
  nominaId: personalCreditReducer?.nominaId ?? "",
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    nextStep,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionPeps);
