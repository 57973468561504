const actividadesEconomicas = [
    'CONSTRUCCION DE INMUEBLES',
    'MENORES CONSTRUCCION DE VIVIENDA RESIDENCIAL',
    'COMPRAVENTA DE ALIMENTOS, BEBIDAS Y PRODUCTOS DE TABACO',
    'COMPRAVENTA DE SEMILLAS Y GRANOS',
    'COMPRAVENTA DE FRUTAS',
    'COMPRAVENTA DE LECHE',
    'COMPRAVENTA DE OTROS PRODUCTOS LACTEOS',
    'COMPRAVENTA DE PRENDAS DE VESTIR Y OTROS ARTÍCULOS DE USO PERSONAL',
    'COMPRAVENTA DE ARTICULOS DE LENCERIA',
    'COMPRAVENTA DE ROPA',
    'COMPRAVENTA DE CALZADO',
    'COMPRAVENTA DE ARTICULOS DE MERCERIA Y CEDERIA',
    'COMPRAVENTA DE TELAS',
    'COMPRAVENTA DE ARTICULOS DE OPTICA',
    'COMPRAVENTA DE JUGUETES',
    'COMPRAVENTA DE PERFUMES',
    'COMPRAVENTA DE PAPELERIA Y ARTICULOS DE ESCRITORIO',
    'COMPRAVENTA DE MUEBLES',
    'COMPRAVENTA DE ARTICULOS PARA REGALO',
    'COMPRAVENTA DE FLORES Y ADORNOS FLORALES NATURALES',
    'COMPRAVENTA DE OTROS ARTICULOS PARA EL HOGAR',
    'TIENDA DE AUTOSERVICIO',
    'COMPRAVENTA DE OTRAS MATERIAS PRIMAS VEGETALES',
    'COMPRAVENTA DE PINTURAS BARNICES Y BROCHAS',
    'COMUNICACIONES',
    'SOCIEDADES FINANCIERAS DE OBJETO MULTIPLE NO REGULADAS',
    'EMPRESAS DE SEGURIDAD PRIVADA',
    'PREPARACIÓN Y SERVICIO DE ALIMENTOS Y BEBIDAS',
    'CAFETERIA',
    'RESTAURANTE',
    'TALLER DE REPARACION GENERAL DE AUTOMOVILES Y CAMIONES',
    'TALLER DE REPARACION DE CALZADO',
    'TALLER DE REPARACION DE ROPA Y MEDIAS',
    'SALON DE BELLEZA',
    'LAVANDERIA',
    'TINTORERIA Y PLANCHADURIA',
    'EMPRESA DE SERVICIO DE LIMPIEZA',
    'AGENCIA DE INHUMACIONES',
    'PANTEON',
    'QUEHACERES DEL HOGAR',
    'SERVICIOS DE ENSEÑANZA PRERRIMARIA Y PRIMARIA',
    'SERVICIOS DE ENSEÑANZA SECUNDARIA',
    'HOSPITALES  SANATORIOS  CLINICAS Y MATERNIDADES',
    'EMPLEADO DEL SECTOR PRIVADO ',
    'EMPLEADO DEL SECTOR PUBLICO',
    'JUBILADO',
    'PENSIONADO',
    'AMA DE CASA',
    'OTRO '
]

export default actividadesEconomicas;