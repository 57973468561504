import * as Yup from "yup";

const PHONE_NUMBER_REGX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
const STRINGEXP=/[A-Za-z ]/;
export interface PersonalData {
  curp: string;
  rfc: string;
  nationality: string;
  studiesLevel: string;
  countryOfBirth: string;
  stateOfBirth: string;
  propertyType: string;
  economicDependents: string;
  antiquity: number;
  antiquityMonth: number;
  identificationNumber: string;
}

export interface InformationData {
  curp: string;
  rfc: string;
  nationality: string;
  studiesLevel: string;
  countryOfBirth: string;
  stateOfBirth: string;
  propertyType: string;
  economicDependents: string;
  identificationNumber: string;
}

export interface AddressData {
  zip: string;
  street: string;
  exteriorNumber: string;
  interiorNumber?: string;
  delegation: string;
  state: string;
  city: string;
  suburb: string;
  propertyType: string;
}

export interface PersonalReference {
  name: string;
  relationship: string;
  contactNumber: string;
}

export interface LaborData {
  companyName: string;
  salary: string;
  companyRole: string;
  antiquity: number;
  antiquityMonth: number;
  economicalActivity: string;
  companyPhone: string;
  personalReferences: PersonalReference[];
}

export interface LaborData1 {
  companyName: string;
  salary: string;
  companyRole: string;
  antiquity: number;
  antiquityMonth: number;
  economicalActivity: string;
  companyPhone: string;
  employeeNumber: string;
}

export const FORM_SECTION_2 = {
  initialValues: {
    curp: "",
    rfc: "",
    nationality: "",
    educationLevel: "",
    countryOfBirth: "",
    stateOfBirth: "",
    economicDependents: "",
    identificationNumber: "",
    employeeNumber: "",
    civilStatus: "",
  },
  validationSchema: Yup.object({
    // curp: Yup.string().required('Curp es requerido')
    //   .trim()
    //   .matches(
    //     /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
    //     "La curp es inválida."
    //   ),
    rfc: Yup.string().required('RFC es requerido').matches(
      // /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$/,
      /^[a-zA-Z]{3,4}(\d{6})((\D|\d){3})?$/,
      "El RFC es inválido."
    ),
    nationality: Yup.string().required("Tienes que ingresar tu nacionalidad."),
    educationLevel: Yup.string().required(
      "Tienes que ingresar tu nivel de estudios."
    ),
    countryOfBirth: Yup.string().required(
      "Tienes que ingresar tu pais de nacimiento."
    ),
    stateOfBirth: Yup.string().required(
      "Tienes que ingresar tu estado de nacimiento."
    ),
    // economicDependents: Yup.string().required(
    //   "Tienes que ingresar tus dependientes económicos."
    // ),
    // IDType: Yup.string().required(
    //   "Tienes que ingresar tipo de identificacion."
    // ),
    // identificationNumber: Yup.string()
    //   .required("Tienes que ingresar el numero de identificación")
    //   .matches(/^[0-9]{13}$/, "Ingresa un numero de identificación valido"),
  }),
  onSubmit: (values) => {
    console.log(values);
  },
};

export const FORM_SECTION_3 = {
  initialValues: {
    postalCode: "",
    street: "",
    interiorNumber: "‏‏‎ ‎",
    exteriorNumber: "",
    // delegation: "",
    state: "",
    city: "",
    suburb: "",
    antiquity: 0,
    antiquityMonth: 0,
    propertyType: "",
    country: "Mexico",
    homePhone:""
  },
  validationSchema: Yup.object({
    postalCode: Yup.string()
      .matches(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)
      .required(),
    street: Yup.string().required("Tienes que ingresar la calle."),
    exteriorNumber: Yup.string().required(),
    interiorNumber: Yup.string(),
    suburb: Yup.string().required(),
    propertyType: Yup.string().required(),
    homePhone: Yup.string().required("Telefono de casa es requerido"),
  }),
  onSubmit: (values) => {
    console.log(values);
  },
};

export const FORM_SECTION_4 = {
  initialValues: {
    companyName: "",
    salary: "",
    companyRole: "",
    antiquity: 0,
    antiquityMonth: 0,
    economicalActivity: "",
    companyPhone: "",
    personalReferences: [
      {
        name: "",
        relationship: "",
        contactNumber: "",
      },
      {
        name: "",
        relationship: "",
        contactNumber: "",
      },
    ],
  },
  validationSchema: Yup.object({
    companyName: Yup.string().required(),
    salary: Yup.string().required(),
    companyRole: Yup.string().required(),
    antiquity: Yup.string().required(),
    economicalActivity: Yup.string().required(),
    companyPhone: Yup.string().required().matches(PHONE_NUMBER_REGX),
    personalReferences: Yup.array().of(
      Yup.object({
        name: Yup.string().required(),
        relationship: Yup.string().required(),
        contactNumber: Yup.string().required().matches(PHONE_NUMBER_REGX),
      })
    ),
  }),
  onSubmit: (values) => {
    console.log(values);
  },
};

export const FORM_SECTION_5 = {
  initialValues: {
    companyName: "",
    salary: 0,
    jobPosition: "",
    antiquity: 0,
    antiquityMonth: 0,
    economicActivity: "",
    phone: "",
    immediateBoss: ""
  },
  validationSchema: Yup.object({
    companyName: Yup.string().required().matches(STRINGEXP),
    salary: Yup.string().notRequired(),
    jobPosition: Yup.string().required(),
    antiquity: Yup.string().notRequired(),
    economicActivity: Yup.string().notRequired(),
    phone: Yup.string().notRequired().matches(PHONE_NUMBER_REGX),
    immediateBoss: Yup.string().required(),
  }),
  onSubmit: (values) => {
    console.log(values);
  },
};
