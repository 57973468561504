import React, { useEffect, useState } from 'react'
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import { useHistory, useLocation } from 'react-router-dom';
import HeaderDashboard from '../../headerDashboard.component'
import ApiClient from '../../../services/apiclient';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const Veriff = () => {
    const apiClient = ApiClient.getInstance();
    const query = useQuery();
    const history = useHistory();

    const [finished, setFinished] = useState(false);

    const createNewVeriffSession = async () => {
      try {
        const session = await apiClient.createVeriffSession('ID_CARD');
        console.log('new session', session);
        
        return session;
      } catch (error) {
        return null;
      }
    }

    const checkUserVeriffValidated = async () => {
        const from = query.get('from');
        
        const isValidatedRes = await apiClient.checkUserVeriffValidated();
        if(isValidatedRes.veriffValidated === true) {
            history.push(from ? '/'+from : '/productos');
            return;
        }

        // try {
        //   const activeSession = await apiClient.getActiveVeriffSession();
        //   openVeriffFrame(activeSession.sessionURL);
        // } catch (error) {
        //   if(error.name === 'VeriffSessionNotFound') {
        //     const session = await apiClient.createVeriffSession('ID_CARD');         
            
            
        //   }
        // }

        apiClient.getActiveVeriffSession()
          .then(activeSession => {
            console.log('activeSession', activeSession);
            openVeriffFrame(activeSession.sessionURL);
          })
          .catch(async error => {
            if(error.name === 'VeriffSessionNotFound') {             
              const session = await createNewVeriffSession();
              if(session) openVeriffFrame(session.sessionURL);
            }
          })

    };

    const openVeriffFrame = async (sessionURL: string) => {

      try {
        const veriffFrame = createVeriffFrame({
          url: sessionURL,
          onEvent: msg => {
            if(msg === MESSAGES.CANCELED){
              console.log('Veriff canceled');
            }
            else if (msg === MESSAGES.FINISHED) {
              setFinished(true);
            }
          }
        });
      } catch (error) {
        console.log(error);
      }

    }

    useEffect(() => {
      checkUserVeriffValidated();
    }, []);
    

  return (
    <div>
        <HeaderDashboard />

        <div className="container">
        <div className="header">
          <h2>
            {" "}
            <b>Necesitamos comprobar tu identidad</b>{" "}
          </h2>
        </div>
        <div style={{textAlign: 'center'}} className="header__text">
          {
            finished ? (
              <>
                <p>
                  Muchas gracias! Sus datos se estan verificando. Por favor espere unos minutos y compruebe sus resultados. 
                </p>
                <button onClick={() => checkUserVeriffValidated()} className='blue center' >Comprobar</button>
              </>
          )
          : (
            <>
                 <p>
                  A continuación se te solicitara que verifiques tu identidad.
                </p>
                <button onClick={() => checkUserVeriffValidated()} className='blue center' >Volver a intentarlo</button>
              </>
          )
        }
          

        </div>

        {/* Navbar */}
      </div>
    </div>
  )
}
