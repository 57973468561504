import React, {useEffect, useState} from 'react';
import icons from '../../assets/icons.png';
import Header from '../header.component';
import AlertMessage from "../alert.component";
import useLocalStorage from "../../hooks/local-storage";
import {useHistory, useParams} from "react-router-dom";
import ApiClient from '../../services/apiclient';


//function RequestForm2() {
const ForgotPassword = (props: any) => {

  const apiClient = ApiClient.getInstance();


  const history = useHistory();
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState({
    password:"",
  })
  const [token, setToken] = useState('')

    let params = useParams<any>();

    useEffect(() => {
        console.log(params.token);
        if(params.token){
            console.log('validate token');
            setToken(params.token)
        }

    }, [])

  const resetPassword = (e: {preventDefault: () => void}) => {
    e.preventDefault();

    console.log(confirmPassword,"confirm");
    console.log(token);


    apiClient.resetPasswordChange(confirmPassword,token).then(res => {
      setOpenMessage(true);
      setMessage('El password se ha actualizado');

       setTimeout( () => {
          history.push('/login');
      }, 3000); 

    })
    .catch(error => {
      setOpenMessage(true);
      setMessage('Ocurrio un error al enviar la petición');
      setSeverity('error');
    });
    
    
    };

  const handleClose = () => {
    setOpenMessage(false);
  }

  const formInvalid = () => {
      return ((password.length === 0) || confirmPassword.password.length === 0 || password !== confirmPassword.password)
  }

  return (
    <div>
      <Header />
      <AlertMessage
          open={openMessage}
          message={message}
          handleClose={handleClose}
          severity={severity}
      />
      <div className="solicitud__container">
        <div className="solicitud-2__content">

          <h2>Recupera tu contraseña</h2>



            <form action="" onSubmit={resetPassword}>


                <div className="solicitud-4__input-group justify-start">
                    <div>
                        <h4>Contraseña</h4>
                        <input
                            required
                            type="password"
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                        />
                    </div>
                </div>


                <div className="solicitud-4__input-group justify-start">
                    <div>
                        <h4>Confirmar Contraseña</h4>
                        <input
                            required
                            name="password"
                            type="password"
                            onChange={(e) => {
                                setConfirmPassword({password:e.target.value});
                            }}
                        />
                    </div>
                </div>


                <button disabled={formInvalid()} className="blue">Enviar</button>

            </form>



        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
