import Header from "../../header.component";
import "./nav.css";
import {
  Link,
  useHistory,
  useParams,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { ValidacionPabs } from "./validacion/validacion";
import Informacion from "./informacion/informacion";
import Domicilio from "./informacion/domicilio/domicilio";
import DatosLaborales from "./informacion/datos_laborales/datos";
import Referencias from "./informacion/referencias/referencias";
import Perfil from "./informacion/perfil/perfil";
import Aceptado from "./validacion/aceptado/aceptado";
import Documentos from "./informacion/documentos/documentos";

import HeaderDashboard from "../../headerDashboard.component";

import React, { useEffect, useState } from "react";
import ApiClient from "../../../services/apiclient";

export default function Nav() {
  const [loanAmount, setLoanAmount] = useState({
    upper: 0,
    lower: 0,
  });

  const [productName, setProductName] = useState({
    product: "",
  });
  const apiClient = ApiClient.getInstance();

  const checkActiveCredit = async () => {
    const activeCredit = await apiClient.getActiveCredit();
    return activeCredit;
  };

  useEffect(() => {
    //const creditID = checkActiveCredit();
    //if (creditID) history.push("/productos");
    apiClient
      .PabsValid()
      .then((res) => {
        console.log(res);
        console.log(res.titleNumber);
        if (res.hasOwnProperty("validated") === false) {
          localStorage.setItem("upper", res.upper);
          localStorage.setItem("lower", res.lower);
          userData1.questionsPeps.titleNumber = res.titleNumber;
          setPabsNumber(res.titleNumber);
          setUserData1({ ...userData1 });
          nextStep();
        } else {
          if (res.validated === true && res.to === "questionPeps") {
            userData1.questionsPeps.titleNumber = res.titleNumber;
            setPabsNumber(res.titleNumber);
            setUserData1({ ...userData1 });
            setCurrentPage(5);
          }
          if (res.validated === true && res.to === "documents") {
            setPabsNumber(res.titleNumber);
            userData1.questionsPeps.titleNumber = res.titleNumber;
            setUserData1({ ...userData1 });
            setCurrentPage(7);
          }
        }
      })
      .catch((err) => {
        localStorage.clear();
        history.push("/");
      });
  }, []);

  const [pabsNumber, setPabsNumber] = useState("");

  const [userData, setUserData] = useState({
    user: {
      nationality: "",
      countryOfBirth: "",
      stateOfBirth: "",
      curp: "",
      rfc: "",
    },
    amount: 0,
    personalData: {
      IDType: "",
      identificationNumber: "",
      civilStatus: "",
      educationLevel: "",
      economicDependents: 0,
    },
    address: {
      street: "",
      interiorNumber: "",
      exteriorNumber: "",
      suburb: "",
      postalCode: "",
      city: "",
      state: "",
      country: "",
      homePhone: "",
      antiquity: null,
      antiquityMonth: null,
      propertyType: "",
    },
    laborData: {
      companyName: "",
      salary: 0,
      jobPosition: "",
      antiquity: null,
      antiquityMonth: null,
      economicActivity: "",
      phone: "",
    },
  });

  const [userData1, setUserData1] = useState({
    personalReferences: [
      {
        name: "",
        relationship: "",
        contactNumber: "",
      },
    ],
    questionsPeps: {
      publicOffice: false,
      specifyPublicOffice: "",
      conyugeIsPEP: false,
      specifyConcuyeIsPEP: "",
      thirdWillGetBenefits: false,
      specifyThirdWillGetBenefits: "",
      thirdProvidesResources: false,
      nameWillProvideResources: "",
      sourcesOfResources: "INGRESOS PROPIOS DE SU ACTIVIDAD",
      destinationOfResources: "PAGO DE DEUDA",
      natureOfResources: "CREDITO SIMPLE",
      titleNumber: "",
      numberCheque: "",
      expirationDateINE: "",
      expirationDateProofAddress: "",
      wayToPay: "EFECTIVO",
    },
  });

  const history = useHistory();
  const [finishStatus, setfinishStatus] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);

  const nextStep = () => setCurrentPage((prev) => prev + 1);
  const prevStep = () => setCurrentPage((prev) => prev - 1);

  const formPages: any = [
    <ValidacionPabs
      setLoanAmount={setLoanAmount}
      setPabsNumber={setPabsNumber}
      nextStep={nextStep}
    ></ValidacionPabs>,
    <Aceptado
      productName={productName}
      setProductName={setProductName}
      nextStep={nextStep}
      loanAmount={loanAmount}
      userData={userData}
      setUserData={setUserData}
    ></Aceptado>,
    <Informacion
      userData={userData}
      setUserData={setUserData}
      nextStep={nextStep}
      prevStep={prevStep}
    ></Informacion>,
    <Domicilio
      userData={userData}
      setUserData={setUserData}
      nextStep={nextStep}
      prevStep={prevStep}
    ></Domicilio>,
    <DatosLaborales
      productName={productName}
      userData={userData}
      setUserData={setUserData}
      nextStep={nextStep}
      setCurrentPage={setCurrentPage}
      prevStep={prevStep}
    ></DatosLaborales>,
    <Referencias
      productName={productName}
      userData={userData}
      setUserData={setUserData}
      userData1={userData1}
      setUserData1={setUserData1}
      nextStep={nextStep}
      setCurrentPage={setCurrentPage}
      prevStep={prevStep}
    ></Referencias>,
    <Perfil
      productName={productName}
      setProductName={setProductName}
      userData={userData}
      userData1={userData1}
      setUserData1={setUserData1}
      pabsNumber={pabsNumber}
      nextStep={nextStep}
      prevStep={prevStep}
    ></Perfil>,

    <Documentos
      productName={productName}
      setProductName={setProductName}
      userData1={userData1}
      prevStep={prevStep}
    />,
  ];

  return (
    <div>
      <HeaderDashboard />

      <div className="container">
        <div className="header">
          <h3>
            {" "}
            <b>¡Solicita tu préstamo por crédito personal con garantía! </b>{" "}
          </h3>
        </div>
        <div className="header__text">
          <p>
            Te prestamos desde $2,000 hasta $10,000 pesos, en tan solo cinco
            sencillos pasos. <br /> Comencemos por la información de tu título
            PABS
          </p>

          <h1></h1>
        </div>

        {/* Navbar */}
      </div>

      <div>
        <div className="cliente_container">{formPages[currentPage]}</div>
      </div>
    </div>
  );
}
