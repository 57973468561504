import { useFormik } from "formik";
import { useEffect, useState } from "react";
import ApiClient from "../../../../../services/apiclient";
import { FORM_SECTION_3 } from "../forms";
import Error from "../../../../../../assets/error.png";
import GreenChecked from "../../../../../assets/green-cheked.png";
import Warning from "../../../../../assets/warning.png";
import AlertMessage from "../../../alertNomina.component";
import { ProgressCircular } from "../../Progress";
import * as Yup from "yup";

export default function Direccion({
  setAddress,
  loading,
  callback: sendData,
  addressData,
}) {
  const formSection3 = useFormik({
    initialValues: {
      postalCode: "",
      street: "",
      interiorNumber: "‏‏‎ ‎",
      exteriorNumber: "",
      // delegation: "",
      state: "",
      city: "",
      suburb: "",
      antiquity: 0,
      antiquityMonth: 0,
      propertyType: "",
      country: "Mexico",
      homePhone: "",
    },
    validationSchema: Yup.object({
      postalCode: Yup.string()
        .matches(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)
        .required(),
      street: Yup.string().required("Tienes que ingresar la calle."),
      exteriorNumber: Yup.string().required(),
      interiorNumber: Yup.string(),
      suburb: Yup.string().required("Tienes que seleccionar una colonia"),
      //propertyType: Yup.string().required(),
      homePhone: Yup.string().required("Telefono de casa es requerido"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });
  useEffect(() => {
    apiClient.getUserPreInfo().then((data) => {
      if (!data.error) {
        formSection3.setFieldValue("street", data.street);
        formSection3.setFieldValue("exteriorNumber", data.exteriorNumber);
        formSection3.setFieldValue("interiorNumber", data.interiorNumber);
        formSection3.setFieldValue("postalCode", data.postalCode);
        formSection3.setFieldValue("antiquity", data.antiquity);
        formSection3.setFieldValue("antiquityMonth", data.antiquityMonth);
        formSection3.setFieldValue("propertyType", data.propertyType);
        if (data.postalCode) {
          apiClient
            .getAdrressFromZipCode(data.postalCode)
            .then((data) => {
              formSection3.setFieldValue("state", data.estado);
              formSection3.setFieldValue("city", data.ciudad || data.municipio);
              // formSection3.setFieldValue('delegation', data.municipio);
              //formSection3.setFieldValue('postalCode', formSection3.values.postalCode);
              setColonias(data.colonias);
              console.log(formSection3);
            })
            .catch((e) => {
              console.error(e);
            });
        }
      }
    });
  }, []);

  console.log("formSection3.values: ", formSection3.values);
  const apiClient = ApiClient.getInstance();
  const [colonias, setColonias] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [error, setError] = useState(false);
  const [incomplete, setIncomplete] = useState(true);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const collapseSection = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setCollapsed(!collapsed);
  };

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  const handleZipBlur = (event: React.FocusEvent) => {
    if (formSection3.errors.postalCode) {
      setMessage("Debes introducir un código postal válido.");
      setSeverity("error");
      setOpenMessage(true);
      return;
    }

    apiClient
      .getAdrressFromZipCode(formSection3.values.postalCode)
      .then((data) => {
        formSection3.setFieldValue("state", data.estado);
        formSection3.setFieldValue("city", data.ciudad || data.municipio);
        // formSection3.setFieldValue('delegation', data.municipio);
        formSection3.setFieldValue(
          "postalCode",
          formSection3.values.postalCode
        );
        setColonias(data.colonias);
        console.log(formSection3);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const submitData = () => {
    console.log("dasdad");
    if (!formSection3.isValid) {
      Object.keys(formSection3.errors).forEach((key) => {
        console.log(formSection3.errors);
        setMessage(formSection3.errors[key] as string);
        setSeverity("error");
        setOpenMessage(true);
      });
      setAddress(undefined);
      setError(true);
      setIncomplete(true);
      return;
    }

    if (Object.values(formSection3.values).every((value) => value === "")) {
      console.log("sdadasd");
      setMessage("Los datos de dirección son obligatorios.");
      setSeverity("error");
      setOpenMessage(true);
      setIncomplete(true);
      setError(false);
      setAddress(undefined);
      return;
    }

    // setError(false);
    // setIncomplete(false);
    console.log("formSection3.values: ", formSection3.values);

    sendData(formSection3.values);
    // setCollapsed(true);
  };
  useEffect(() => {
    console.log(addressData);
    if (formSection3.isValid) {
      sendData(addressData);
    }
  }, [addressData]);

  return (
    <div id="div-domicilio" className="solicitud-b__container">
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />

      <div className="incisio-header">
        <div className="inciso-header-text">
          <h4>
            ¡Ya casi terminamos. Solo necesitamos saber tu dirección y código
            postal.
          </h4>
        </div>
        {/* {error ? (
          <img src={Error} alt="Error icon" />
        ) : !error && incomplete ? (
          <img src={Warning} alt="Warning icon" />
        ) : !error && !incomplete ? (
          <img src={GreenChecked} alt="Success icon" />
        ) : (
          ""
        )} */}
      </div>

      <div id="4" className="solicitud-4__input-group informacionC">
        <div>
          <label htmlFor="street">Calle*</label>
          <input
            className="calleInput"
            tabIndex={17}
            type="text"
            id="street"
            name="street"
            placeholder="Ej."
            onChange={(e) => {
              const value = e.target.value || "";
              formSection3.setFieldValue(
                "street",
                value
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toUpperCase()
              );
            }}
            value={formSection3.values.street}
          />
        </div>

        <div>
          <label htmlFor="exteriorNumber">Numero exterior*</label>
          <input
            tabIndex={17}
            type="text"
            id="exteriorNumber"
            name="exteriorNumber"
            placeholder="Ej."
            onChange={(e) => {
              const value = e.target.value || "";
              formSection3.setFieldValue(
                "exteriorNumber",
                value
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toUpperCase()
              );
            }}
            value={formSection3.values.exteriorNumber}
          />
        </div>

        <div>
          <label htmlFor="interiorNumber">Numero interior</label>
          <input
            tabIndex={17}
            type="text"
            id="interiorNumber"
            name="interiorNumber"
            placeholder="Ej."
            onChange={(e) => {
              const value = e.target.value || "";
              formSection3.setFieldValue(
                "interiorNumber",
                value
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toUpperCase()
              );
            }}
            value={formSection3.values.interiorNumber}
          />
        </div>

        <div>
          <label htmlFor="propertyType">Tipo de propiedad *</label>
          <select
            tabIndex={15}
            className="input-select"
            id="propertyType"
            name="propertyType"
            onChange={formSection3.handleChange}
            value={formSection3.values.propertyType}
          >
            <option value="">Seleccione una opción</option>
            <option value="Propia">Propia</option>
            <option value="Alquiler">Alquiler</option>
            <option value="Desconocido">Desconocido</option>
            <option value="Empresa">Empresa</option>
            <option value="Familiar">Familiar</option>
            <option value="Propietario">Propietario</option>
            <option value="Propiedad hipoteca">Propietario hipoteca</option>
            <option value="Sin domicilio propio">Sin domicilio propio</option>
          </select>
        </div>

        <div>
          <label>Código Postal *</label>
          <input
            tabIndex={16}
            type="text"
            id="postalCode"
            name="postalCode"
            placeholder="Ej."
            onChange={formSection3.handleChange}
            value={formSection3.values.postalCode}
            onBlur={handleZipBlur}
          />
        </div>

        <div>
          <label htmlFor="suburb">Colonia *</label>
          <select
            tabIndex={21}
            className="input-select"
            id="suburb"
            name="suburb"
            onChange={formSection3.handleChange}
            value={formSection3.values.suburb}
          >
            <option value="">Seleccione una opción</option>
            {colonias.map((data) => {
              return (
                <option value={data.colonia.toUpperCase()}>
                  {data.colonia.toUpperCase()}
                </option>
              );
            })}
          </select>
        </div>

        <div>
          <label htmlFor="city">Ciudad *</label>
          <input
            tabIndex={20}
            type="text"
            id="city"
            name="city"
            placeholder="Ej."
            onChange={formSection3.handleChange}
            value={formSection3.values.city.toUpperCase()}
            disabled
          />
        </div>

        <div>
          <label htmlFor="state">Estado *</label>
          <input
            tabIndex={19}
            type="text"
            id="state"
            name="state"
            placeholder="Ej."
            onChange={formSection3.handleChange}
            value={formSection3.values.state.toUpperCase()}
            disabled
          />
        </div>

        <div>
          <label htmlFor="country">País</label>
          <input
            type="text"
            id="country"
            name="country"
            onChange={formSection3.handleChange}
            value={formSection3.values.country}
            disabled
          ></input>
        </div>
        <div>
          <label htmlFor="homePhone">Teléfono casa</label>
          <input
            type="text"
            id="homePhone"
            name="homePhone"
            onChange={formSection3.handleChange}
            value={formSection3.values.homePhone}
          />
        </div>
        <div>
          <label htmlFor="antiquity">Tiempo en el domicilio</label>
          <div className="domicilioTime">
            <input
              className="domicilioTimeI"
              tabIndex={14}
              type="number"
              id="antiquity"
              name="antiquity"
              onChange={formSection3.handleChange}
              value={formSection3.values.antiquity}
              placeholder="Años"
            />
            <input
              className="domicilioTimeI"
              tabIndex={14}
              type="number"
              id="antiquityMonth"
              name="antiquityMonth"
              onChange={formSection3.handleChange}
              value={formSection3.values.antiquityMonth}
              placeholder="Meses"
            />
          </div>
        </div>

        {/* <div>
            <label htmlFor="delegation">Delegación</label >
            <input
              tabIndex={18}
              type="text"
              id="delegation"
              name="delegation"
              placeholder="Ej."
              onChange={formSection3.handleChange}
              value={formSection3.values.delegation.toUpperCase()}
            />
          </div> */}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button className="blue" onClick={submitData} disabled={loading}>
          <ProgressCircular loading={loading} title="Continuar" />
        </button>
      </div>
    </div>
  );
}
