import Login from "../components/login.component";
import LoginNomina from "../components/nominaForm/loginNomina.component";

import AccountActived from "../components/pages/account.actived.component";
import AccountActivedNomina from "../components/nominaForm/pages/account.actived.nomina.component";
import AvisoPrivacidad from "../components/pages/avisoPrivacidad";
import AvisoPrivacidadNomina from "../components/nominaForm/pages/avisoPrivacidad";
import TerminosCondicionesView from "../components/pages/terminosCondicionesView";
import TerminosCondicionesViewNomina from "../components/nominaForm/pages/terminosCondicionesView";
import EDF from "../components/pages/edf.component";
import EDFNomina from "../components/nominaForm/pages/edf.component";
import FAQ from "../components/pages/faq.component";
import FAQNomina from "../components/nominaForm/pages/faq.component";
import ForgotPassword from "../components/pages/forgot-password";
import ForgotPasswordNomina from "../components/nominaForm/pages/forgot-password";
import PAS from "../components/pages/pas.component";
import PASNomina from "../components/nominaForm/pages/pas.component";
import RequestForgotPassword from "../components/pages/request-forgot-password";
import RequestForgotPasswordNomina from "../components/nominaForm/pages/request-forgot-password";
import Page1 from "../components/pages/request-form-1.component";
import Page1Nomina from "../components/nominaForm/pages/request-form-1.nomina.component";
import Page1Solarum from "../components/nominaForm/pages/request-form-1.solarum.component";
import Page1Celular from "../components/nominaForm/pages/request-form-1.celular.component";
import Page2 from "../components/pages/request-form-2.component";
import Page2Nomina from "../components/nominaForm/pages/request-form-2.component";
import TermsConditions from "../components/pages/termsConditions";
import TermsConditionsNomina from "../components/nominaForm/pages/termsConditions";
import RegisterForm from "../components/pages/register-form/register-form";
import RegisterFormNomina from "../components/nominaForm/pages/register-form/register-form-nomina";
import RequestForgotPasswordCode from "../components/pages/forgot-password-entercode";
import RequestForgotPasswordCodeNomina from "../components/nominaForm/pages/forgot-password-entercode";
import DerechosArco from "../components/pages/derechosArco";
import DerechosArcoNomina from "../components/nominaForm/pages/derechosArco";


const rutasPublicas = [
  { path: "/login", component: Login },
  { path: "/step1", component: Page1 },
  { path: "/step2", component: Page2 },
  { path: "/account-active/:token", component: AccountActived },
  { path: "/request-forgot-password", component: RequestForgotPassword },
  { path: "/forgot-password/:token", component: ForgotPassword },
  { path: "/faq", component: FAQ },
  { path: "/educacion-financiera", component: EDF },
  { path: "/pasos-a-seguir", component: PAS },
  { path: "/terminos-y-condiciones", component: TermsConditions },
  { path: "/aviso-de-privacidad", component: AvisoPrivacidad },
  { path: "/contrato-licencia", component: TerminosCondicionesView },
  { path: "/register-form", component: RegisterForm },
  {path:"/derechos-arco", component:DerechosArco},
  {
    path: "/request-forgot-password-code",
    component: RequestForgotPasswordCode,
  },
{ path: "/login-nomina", component: LoginNomina },
{ path: "/step1-nomina", component: Page1Nomina },
{ path: "/step1-solarum", component: Page1Solarum },
{ path: "/step1-celular", component: Page1Celular },
{ path: "/step2-nomina", component: Page2Nomina },
{ path: "/account-active-nomina/:token", component: AccountActivedNomina },
{ path: "/request-forgot-password-nomina", component: RequestForgotPasswordNomina },
{ path: "/forgot-password-nomina/:token", component: ForgotPasswordNomina },
{ path: "/faq-nomina", component: FAQNomina },
{ path: "/educacion-financiera-nomina", component: EDFNomina },
{ path: "/pasos-a-seguir-nomina", component: PASNomina },
{ path: "/terminos-y-condiciones-nomina", component: TermsConditionsNomina },
{ path: "/aviso-de-privacidad-nomina", component: AvisoPrivacidadNomina },
{ path: "/contrato-licencia-nomina", component: TerminosCondicionesViewNomina },
{ path: "/register-form-nomina", component: RegisterFormNomina },
{ path: "/derechos-arco-nomina", component:DerechosArcoNomina},
{
  path: "/request-forgot-password-code-nomina",
  component: RequestForgotPasswordCodeNomina,
},
];

export default rutasPublicas;

