import { useState } from "react";
import ApiClient from "../../../../../services/apiclient";
import AlertMessage from "../../../alertNomina.component";
import "./Referencia.css";
import EmpleoForm from "./labordata-form";

export default function Referencia({ nextStep }) {
  const [loading, setLoading] = useState(false);
  const [employeeNumber, setEmployeeNumber] = useState(null);
  const apiClient = ApiClient.getInstance();
  const [openMessage, setOpenMessage] = useState(false);
  const [laborData, setLaborData] = useState();

  const registerNominaLoan = () => {
    apiClient
      .updateReference(laborData)
      .then((res) => {
        console.log("next");
        nextStep();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <AlertMessage open={openMessage} />
      <div className="solicitud__container">
        <div className="solicitud-b__container">
          <EmpleoForm
            setLabor={setLaborData}
            laborData={laborData}
            employeeNumber={employeeNumber}
            loading={loading}
            callback={registerNominaLoan}
          />
        </div>
      </div>
    </div>
  );
}
