import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import AlertMessage from '../alertNomina.component';
import HeaderNomina from '../header.Nomina.component';
import FormRegister from "../form.register.nomina.component";
import { UserContext } from '../../../context/UserProvider';

function RequestForm1(props: any) {
  const history = useHistory();
  const {userMain} = React.useContext(UserContext);
  const [paso, setPaso] = useState('');
  
/**
 * 
 * La implemento esta validadicion de la siguiente manera ya que con el "const {userMain, updateUser} = React.useContext(UserContext);" tenia valores por default antes de cargar la pagina 
 * y hacia un refresh la pgaina y cargaba la siguiente vista
 */


useEffect(() => {
  console.log("props",props)
    var nombre = '';
    var step = '';
    if(localStorage.getItem('userLocal')){
      var userLocal = JSON.parse(localStorage.getItem('userLocal'));
      nombre = userLocal.nombre;
      setPaso(userLocal.creditoNomina);
    }else{
      nombre = 'Iniciar Sesión'
    }
    console.log('user: ', nombre);
    if (nombre != 'Iniciar Sesión') {
      return history.push('/');
    } 
  }, []);
  const data = {
    nom1 : "¡Solicita tu prestamo!",
    nom2: "Te prestamos desde $2,000 hasta $10,000 pesos, en tan solo dos sencillos pasos:",
    cel1: "¡Adquiere tu préstamo para celular!",
    cel2: "Te financiamos desde $2,000 hasta $7,000 pesos, en tan solo dos sencillos pasos:",
    sol1: "¡Adquiere ya tu Tequila Solarum!",
    sol2: "Te financiamos de 1 hasta 2 botellas, en tan solo dos sencillos pasos:",  
  }
 

  return (
    <div>
      <HeaderNomina />
      <div className="solicitud__container">
        <div className="solicitud-1__form-solicitud">
          <h1>{paso === "nomina" ?  data.nom1 : paso === "celular" ? data.cel1 : paso === "tequila" ? data.sol1 : data.nom1}</h1>
          <span>
          {paso === "nomina" ?  data.nom2 : (paso === "celular" ? data.cel2 : data.sol2)}
          </span>
          <div className="solicitud-1__tabs">
          <Link to="#" className="active">
              1. Regístrate
            </Link>
            <Link to="#">2. Llena tu información personal</Link>
          </div>
          <FormRegister type="step-register" typeLogin = {paso} />
        </div>
      </div>
    </div>
  );
}

export default RequestForm1;
