import React, { useEffect, useState } from "react";

import AlertMessage from "../../../../alert.component";
import "../informacion.css";
import ApiClient from "../../../../../services/apiclient";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

export default function Referencias({
  userData,
  setUserData1,
  nextStep,
  userData1,
  setCurrentPage,
  setUserData,
  productName
  ,prevStep
}) {
  const history = useHistory();
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const apiClient = ApiClient.getInstance();
  const onsubmitForm = (values: any, actions: any) => {
    setUserData1(values);
    nextStep();
  };

  useEffect(() => {
    console.log("UserData primera parte", userData);
    /*apiClient.setProductName(productName).then(data => {
      console.log(data);
    }).catch(err => console.error(err));*/
  }, []);

  const validationSchema = Yup.object().shape({

    personalReferences: Yup.array().of(


      Yup.object({
        name: Yup.string().required("Requerido"),
        relationship: Yup.string().required("Requerido"),
        contactNumber: Yup.string().required("Requerido"),

      })


    )

  });

  const handleClose = () => {
    setOpenMessage(false);
  };
  return (
    <div>
      <AlertMessage 
      open={openMessage}
      message={message}
      handleClose={handleClose}
      severity={severity}
      />
      <div className="solicitud__container">
        <Formik
          initialValues={userData1}
          onSubmit={onsubmitForm}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, errors, touched }) => (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="solicitud-b__container">
                <div className="navbar3">
                  <a className="hg12">1. Validación</a>
                  <a className="hg22">2. Información personal</a>
                  <a className="hg32">3. Referencias</a>
                  <a className="hg42">4. Perfil</a>
                  <a className="hg52 ">5. Titulo PABS</a>
                </div>
                <p>
                  {" "}
                  <b>Primera referencia personal</b>{" "}
                </p>

                <div className="solicitud-4__input-group informacionC">
                  <div>
                    <label htmlFor="personalReferences1">Nombre completo</label>
                    <Field
                      type="text"
                      id="personalReferences1"
                      name="personalReferences.0.name"
                    />
                    <ErrorMessage name={`personalReferences.0.name`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="personalReferences">Parentesco</label>
                    <Field
                      type="text"
                      as="select"
                      id="personalReferences"
                      name="personalReferences.0.relationship"
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="Familiar">Familiar</option>
                      <option value="Amistad">Amistad</option>
                    </Field>
                    <ErrorMessage name={`personalReferences.0.relationship`}>
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="personalReferences">
                      Telefono de contacto
                    </label>
                    <Field
                      type="text"
                      id="personalReferences"
                      name="personalReferences.0.contactNumber"
                    />
                    <ErrorMessage name={`personalReferences.0.contactNumber`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>
                </div>
                <p>
                  {" "}
                  <b>Segunda referencia personal</b>{" "}
                </p>

                <div className="solicitud-4__input-group informacionC">
                  <div>
                    <label htmlFor="personalReferences1">Nombre completo</label>
                    <Field
                      type="text"
                      id="personalReferences1"
                      name="personalReferences.1.name"
                    />
                    <ErrorMessage name={`personalReferences.1.name`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>
                  </div>

                  <div>
                    <label htmlFor="personalReferences">Parentesco</label>
                    <Field
                      type="text"
                      id="personalReferences"
                      name="personalReferences.1.relationship"
                      as="select"
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="Familiar">Familiar</option>
                      <option value="Amistad">Amistad</option>
                    </Field>
                    <ErrorMessage name={`personalReferences.1.relationship`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>

                  </div>

                  <div>
                    <label htmlFor="personalReferences">
                      Telefono de contacto
                    </label>
                    <Field
                      type="text"
                      id="personalReferences"
                      name="personalReferences.1.contactNumber"
                    />
                    <ErrorMessage name={`personalReferences.1.contactNumber`} >
                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                    </ErrorMessage>

                  </div>

                </div>
                
                <div className="Button-cpr">
                  <button type="submit" className="btn blue">
                    Continuar
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
