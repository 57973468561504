import { CircularProgress, Fade } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { animateScroll as scroll, scroller } from "react-scroll";
import Desk from "../../../assets/desk.png";
import Error from "../../../assets/error.png";
import File from "../../../assets/file.png";
import GreenCheked from "../../../assets/green-cheked.png";
import Home from "../../../assets/home.png";
import LeftArrow from "../../../assets/left-arrow.png";
import Paper from "../../../assets/paper.png";
import upload from "../../../assets/upload.png";
import Warning from "../../../assets/warning.png";
import Work from "../../../assets/work.png";
import { DomicilioContext } from "../../../context/DomicilioProvider";
import { TramiteCreditoContext } from "../../../context/TramiteCreditoProvider";
import { UserContext } from "../../../context/UserProvider";
import AlertMessage from "../alertNomina.component";
import HeaderDashboard from "../headerDashboardNomina.component";

function RequestForm4() {
  const history = useHistory();
  const [fechaMax, setFechaMax] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const { credito, updateCredito } = React.useContext(TramiteCreditoContext);

  const { userMain, updateUser } = React.useContext(UserContext);

  const { domicilioMain, updateDomicilio } = React.useContext(DomicilioContext);

  const [colonias, setColonias] = useState([]);

  const [userLoan, setUserLoan] = useState({
    nombre: userMain.nombre === "Iniciar Sesión" ? "" : userMain.nombre,
    apellidoPaterno:
      userMain.nombre === "Iniciar Sesión" ? "" : userMain.apellidoPaterno,
    apellidoMaterno:
      userMain.nombre === "Iniciar Sesión" ? "" : userMain.apellidoMaterno,
    telefono: "",
    nombreImgSelfie: "",
    archivoIdentificacion: "",
    nombreIdentificacion: "",
    archivoComprobante: "",
    nombreComprobante: "",
    archivoCuenta: "",
    nombreCuenta: "",
    rfc: "",
    curp: "",
    genero: "",
    nacionalidad: "",
    fechaNacimiento: "",
    pais: "",
    estado_nacimiento: "",
    nivel_estudio: "",
    dependientes: "",
    anyo_domicilio: "",
    tipo_propiedad: "",
    montoPrestamo: 0,
    numeroPagos: 0,
    formaPago: 0,
  });

  /**
   *
   * La implemento esta validadicion de la siguiente manera ya que con el "const {userMain, updateUser} = React.useContext(UserContext);" tenia valores por default antes de cargar la pagina
   * y no dejaba entrar
   */

  /*  useEffect(() => {
     var nombre = '';
     if (localStorage.getItem('userLocal')) {
       var userLocal = JSON.parse(localStorage.getItem('userLocal'));
       nombre = userLocal.nombre;
 
       var d = new Date(),
         month = '' + (d.getMonth() + 1),
         day = '' + (d.getDate()),
         year = d.getFullYear() - 20;
 
       if (month.length < 2)
         month = '0' + month;
       if (day.length < 2)
         day = '0' + day;
       var format: string = [year, month, day].join('-');
       setFechaMax(format);
     } else {
       nombre = 'Iniciar Sesión'
     }
 
     var creditoLocal = '';
     if (localStorage.getItem('creditoLocal')) {
       var userLocal = JSON.parse(localStorage.getItem('creditoLocal'));
       creditoLocal = userLocal.estatusSolicitud;
     }
     if (nombre == 'Iniciar Sesión' && localStorage.getItem('email')) {
       return history.push('/step2');
     } else if (nombre == 'Iniciar Sesión' && !localStorage.getItem('email')) {
       return history.push('/step1');
     } else if (creditoLocal == 'revision' || creditoLocal == 'enviar-solicitud') {
       return history.push('/');
     }
   }, []); */

  const [domicilio, setDomicilio] = useState({
    antiguedad_domicilio: 0,
    tipo_propiedad: "",
    calle: "",
    delegacion: "",
    colonia: "",
    cp: "",
    ciudad: "",
    estado: "",
  });

  const [datoEmpleo, setDatoEmpleo] = useState({
    nombre_compania: "",
    sueldo: null,
    puesto: "",
    antiguedad: null,
    actividad_economica: "",
    telefono_oficina: "",
    nombre_referencia1: "",
    parentesco: "",
    telefono_contacto: "",
    nombre_referencia2: "",
    parentesco2: "",
    telefono_contacto2: "",
  });

  //setUserLoan({...userLoan, nombre: userMain.nombre});
  const [fileSelfie, setFileSelfie] = useState<string | null>(null);
  const [fileIdentificacion, setFileIdentificacion] = useState<string | null>(
    null
  );
  const [fileComprobante, setFileComprobante] = useState<string | null>(null);
  const [fileCuenta, setFileCuenta] = useState<string | null>(null);

  const [shownIdentidad, setShownIdentidad] = React.useState(false);
  const [identidadCompleta, setIdentidadCompleta] = React.useState(false);
  const [identificacionVacia, setidentificacionVacia] = React.useState(false);

  const [shownPerfil, setShownPerfil] = React.useState(false);
  const [perfilCompleta, setPerfilCompleta] = React.useState(false);
  const [perfilVacio, setPerfilVacio] = React.useState(false);

  const [shownDireccion, setShownDireccion] = React.useState(false);
  const [direccionCompleta, setDireccionCompleta] = React.useState(false);
  const [direccionVacia, setDireccionVacia] = React.useState(false);

  const [shownPrestamo, setShownPrestamo] = React.useState(false);
  const [prestamoCompleta, setPrestamoCompleta] = React.useState(false);
  const [prestamoVacio, setPrestamoVacio] = React.useState(false);

  const [shown, setShown] = React.useState(false);
  const switchShown = () => setShown(!shown);

  const [loading, setLoading] = React.useState(false);

  const [paises, setPaises] = useState([]);
  const [estados, setEstados] = useState([]);
  const [economicActivities, setEconomicActivities] = useState([]);

  useEffect(() => {
    geteconomicActivities();
    obtenerPais();
    obtenerEstados();
  }, []);

  const geteconomicActivities = function () {
    //obtener economicActivities
    fetch(process.env.REACT_APP_URL_API + "/economic-activities", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
        } else {
          setEconomicActivities(data);
        }
      })
      // .catch((error) => console.log(error));
  };

  var obtenerPais = function () {
    //obtener pais
    fetch(process.env.REACT_APP_URL_API + "/country-controller", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
        } else {
          setPaises(data);
        }
      })
      .catch((error) => console.log(error));
  };

  var obtenerEstados = function () {
    fetch(process.env.REACT_APP_URL_API + "/state-controller", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
        } else {
          setEstados(data);
        }
      })
      .catch((error) => console.log(error));
  };

  function expandirIdentidad(e: { preventDefault: () => void }) {
    e.preventDefault();
    setShownIdentidad(!shownIdentidad);

    verificarIdentidad();
  }

  function getAge(fechaNacimiento: string) {
    var fechaNace = new Date(fechaNacimiento);
    var fechaActual = new Date();

    var edad = fechaActual.getFullYear() - fechaNace.getFullYear();
    var m = fechaActual.getMonth() - fechaNace.getMonth();

    if (m < 0 || (m === 0 && fechaActual.getDate() < fechaNace.getDate())) {
      edad--;
    }

    return edad;
  }

  var validarSeccionUno = function () {
    if (userLoan.nombreImgSelfie === "") {
      setOpenMessage(true);
      setMessage("Foto Reciente (Selfie) es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.nombreIdentificacion === "") {
      setOpenMessage(true);
      setMessage("Foto INE es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }
  };

  var verificarIdentidad = function () {
    // validamos datos de seccion para la seccion de colapsable manual, despues del llenado de informacion
    if (
      userLoan.nombreImgSelfie === "" &&
      userLoan.nombreIdentificacion === ""
    ) {
      setidentificacionVacia(true);
      setIdentidadCompleta(false);
    } else if (
      userLoan.nombreImgSelfie === "" ||
      userLoan.nombreIdentificacion === ""
    ) {
      setidentificacionVacia(false);
      setIdentidadCompleta(false);
    } else {
      setidentificacionVacia(false);
      setIdentidadCompleta(true);
    }
  };

  function expandirPerfil(e: { preventDefault: () => void }) {
    e.preventDefault();
    setShownPerfil(!shownPerfil);

    validacionPerfil();
  }

  var validarSeccionDos = function () {
    //validamos datos requeridos
    if (userLoan.curp === "") {
      setOpenMessage(true);
      setMessage("La CURP es requerida. Favor de verificar.");
      setSeverity("error");
      return;
    }

    var re =
        /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
      validado = userLoan.curp.match(re);
    if (!validado) {
      setOpenMessage(true);
      setMessage(
        "La CURP consta de 18 caracteres de código alfanumérico único de identidad. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (userLoan.curp.length <= 17 || userLoan.curp.length >= 19) {
      setOpenMessage(true);
      setMessage(
        "La CURP consta de 18 caracteres de código alfanumérico único de identidad. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (userLoan.rfc === "") {
      setOpenMessage(true);
      setMessage("RFC es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    var re =
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    var validado = userLoan.rfc.match(re);
    if (!validado) {
      setOpenMessage(true);
      setMessage(
        "El RFC consta de 13 caracteres de código alfanumérico para persona Fisica. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (userLoan.rfc.length <= 12 || userLoan.rfc.length >= 14) {
      setOpenMessage(true);
      setMessage(
        "El RFC consta de 13 caracteres de código alfanumérico para persona Fisica. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (userLoan.telefono === "") {
      setOpenMessage(true);
      setMessage("Número de teléfono es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    var valoresAceptados = /^[0-9]+$/;

    if (!userLoan.telefono.match(valoresAceptados)) {
      setOpenMessage(true);
      setMessage(
        "Número de teléfono solo permite valor numerico del 0-9. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (userLoan.telefono.length <= 9 || userLoan.telefono.length >= 11) {
      setOpenMessage(true);
      setMessage(
        "Número de teléfono consta de 10 digitos del 0-9. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (userLoan.nombre === "") {
      setOpenMessage(true);
      setMessage("Nombre es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.apellidoPaterno === "") {
      setOpenMessage(true);
      setMessage("Apellido paterno es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.apellidoMaterno === "") {
      setOpenMessage(true);
      setMessage("Apellido materno es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.genero === "") {
      setOpenMessage(true);
      setMessage("El genero es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.nacionalidad === "") {
      setOpenMessage(true);
      setMessage("La nacionalidad es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.fechaNacimiento === "") {
      setOpenMessage(true);
      setMessage("La fecha de nacimiento es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    //dar formato fecha nacimiento
    var d = new Date(userLoan.fechaNacimiento),
      month = "" + (d.getMonth() + 1),
      day = "" + (d.getDate() + 1),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var formatoFecha: string = [year, month, day].join("-");

    var edad: number = getAge(formatoFecha);

    if (edad <= 21) {
      setOpenMessage(true);
      setMessage(
        "Debe ser mayor a 21 años de edad para realizar proceso de solicitud. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (userLoan.pais === "") {
      setOpenMessage(true);
      setMessage("El país de nacimiento es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }
    if (userLoan.estado_nacimiento === "") {
      setOpenMessage(true);
      setMessage("El estado de nacimiento es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }
    if (userLoan.nivel_estudio === "") {
      setOpenMessage(true);
      setMessage("El nivel de estudio es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }
    if (userLoan.dependientes === "") {
      setOpenMessage(true);
      setMessage(
        "Los datos de dependientes econimicos es requerido. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (userLoan.anyo_domicilio === "") {
      setOpenMessage(true);
      setMessage(
        "Años de vivir en el domicilio es requerido. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (!userLoan.anyo_domicilio.match(valoresAceptados)) {
      setOpenMessage(true);
      setMessage(
        "Años de vivir solo permite valores numericos(0-9). Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (userLoan.tipo_propiedad === "") {
      setOpenMessage(true);
      setMessage("Tipo de propiedad es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.nombreComprobante === "") {
      setOpenMessage(true);
      setMessage("Comprobante de domicilio es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.nombreCuenta === "") {
      setOpenMessage(true);
      setMessage("Estado de cuenta es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }
  };

  var validacionPerfil = function () {
    console.log(userLoan);
    if (
      userLoan.curp === "" &&
      userLoan.rfc === "" &&
      userLoan.telefono === "" &&
      userLoan.nombre === "" &&
      userLoan.apellidoPaterno === "" &&
      userLoan.apellidoMaterno === "" &&
      userLoan.nombreComprobante === "" &&
      userLoan.nombreCuenta === "" &&
      userLoan.genero === "" &&
      userLoan.nacionalidad === "" &&
      userLoan.fechaNacimiento === "" &&
      userLoan.pais === "" &&
      userLoan.estado_nacimiento === "" &&
      userLoan.nivel_estudio === "" &&
      userLoan.dependientes === "" &&
      userLoan.anyo_domicilio === "" &&
      userLoan.tipo_propiedad === ""
    ) {
      setPerfilVacio(true);
      setPerfilCompleta(false);
    } else if (
      userLoan.curp === "" ||
      userLoan.rfc === "" ||
      userLoan.telefono === "" ||
      userLoan.nombre === "" ||
      userLoan.apellidoPaterno === "" ||
      userLoan.apellidoMaterno === "" ||
      userLoan.nombreComprobante === "" ||
      userLoan.nombreCuenta === "" ||
      userLoan.genero === "" ||
      userLoan.nacionalidad === "" ||
      userLoan.fechaNacimiento === "" ||
      userLoan.pais === "" ||
      userLoan.estado_nacimiento === "" ||
      userLoan.nivel_estudio === "" ||
      userLoan.dependientes === "" ||
      userLoan.anyo_domicilio === "" ||
      userLoan.tipo_propiedad === ""
    ) {
      setPerfilVacio(false);
      setPerfilCompleta(false);
    } else {
      setPerfilVacio(false);
      setPerfilCompleta(true);
    }
  };

  function expandirDireccion(e: { preventDefault: () => void }) {
    e.preventDefault();
    setShownDireccion(!shownDireccion);
    validarDireccion();
  }

  var validarSeccionTres = function () {
    var valoresAceptados = /^[0-9]+$/;

    if (domicilio.cp === "") {
      setOpenMessage(true);
      setMessage("Código postal es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (!domicilio.cp.match(valoresAceptados)) {
      setOpenMessage(true);
      setMessage("El Código postal acepta valor numérico. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (domicilio.cp.length <= 4 || domicilio.cp.length >= 6) {
      setOpenMessage(true);
      setMessage(
        "El Código postal consta de 5 digitos (0-9). Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (domicilio.calle === "") {
      setOpenMessage(true);
      setMessage(
        "Dirección (calle y número) es requerido. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (domicilio.delegacion === "") {
      setOpenMessage(true);
      setMessage("La delegacion es requerida. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (domicilio.estado === "") {
      setOpenMessage(true);
      setMessage("Estado es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (domicilio.ciudad === "") {
      setOpenMessage(true);
      setMessage("Ciudad es requerida. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (domicilio.colonia === "") {
      setOpenMessage(true);
      setMessage("Colonia es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }
  };

  var validarDireccion = function () {
    if (
      domicilio.calle === "" &&
      domicilio.cp === "" &&
      domicilio.delegacion === "" &&
      domicilio.estado === "" &&
      domicilio.ciudad === "" &&
      domicilio.colonia === ""
    ) {
      setDireccionVacia(true);
      setDireccionCompleta(false);
    } else if (
      domicilio.calle === "" ||
      domicilio.cp === "" ||
      domicilio.delegacion === "" ||
      domicilio.estado === "" ||
      domicilio.ciudad === "" ||
      domicilio.colonia === ""
    ) {
      setDireccionVacia(false);
      setDireccionCompleta(false);
    } else {
      setDireccionVacia(false);
      setDireccionCompleta(true);
    }
  };

  /** var verificarPrestamo = setInterval(function () {
    var validar: boolean = validarPrestamo();
    if (validar && !openClientPrestamo) {
      clearInterval(verificarPrestamo);
      setShownPrestamo(true);
      setPrestamoCompleta(true);
    }

  }, 3000);**/

  function expandirPrestamo(e: { preventDefault: () => void }) {
    e.preventDefault();
    setShownPrestamo(!shownPrestamo);
    validarDato();
  }

  var validarSeccionCuatro = function () {
    var valoresAceptados = /^[0-9]+$/;

    if (datoEmpleo.nombre_compania === "") {
      setOpenMessage(true);
      setMessage("Nombre de compañia es requerida. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (datoEmpleo.sueldo === 0) {
      setOpenMessage(true);
      setMessage("El sualedo es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (!String(datoEmpleo.sueldo).match(valoresAceptados)) {
      setOpenMessage(true);
      setMessage(
        "El sueldo solo permite valores numericos(0-9). Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (datoEmpleo.puesto === "") {
      setOpenMessage(true);
      setMessage("El puesto es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (datoEmpleo.antiguedad === 0) {
      setOpenMessage(true);
      setMessage("Antigüedad Años es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (!String(datoEmpleo.antiguedad).match(valoresAceptados)) {
      setOpenMessage(true);
      setMessage(
        "Antigüedad Años solo permite valores numericos(0-9). Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (datoEmpleo.actividad_economica === "") {
      setOpenMessage(true);
      setMessage("Actividad econimica es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (datoEmpleo.telefono_oficina === "") {
      setOpenMessage(true);
      setMessage("Teléfono de oficina es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (!datoEmpleo.telefono_oficina.match(valoresAceptados)) {
      setOpenMessage(true);
      setMessage(
        "Número de teléfono de oficina solo permite valor numerico del 0-9. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (
      datoEmpleo.telefono_oficina.length <= 9 ||
      datoEmpleo.telefono_oficina.length >= 11
    ) {
      setOpenMessage(true);
      setMessage(
        "Número de teléfono de oficina consta de 10 digitos del 0-9. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (datoEmpleo.nombre_referencia1 === "") {
      setOpenMessage(true);
      setMessage("Nombre de referencia es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (datoEmpleo.parentesco === "") {
      setOpenMessage(true);
      setMessage("Parentesco de referencia es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (datoEmpleo.telefono_contacto === "") {
      setOpenMessage(true);
      setMessage(
        "Teléfono de contacto de referencia es requerido. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (!datoEmpleo.telefono_contacto.match(valoresAceptados)) {
      setOpenMessage(true);
      setMessage(
        "Número de teléfono de contacto de referencia 1 solo permite valor numerico del 0-9. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (
      datoEmpleo.telefono_contacto.length <= 9 ||
      datoEmpleo.telefono_contacto.length >= 11
    ) {
      setOpenMessage(true);
      setMessage(
        "Número de teléfono de contacto de referencia 1 consta de 10 digitos del 0-9. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (datoEmpleo.nombre_referencia2 === "") {
      setOpenMessage(true);
      setMessage("Nombre de referencia es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (datoEmpleo.parentesco2 === "") {
      setOpenMessage(true);
      setMessage("Parentesco de referencia es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (datoEmpleo.telefono_contacto2 === "") {
      setOpenMessage(true);
      setMessage(
        "Teléfono de contacto de referencia es requerido. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (!datoEmpleo.telefono_contacto2.match(valoresAceptados)) {
      setOpenMessage(true);
      setMessage(
        "Número de teléfono de contacto de referencia 2 solo permite valor numerico del 0-9. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (
      datoEmpleo.telefono_contacto2.length <= 9 ||
      datoEmpleo.telefono_contacto2.length >= 11
    ) {
      setOpenMessage(true);
      setMessage(
        "Número de teléfono de contacto de referencia 2 consta de 10 digitos del 0-9. Favor de verificar."
      );
      setSeverity("error");
      return;
    }
  };
  var validarDato = function () {
    if (
      datoEmpleo.nombre_compania === "" &&
      datoEmpleo.sueldo === 0 &&
      datoEmpleo.puesto === "" &&
      datoEmpleo.antiguedad === 0 &&
      datoEmpleo.actividad_economica === "" &&
      datoEmpleo.telefono_oficina === "" &&
      datoEmpleo.nombre_referencia1 === "" &&
      datoEmpleo.parentesco === "" &&
      datoEmpleo.telefono_contacto === "" &&
      datoEmpleo.nombre_referencia2 === "" &&
      datoEmpleo.parentesco2 === "" &&
      datoEmpleo.telefono_contacto2 === ""
    ) {
      setPrestamoVacio(true);
      setPrestamoCompleta(false);
    } else if (
      datoEmpleo.nombre_compania === "" ||
      datoEmpleo.sueldo === 0 ||
      datoEmpleo.puesto === "" ||
      datoEmpleo.antiguedad === 0 ||
      datoEmpleo.actividad_economica === "" ||
      datoEmpleo.telefono_oficina === "" ||
      datoEmpleo.nombre_referencia1 === "" ||
      datoEmpleo.parentesco === "" ||
      datoEmpleo.telefono_contacto === "" ||
      datoEmpleo.nombre_referencia2 === "" ||
      datoEmpleo.parentesco2 === "" ||
      datoEmpleo.telefono_contacto2 === ""
    ) {
      setPrestamoVacio(false);
      setPrestamoCompleta(false);
    } else {
      setPrestamoVacio(false);
      setPrestamoCompleta(true);
    }
  };

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    setUserLoan({
      ...userLoan,
      [e.target.name]: e.target.value.toUpperCase(),
    });

    setDomicilio({
      ...domicilio,
      [e.target.name]: e.target.value.toUpperCase(),
    });

    setDatoEmpleo({
      ...datoEmpleo,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const handleInputChangePattern = (e: {
    target: { name: any; value: any; validity: any };
  }) => {
    const esValido = e.target.validity.valid;
    if (esValido == true) {
      setUserLoan({
        ...userLoan,
        [e.target.name]: e.target.value.toUpperCase(),
      });

      setDomicilio({
        ...domicilio,
        [e.target.name]: e.target.value.toUpperCase(),
      });

      setDatoEmpleo({
        ...datoEmpleo,
        [e.target.name]: e.target.value.toUpperCase(),
      });
    }
  };

  const registerUserLoan = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    let userId = userMain.userId;
    let formatDate = new Date().toISOString();
    let monto = Number(userLoan.montoPrestamo);
    let plazo = Number(userLoan.numeroPagos);
    let forma = Number("0"); // por defual pago mensual, Mensual = 1, Quincenal = 2, Semanal = 3

    //validamos datos requeridos
    if (userLoan.curp === "") {
      setOpenMessage(true);
      setMessage("La CURP es requerida. Favor de verificar.");
      setSeverity("error");
      return;
    }

    var re =
        /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
      validado = userLoan.curp.match(re);
    if (!validado) {
      setOpenMessage(true);
      setMessage(
        "La CURP consta de 18 caracteres de código alfanumérico único de identidad. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (userLoan.curp.length <= 17 || userLoan.curp.length >= 19) {
      setOpenMessage(true);
      setMessage(
        "La CURP consta de 18 caracteres de código alfanumérico único de identidad. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (userLoan.rfc === "") {
      setOpenMessage(true);
      setMessage("RFC es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    var re =
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    var validado = userLoan.rfc.match(re);
    if (!validado) {
      setOpenMessage(true);
      setMessage(
        "El RFC consta de 13 caracteres de código alfanumérico para persona Fisica. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (userLoan.rfc.length <= 12 || userLoan.rfc.length >= 14) {
      setOpenMessage(true);
      setMessage(
        "El RFC consta de 13 caracteres de código alfanumérico para persona Fisica. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (userLoan.telefono === "") {
      setOpenMessage(true);
      setMessage("Número de teléfono es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    var valoresAceptados = /^[0-9]+$/;

    if (!userLoan.telefono.match(valoresAceptados)) {
      setOpenMessage(true);
      setMessage(
        "Número de teléfono solo permite valor numerico del 0-9. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (userLoan.telefono.length <= 9 || userLoan.telefono.length >= 11) {
      setOpenMessage(true);
      setMessage(
        "Número de teléfono consta de 10 digitos del 0-9. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (userLoan.nombre === "") {
      setOpenMessage(true);
      setMessage("Nombre es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.apellidoPaterno === "") {
      setOpenMessage(true);
      setMessage("Apellido paterno es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.apellidoMaterno === "") {
      setOpenMessage(true);
      setMessage("Apellido materno es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.genero === "") {
      setOpenMessage(true);
      setMessage("El genero es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.nacionalidad === "") {
      setOpenMessage(true);
      setMessage("La nacionalidad es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.fechaNacimiento === "") {
      setOpenMessage(true);
      setMessage("La fecha de nacimiento es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    //dar formato fecha nacimiento
    var d = new Date(userLoan.fechaNacimiento),
      month = "" + (d.getMonth() + 1),
      day = "" + (d.getDate() + 1),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var formatoFecha: string = [year, month, day].join("-");

    var edad: number = getAge(formatoFecha);

    if (edad <= 21) {
      setOpenMessage(true);
      setMessage(
        "Debe ser mayor a 21 años de edad para realizar proceso de solicitud. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (userLoan.pais === "") {
      setOpenMessage(true);
      setMessage("El país de nacimiento es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }
    if (userLoan.estado_nacimiento === "") {
      setOpenMessage(true);
      setMessage("El estado de nacimiento es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }
    if (userLoan.nivel_estudio === "") {
      setOpenMessage(true);
      setMessage("El nivel de estudio es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }
    if (userLoan.dependientes === "") {
      setOpenMessage(true);
      setMessage(
        "Los datos de dependientes econimicos es requerido. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (userLoan.anyo_domicilio === "") {
      setOpenMessage(true);
      setMessage(
        "Años de vivir en el domicilio es requerido. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (!userLoan.anyo_domicilio.match(valoresAceptados)) {
      setOpenMessage(true);
      setMessage(
        "Años de vivir solo permite valores numericos(0-9). Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (userLoan.tipo_propiedad === "") {
      setOpenMessage(true);
      setMessage("Tipo de propiedad es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.nombreImgSelfie === "") {
      setOpenMessage(true);
      setMessage("Foto Reciente (Selfie) es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.nombreIdentificacion === "") {
      setOpenMessage(true);
      setMessage("Foto INE es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.nombreComprobante === "") {
      setOpenMessage(true);
      setMessage("Comprobante de domicilio es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.nombreCuenta === "") {
      setOpenMessage(true);
      setMessage("Estado de cuenta es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (domicilio.cp === "") {
      setOpenMessage(true);
      setMessage("Código postal es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (!domicilio.cp.match(valoresAceptados)) {
      setOpenMessage(true);
      setMessage("El Código postal acepta valor numérico. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (domicilio.cp.length <= 4 || domicilio.cp.length >= 6) {
      setOpenMessage(true);
      setMessage(
        "El Código postal consta de 5 digitos (0-9). Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (domicilio.calle === "") {
      setOpenMessage(true);
      setMessage(
        "Dirección (calle y número) es requerido. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (domicilio.delegacion === "") {
      setOpenMessage(true);
      setMessage("La delegacion es requerida. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (domicilio.estado === "") {
      setOpenMessage(true);
      setMessage("Estado es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (domicilio.ciudad === "") {
      setOpenMessage(true);
      setMessage("Ciudad es requerida. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (domicilio.colonia === "") {
      setOpenMessage(true);
      setMessage("Colonia es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }
    if (datoEmpleo.nombre_compania === "") {
      setOpenMessage(true);
      setMessage("Nombre de compañia es requerida. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (datoEmpleo.sueldo === 0) {
      setOpenMessage(true);
      setMessage("El sualedo es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (!String(datoEmpleo.sueldo).match(valoresAceptados)) {
      setOpenMessage(true);
      setMessage(
        "El sueldo solo permite valores numericos(0-9). Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (datoEmpleo.puesto === "") {
      setOpenMessage(true);
      setMessage("El puesto es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (datoEmpleo.antiguedad === 0) {
      setOpenMessage(true);
      setMessage("Antigüedad Años es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (!String(datoEmpleo.antiguedad).match(valoresAceptados)) {
      setOpenMessage(true);
      setMessage(
        "Antigüedad Años solo permite valores numericos(0-9). Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (datoEmpleo.actividad_economica === "") {
      setOpenMessage(true);
      setMessage("Actividad econimica es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (datoEmpleo.telefono_oficina === "") {
      setOpenMessage(true);
      setMessage("Teléfono de oficina es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (!datoEmpleo.telefono_oficina.match(valoresAceptados)) {
      setOpenMessage(true);
      setMessage(
        "Número de teléfono de oficina solo permite valor numerico del 0-9. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (
      datoEmpleo.telefono_oficina.length <= 9 ||
      datoEmpleo.telefono_oficina.length >= 11
    ) {
      setOpenMessage(true);
      setMessage(
        "Número de teléfono de oficina consta de 10 digitos del 0-9. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (datoEmpleo.nombre_referencia1 === "") {
      setOpenMessage(true);
      setMessage("Nombre de referencia es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (datoEmpleo.parentesco === "") {
      setOpenMessage(true);
      setMessage("Parentesco de referencia es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (datoEmpleo.telefono_contacto === "") {
      setOpenMessage(true);
      setMessage(
        "Teléfono de contacto de referencia es requerido. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (!datoEmpleo.telefono_contacto.match(valoresAceptados)) {
      setOpenMessage(true);
      setMessage(
        "Número de teléfono de contacto de referencia 1 solo permite valor numerico del 0-9. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (
      datoEmpleo.telefono_contacto.length <= 9 ||
      datoEmpleo.telefono_contacto.length >= 11
    ) {
      setOpenMessage(true);
      setMessage(
        "Número de teléfono de contacto de referencia 1 consta de 10 digitos del 0-9. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (datoEmpleo.nombre_referencia2 === "") {
      setOpenMessage(true);
      setMessage("Nombre de referencia es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (datoEmpleo.parentesco2 === "") {
      setOpenMessage(true);
      setMessage("Parentesco de referencia es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (datoEmpleo.telefono_contacto2 === "") {
      setOpenMessage(true);
      setMessage(
        "Teléfono de contacto de referencia es requerido. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (!datoEmpleo.telefono_contacto2.match(valoresAceptados)) {
      setOpenMessage(true);
      setMessage(
        "Número de teléfono de contacto de referencia 2 solo permite valor numerico del 0-9. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    if (
      datoEmpleo.telefono_contacto2.length <= 9 ||
      datoEmpleo.telefono_contacto2.length >= 11
    ) {
      setOpenMessage(true);
      setMessage(
        "Número de teléfono de contacto de referencia 2 consta de 10 digitos del 0-9. Favor de verificar."
      );
      setSeverity("error");
      return;
    }

    let format_fecha_nacimiento = new Date(
      userLoan.fechaNacimiento
    ).toISOString();

    var referenciaJson = {
      referencias: [
        {
          nombre_completo: datoEmpleo.nombre_referencia1.toUpperCase(),
          parentesco: datoEmpleo.parentesco.toUpperCase(),
          telefono_contacto: datoEmpleo.telefono_contacto,
        },
        {
          nombre_completo: datoEmpleo.nombre_referencia2.toUpperCase(),
          parentesco: datoEmpleo.parentesco2.toUpperCase(),
          telefono_contacto: datoEmpleo.telefono_contacto2,
        },
      ],
    };

    var dataJson = {
      first_name: userLoan.nombre.toUpperCase(),
      last_f_name: userLoan.apellidoPaterno.toUpperCase(),
      last_m_name: userLoan.apellidoMaterno.toUpperCase(),
      rfc: userLoan.rfc.toUpperCase(),
      phone_number: userLoan.telefono,
      loan_request_date: formatDate,
      loan_selfie_file: userLoan.nombreImgSelfie,
      identification_file: userLoan.archivoIdentificacion,
      name_file_identification: userLoan.nombreIdentificacion,
      file_proof_address: userLoan.archivoComprobante,
      file_name_proof_address: userLoan.nombreComprobante,
      statement_file: userLoan.archivoCuenta,
      file_name_statement: userLoan.nombreCuenta,
      curp: userLoan.curp.toUpperCase(),
      loan_amount_requested: monto,
      number_term_payment: plazo,
      form_of_payment: forma,
      postal_code: domicilio.cp,
      state: domicilio.estado.toUpperCase(),
      city: domicilio.ciudad.toUpperCase(),
      delegation: domicilio.delegacion.toUpperCase(),
      colonia: domicilio.colonia.toUpperCase(),
      street: domicilio.calle.toUpperCase(),
      exterior_number: domicilio.calle.toUpperCase(),
      gender: userLoan.genero,
      nationality: userLoan.nacionalidad,
      birth_date: format_fecha_nacimiento,
      country: userLoan.pais,
      state_of_birth: userLoan.estado_nacimiento,
      education_level: userLoan.nivel_estudio,
      dependents: userLoan.dependientes.toUpperCase(),
      year_address: Number(userLoan.anyo_domicilio),
      type_of_property: userLoan.tipo_propiedad,
      company_name: datoEmpleo.nombre_compania.toUpperCase(),
      salary: Number(datoEmpleo.sueldo),
      job_position: datoEmpleo.puesto.toUpperCase(),
      seniority: datoEmpleo.antiguedad,
      economic_activity: datoEmpleo.actividad_economica.toUpperCase(),
      office_phone: datoEmpleo.telefono_oficina,
      personal_reference: referenciaJson,
      isEmployee: false,
    };

    fetch(process.env.REACT_APP_URL_API + "/user-loan/" + userId, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataJson),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setOpenMessage(true);
          setMessage(data.error.message);
          setSeverity("error");
          return history.push("/step4");
        } else {
          updateCredito({
            ...credito,
            idPrestamo: data.id,
            fecha_solicitud: data.loan_request_date,
            isApproved: data.isApproved,
            isDelivered: data.isDelivered,
            estatusSolicitud: "enviar-solicitud",
            limitAmountCredit: 20000, // limite de dinero a prestar
            limitTermCredit: 12, // limite de meses
            tasa: 70,
          });

          updateDomicilio({
            ...domicilioMain,
            codigo_postal: domicilio.cp,
            estado: domicilio.estado,
            ciudad: domicilio.ciudad,
            colonia: domicilio.colonia,
            calle: domicilio.calle,
            numero_exterior: "S/N",
            numero_interior: "S/N",
          });

          updateUser({
            ...userMain,
            curp: userLoan.curp,
            rfc: userLoan.rfc,
            telefono: userLoan.telefono,
            esEmpleado: false,
          });

          setOpenMessage(true);
          setMessage("Registro de solicitud realizado correctamente");
          setSeverity("success");
          // return history.push('/step5');
          return history.push("/credito-config");
        }
      })
      .catch((error) => {
        return history.push("/step4");
      });
  };

  const selfieUploadClick = (event: any) => {
    var file = event.target.files[0];
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    if (file) {
      const data = new FormData();
      data.append("file", file);
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        const valor = reader?.result;
        // @ts-ignore
        setFileSelfie(reader?.result.toString());

        var dataJson = {
          tipo: 1,
          archivo: valor,
          formato: data,
        };

        updateUserLoan(dataJson);
      };
    } else {
      setFileSelfie(null);
    }
  };

  const identificacionUploadClick = (event: any) => {
    var file = event.target.files[0];
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    if (file) {
      const data = new FormData();
      data.append("file", file);
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        const valor = reader?.result;
        // @ts-ignore
        setFileIdentificacion(reader?.result.toString());

        var dataJson = {
          tipo: 2,
          archivo: valor,
          formato: data,
        };

        updateUserLoan(dataJson);
      };
    } else {
      setFileIdentificacion(null);
    }
  };

  const comprobanteUploadClick = (event: any) => {
    var file = event.target.files[0];
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    if (file) {
      const data = new FormData();
      data.append("file", file);
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        const valor = reader?.result;
        // @ts-ignore
        setFileComprobante(reader?.result.toString());

        var dataJson = {
          tipo: 3,
          archivo: valor,
          formato: data,
        };

        updateUserLoan(dataJson);
      };
    } else {
      setFileComprobante(null);
    }
  };

  const handleUploadClick = (event: any) => {
    var file = event.target.files[0];

    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    if (file) {
      const data = new FormData();
      data.append("file", file);
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        const valor = reader?.result;
        // @ts-ignore
        setFileCuenta(reader?.result.toString());

        //colocamos el servicio para guardar imagen en el sevicio
        var dataJson = {
          tipo: 4,
          archivo: valor,
          formato: data,
        };

        updateUserLoan(dataJson);
      };
    } else {
      setFileCuenta(null);
    }
  };

  const handleOpenDialogFile = (idInputFile: string) => {
    // @ts-ignore
    document.getElementById(idInputFile).click();
  };

  const updateUserLoan = (valor: any) => {
    switch (valor.tipo) {
      case 1:
        fetch(process.env.REACT_APP_URL_API + "/selfie-Image", {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: valor.formato,
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              setOpenMessage(true);
              setMessage("El formato o tamaño de archivo no soportado.");
              setSeverity("error");
            } else {
              setUserLoan({ ...userLoan, nombreImgSelfie: data.filename });
            }
          })
          .catch((error) => console.log(error));
        break;
      case 2:
        fetch(process.env.REACT_APP_URL_API + "/identification-user-Image", {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: valor.formato,
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              setOpenMessage(true);
              setMessage("El formato o tamaño de archivo no soportado.");
              setSeverity("error");
            } else {
              setUserLoan({
                ...userLoan,
                nombreIdentificacion: data.filename,
                archivoIdentificacion: "Carga de archivo de identificacion.",
              });
            }
          })
          .catch((error) => console.log(error));
        break;
      case 3:
        fetch(process.env.REACT_APP_URL_API + "/proof-address-image", {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: valor.formato,
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              setOpenMessage(true);
              setMessage("El formato o tamaño de archivo no soportado.");
              setSeverity("error");
            } else {
              setUserLoan({
                ...userLoan,
                nombreComprobante: data.filename,
                archivoComprobante: "Carga comprobante de domicilio.",
              });
            }
          })
          .catch((error) => console.log(error));
        break;
      case 4:
        fetch(process.env.REACT_APP_URL_API + "/account-statement-image", {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: valor.formato,
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              setOpenMessage(true);
              setMessage("El formato o tamaño de archivo no soportado.");
              setSeverity("error");
            } else {
              setUserLoan({
                ...userLoan,
                nombreCuenta: data.filename,
                archivoCuenta: "Carga archivo de estado de cuenta.",
              });
            }
          })
          .catch((error) => console.log(error));
        break;
    }
  };

  const handleClose = (event, reason) => {
    console.log("handleClose: ");
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  const obtenerCodigoPostal = () => {
    setLoading(true);

    var valoresAceptados = /^[0-9]+$/;
    if (!domicilio.cp.match(valoresAceptados)) {
      setOpenMessage(true);
      setMessage("El Código postal acepta valor numérico. Favor de verificar.");
      setSeverity("error");
      setLoading(false);
      return;
    }

    if (domicilio.cp.length <= 4 || domicilio.cp.length >= 6) {
      setOpenMessage(true);
      setMessage(
        "El Código postal consta de 5 digitos (0-9). Favor de verificar."
      );
      setSeverity("error");
      setLoading(false);
      return;
    }

    console.log("ejecutamos servicio: " + domicilio.cp);
    fetch("https://pc.axoloft.com/findcp/" + domicilio.cp, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setColonias([]);
          setDomicilio({
            ...domicilio,
            ciudad: "",
            estado: "",
            delegacion: "",
          });
          setLoading((prevLoading) => !prevLoading);
        } else {
          console.log(data);
          setColonias(data.colonias);
          setDomicilio({
            ...domicilio,
            ciudad: data.ciudad.toUpperCase(),
            estado: data.estado.toUpperCase(),
            delegacion: data.municipio.toUpperCase(),
          });
          setLoading((prevLoading) => !prevLoading);
        }
      })
      .catch((error) => console.log(error));
  };

  const scrollToTop = () => {
    setShownPrestamo(!shownPrestamo);
    validarDato();
    validarSeccionCuatro();
    //setShownDireccion(!shownDireccion);
    //validarDireccion();
    scroll.scrollToTop();
  };

  const scrollTo = (data: string) => {
    if (data === "div-perfil") {
      setShownIdentidad(!shownIdentidad);
      verificarIdentidad();
      validarSeccionUno();
    } else if (data === "div-domicilio") {
      setShownPerfil(!shownPerfil);
      validacionPerfil();
      validarSeccionDos();
    } else if (data === "div-empleo") {
      setShownDireccion(!shownDireccion);
      validarDireccion();
      validarSeccionTres();
    }
    scroller.scrollTo(data, {
      duration: 400,
      delay: 0,
      offset: -300,
      smooth: "easeInOutQuart",
    });
  };

  const onBlurCURP = () => {
    var re =
        /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
      validado = userLoan.curp.match(re);
    if (!validado) {
      setOpenMessage(true);
      setMessage(
        "La CURP consta de 18 caracteres de código alfanumérico único de identidad. Favor de verificar."
      );
      setSeverity("error");
      return;
    }
  };

  const onBlurRFC = () => {
    var re =
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    var validado = userLoan.rfc.match(re);
    if (!validado) {
      setOpenMessage(true);
      setMessage(
        "El RFC consta de 13 caracteres de código alfanumérico para persona Fisica. Favor de verificar."
      );
      setSeverity("error");
      return;
    }
  };

  return (
    <div>
      <HeaderDashboard />
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <div className="solicitud__container">
        <div className="solicitud-b__container">
          <div className="solicitud-b__header">
            <h1>
              ¡Hola, {userMain.nombre}! Bienvenido a percápita. Vamos a iniciar
              con tu solicitud de crédito.
            </h1>
            <span>
              ¡No te preocupes! lo terminaremos en un abrir y cerrar de ojos.
            </span>
          </div>

          <div className="solicitud-b__inciso">
            <div className="incisio-header">
              <div className="inciso-header-text">
                <h4>
                  1.Primero, necesitamos verificar tu identidad, por lo que
                  necesitamos una foto de tu INE y una selfie tuya.
                </h4>
                <span className="toggle-collapsed" onClick={expandirIdentidad}>
                  {shownIdentidad ? "Abrir" : "Cerrar"}
                </span>
              </div>
              {shownIdentidad && identidadCompleta && !identificacionVacia ? (
                <img src={GreenCheked} />
              ) : shownIdentidad &&
                identificacionVacia &&
                !identidadCompleta ? (
                <img src={Error} />
              ) : shownIdentidad &&
                !identificacionVacia &&
                !identidadCompleta ? (
                <img src={Warning} />
              ) : (
                ""
              )}
            </div>
            <div
              id="1"
              className={
                shownIdentidad
                  ? "solicitud-b__inciso-content collapsed"
                  : "solicitud-b__inciso-content"
              }
            >
              <div className="files-accordeon">
                <div>
                  <div
                    className="drag_and_drop"
                    onClick={() =>
                      handleOpenDialogFile("file-upload-identificacion")
                    }
                  >
                    <i className="fas fa-file-upload">
                      {fileIdentificacion != null ? (
                        <img src={fileIdentificacion} />
                      ) : (
                        <img src={File} />
                      )}
                    </i>
                    <span>
                      <label>
                        {/* eslint-disable-next-line jsx-a11y/alt-text */}
                        Elegir un archivo <img src={upload} />
                      </label>
                      <input
                        type="file"
                        id="file-upload-identificacion"
                        onChange={identificacionUploadClick}
                      />
                    </span>
                  </div>
                  <span> Foto de tu INE o IFE * </span>
                </div>
                <div>
                  <div
                    className="drag_and_drop"
                    onClick={() => handleOpenDialogFile("file-upload-selfie")}
                  >
                    <i className="fas fa-file-upload">
                      {fileSelfie != null ? (
                        <img src={fileSelfie} />
                      ) : (
                        <img src={File} />
                      )}
                    </i>
                    <span>
                      <label>
                        {/* eslint-disable-next-line jsx-a11y/alt-text */}
                        Elegir un archivo <img src={upload} />
                      </label>
                      <input
                        type="file"
                        id="file-upload-selfie"
                        onChange={selfieUploadClick}
                      />
                    </span>
                  </div>
                  <span>Selfie con tu INE/IFE *</span>
                </div>
              </div>
              <img
                className="decoration-image"
                src={Desk}
                alt="slefies percapita"
              />
            </div>
            <div>
              <div></div>
              {!shownIdentidad ? (
                <button
                  className="btn green"
                  onClick={() => scrollTo("div-perfil")}
                >
                  Siguiente
                </button>
              ) : (
                ""
              )}
            </div>
          </div>

          <div id="div-perfil" className="solicitud-b__inciso">
            <div className="incisio-header">
              <div className="inciso-header-text">
                <h4>
                  2.Ahora, seguimos con algunos otros datos necesarios para
                  analizar tu perfil y otorgarte tu crédito:
                </h4>
                <span className="toggle-collapsed" onClick={expandirPerfil}>
                  {shownPerfil ? "Abrir" : "Cerrar"}
                </span>
              </div>
              {shownPerfil && perfilCompleta && !perfilVacio ? (
                <img src={GreenCheked} />
              ) : shownPerfil && perfilVacio && !perfilCompleta ? (
                <img src={Error} />
              ) : shownPerfil && !perfilVacio && !perfilCompleta ? (
                <img src={Warning} />
              ) : (
                ""
              )}
            </div>
            <div
              id="4"
              className={
                shownPerfil
                  ? "solicitud-b__inciso-content collapsed"
                  : "solicitud-b__inciso-content"
              }
            >
              <div>
                <div className="solicitud-4__input-group">
                  <div>
                    <label htmlFor="curp">CURP *</label>
                    <input
                      tabIndex={1}
                      type="text"
                      id="curp"
                      name="curp"
                      onChange={handleInputChange}
                      onBlur={onBlurCURP}
                      placeholder="Ejemplo: PARUOA9308ASFHG"
                    />
                  </div>

                  <div>
                    <label htmlFor="nombre">Nombre(s) *</label>
                    <input
                      tabIndex={4}
                      value={userLoan.nombre.toUpperCase()}
                      type="text"
                      id="nombre"
                      name="nombre"
                      onChange={handleInputChange}
                      placeholder="Ej."
                    />
                  </div>

                  <div>
                    <label htmlFor="genero">Genero *</label>
                    <select
                      tabIndex={7}
                      className="input-select"
                      id="genero"
                      name="genero"
                      onChange={handleInputChange}
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="Masculino">Masculino</option>
                      <option value="Femenino">Femenino</option>
                    </select>
                  </div>

                  <div>
                    <label htmlFor="pais">País de nacimiento *</label>
                    <select
                      tabIndex={10}
                      className="input-select"
                      id="pais"
                      name="pais"
                      onChange={handleInputChange}
                    >
                      <option value="">Seleccione un país</option>
                      {paises.map((pais: any) => (
                        <option key={pais.id} value={pais.name}>
                          {pais.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label htmlFor="dependientes">
                      Dependientes Económicos *
                    </label>
                    <input
                      tabIndex={13}
                      type="text"
                      id="dependientes"
                      name="dependientes"
                      onChange={handleInputChange}
                      placeholder="Ej."
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="solicitud-4__input-group">
                  <div>
                    <label htmlFor="rfc">RFC *</label>
                    <input
                      tabIndex={2}
                      type="text"
                      id="rfc"
                      name="rfc"
                      onChange={handleInputChange}
                      onBlur={onBlurRFC}
                      placeholder="Ejemplo: PARUOA9308ASFHG"
                    />
                  </div>
                  <div>
                    <label htmlFor="apellidoPaterno">Apellido Paterno *</label>
                    <input
                      value={userLoan.apellidoPaterno.toUpperCase()}
                      tabIndex={5}
                      type="text"
                      id="apellidoPaterno"
                      name="apellidoPaterno"
                      onChange={handleInputChange}
                      placeholder="Ej."
                    />
                  </div>

                  <div>
                    <label htmlFor="nacionalidad">Nacionalidad *</label>
                    <select
                      tabIndex={8}
                      className="input-select"
                      id="nacionalidad"
                      name="nacionalidad"
                      onChange={handleInputChange}
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="Mexicana">Mexicana</option>
                    </select>
                  </div>

                  <div>
                    <label htmlFor="estado_nacimiento">
                      Estado de Nacimiento *
                    </label>
                    <select
                      tabIndex={11}
                      className="input-select"
                      id="estado_nacimiento"
                      name="estado_nacimiento"
                      onChange={handleInputChange}
                    >
                      <option value="">Seleccione un estado</option>
                      {estados.map((estado: any) => (
                        <option key={estado.id} value={estado.name}>
                          {estado.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label htmlFor="anyo_domicilio">
                      Años en el domicilio *
                    </label>
                    <input
                      tabIndex={14}
                      type="text"
                      id="anyo_domicilio"
                      name="anyo_domicilio"
                      onChange={handleInputChange}
                      placeholder="Ej."
                    />
                  </div>
                </div>
              </div>

              <div>
                <div className="solicitud-4__input-group">
                  <div>
                    <label htmlFor="telefono">Número Teléfono *</label>
                    <input
                      tabIndex={3}
                      value={userLoan.telefono}
                      type="text"
                      id="telefono"
                      name="telefono"
                      onChange={handleInputChangePattern}
                      placeholder="Ejemplo: 9999999999"
                      pattern="[0-9]{0,10}"
                    />
                  </div>

                  <div>
                    <label htmlFor="apellidoMaterno">Apellido Materno *</label>
                    <input
                      value={userLoan.apellidoMaterno.toUpperCase()}
                      tabIndex={6}
                      type="text"
                      id="apellidoMaterno"
                      name="apellidoMaterno"
                      onChange={handleInputChange}
                      placeholder="Ej."
                    />
                  </div>

                  <div>
                    <label htmlFor="nivel_estudio">Nivel de estudios *</label>
                    <select
                      tabIndex={12}
                      className="input-select"
                      id="nivel_estudio"
                      name="nivel_estudio"
                      onChange={handleInputChange}
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="Sin estudios">Sin estudios</option>
                      <option value="Primaria">Primaria</option>
                      <option value="Secundaria">Secundaria</option>
                      <option value="Bachillerato o Preparatoria">
                        Bachillerato o Preparatoria
                      </option>
                      <option value="Normal Básica">Normal Básica</option>
                      <option value="Licenciatura">Licenciatura</option>
                      <option value="Maestria">Maestria</option>
                      <option value="Doctorado">Doctorado</option>
                    </select>
                  </div>

                  <div>
                    <label htmlFor="pais">Tipo de propiedad *</label>
                    <select
                      tabIndex={15}
                      className="input-select"
                      id="tipo_propiedad"
                      name="tipo_propiedad"
                      onChange={handleInputChange}
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="Propia">Propia</option>
                      <option value="Alquiler">Alquiler</option>
                      <option value="Familiar">Familiar</option>
                      <option value="Otros">Otros</option>
                    </select>
                  </div>

                  <div>
                    <label htmlFor="fechaNacimiento">Fecha Nacimiento *</label>
                    <input
                      tabIndex={9}
                      type="date"
                      id="fechaNacimiento"
                      name="fechaNacimiento"
                      onChange={handleInputChange}
                      max={fechaMax}
                    />
                  </div>
                </div>
              </div>

              <img
                className="decoration-image"
                src={Paper}
                alt="docs percapita"
              />
            </div>
            <div
              id="2"
              className={
                shownPerfil
                  ? "solicitud-b__inciso-content collapsed"
                  : "solicitud-b__inciso-content"
              }
            >
              <div className="files-accordeon">
                <div>
                  <div
                    className="drag_and_drop"
                    onClick={() =>
                      handleOpenDialogFile("file-upload-comprobante")
                    }
                  >
                    <i className="fas fa-file-upload">
                      {fileComprobante != null ? (
                        <img src={fileComprobante} />
                      ) : (
                        <img src={File} />
                      )}
                    </i>
                    <span>
                      <label>
                        {/* eslint-disable-next-line jsx-a11y/alt-text */}
                        Elegir un archivo <img src={upload} />
                      </label>
                      <input
                        type="file"
                        id="file-upload-comprobante"
                        onChange={comprobanteUploadClick}
                      />
                    </span>
                  </div>
                  <span> Comprobante domicilio reciente* </span>
                </div>
                <div>
                  <div
                    className="drag_and_drop"
                    onClick={() => handleOpenDialogFile("file-upload-cuenta")}
                  >
                    <i className="fas fa-file-upload">
                      {fileCuenta != null ? (
                        <img src={fileCuenta} />
                      ) : (
                        <img src={File} />
                      )}
                    </i>
                    <span>
                      <label>
                        {/* eslint-disable-next-line jsx-a11y/alt-text */}
                        Elegir un archivo <img src={upload} />
                      </label>
                      <input
                        type="file"
                        id="file-upload-cuenta"
                        onChange={handleUploadClick}
                      />
                    </span>
                  </div>
                  <div className="div-tooltip">
                    <span className="span-tooltip">
                      {" "}
                      Comprobante de ingresos reciente*
                    </span>
                    <div className="wrapper-tooltip-pregunta">
                      <input />
                      <div className="btn" style={{ color: "#000000" }}>
                        ?
                      </div>
                      <div className="tooltip">
                        <div>
                          Nomina, Carta patronal, Carta del jefe, etc...
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {!shownPerfil ? (
                <button
                  className="btn green"
                  onClick={() => scrollTo("div-domicilio")}
                >
                  Siguiente
                </button>
              ) : (
                ""
              )}
            </div>
          </div>

          <div id="div-domicilio" className="solicitud-b__inciso">
            <div className="incisio-header">
              <div className="inciso-header-text">
                <h4>
                  3.¡Ya casi terminamos. Solo necesitamos saber tu dirección y
                  código postal.
                </h4>
                <span className="toggle-collapsed" onClick={expandirDireccion}>
                  {shownDireccion ? "Abrir" : "Cerrar"}
                </span>
              </div>
              {shownDireccion && direccionCompleta && !direccionVacia ? (
                <img src={GreenCheked} />
              ) : shownDireccion && direccionVacia && !direccionCompleta ? (
                <img src={Error} />
              ) : shownDireccion && !direccionVacia && !direccionCompleta ? (
                <img src={Warning} />
              ) : (
                ""
              )}
            </div>
            <div
              id="3"
              className={
                shownDireccion
                  ? "solicitud-b__inciso-content collapsed"
                  : "solicitud-b__inciso-content"
              }
            >
              <div className="solicitud-4__input-group">
                <div>
                  <label>Código Postal *</label>
                  <input
                    tabIndex={16}
                    type="text"
                    id="cp"
                    name="cp"
                    placeholder="Ej."
                    onChange={handleInputChange}
                    onBlur={obtenerCodigoPostal}
                    disabled={loading}
                  />
                </div>

                <div>
                  <label htmlFor="estado">Estado *</label>
                  <input
                    tabIndex={19}
                    disabled={loading}
                    type="text"
                    id="estado"
                    name="estado"
                    value={domicilio.estado}
                    placeholder="Ej."
                    onChange={handleInputChange}
                  />
                </div>
                <Fade
                  in={loading}
                  style={{
                    transitionDelay: loading ? "800ms" : "0ms",
                  }}
                  unmountOnExit
                >
                  <CircularProgress />
                </Fade>
              </div>

              <div className="solicitud-4__input-group">
                <div>
                  <label htmlFor="calle">Dirección (Calle y número) *</label>
                  <input
                    tabIndex={17}
                    disabled={loading}
                    type="text"
                    id="calle"
                    name="calle"
                    onChange={handleInputChange}
                    placeholder="Ej."
                  />
                </div>
                <Fade
                  in={loading}
                  style={{
                    transitionDelay: loading ? "800ms" : "0ms",
                  }}
                  unmountOnExit
                >
                  <CircularProgress />
                </Fade>
                <div>
                  <label htmlFor="ciudad">Ciudad *</label>
                  <input
                    tabIndex={20}
                    disabled={loading}
                    type="text"
                    id="ciudad"
                    name="ciudad"
                    value={domicilio.ciudad}
                    placeholder="Ej."
                    onChange={handleInputChange}
                  />
                </div>
                <Fade
                  in={loading}
                  style={{
                    transitionDelay: loading ? "800ms" : "0ms",
                  }}
                  unmountOnExit
                >
                  <CircularProgress />
                </Fade>
              </div>

              <div className="solicitud-4__input-group">
                <div>
                  <label htmlFor="delegacion">Delegación</label>
                  <input
                    tabIndex={18}
                    disabled={loading}
                    type="text"
                    id="delegacion"
                    name="delegacion"
                    value={domicilio.delegacion}
                    onChange={handleInputChange}
                    placeholder="Ej."
                  />
                </div>

                <div>
                  <label htmlFor="colonia">Colonia *</label>
                  {!shown ? (
                    <select
                      tabIndex={21}
                      disabled={loading}
                      className="input-select"
                      id="colonia"
                      name="colonia"
                      onChange={handleInputChange}
                    >
                      <option value="">Seleccione una opción</option>
                      {colonias.map((colonia: any) => (
                        <option
                          key={colonia.colonia}
                          value={colonia.colonia.toUpperCase()}
                        >
                          {colonia.colonia.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type="text"
                      id="colonia"
                      name="colonia"
                      onChange={handleInputChange}
                      placeholder="Ej."
                    />
                  )}

                  {!shown ? (
                    <span onClick={switchShown} className="add-colonia">
                      Agregar Nueva Colonia{" "}
                      <i className="fas fa-plus-circle"></i>
                    </span>
                  ) : (
                    <span>
                      Regresar a opcciones{" "}
                      <img src={LeftArrow} onClick={switchShown}></img>
                    </span>
                  )}
                </div>
              </div>

              <img className="decoration-image" src={Home} />
            </div>
            <div>
              <div></div>
              {!shownDireccion ? (
                <button
                  className="btn green"
                  onClick={() => scrollTo("div-empleo")}
                >
                  Siguiente
                </button>
              ) : (
                ""
              )}
            </div>
          </div>

          <div id="div-empleo" className="solicitud-b__inciso">
            <div className="incisio-header">
              <div className="inciso-header-text">
                <h4>4. Datos de Empleo y Referencias Personales</h4>
                <span className="toggle-collapsed" onClick={expandirPrestamo}>
                  {shownPrestamo ? "Abrir" : "Cerrar"}
                </span>
              </div>
              {shownPrestamo && prestamoCompleta && !prestamoVacio ? (
                <img src={GreenCheked} />
              ) : shownPrestamo && prestamoVacio && !prestamoCompleta ? (
                <img src={Error} />
              ) : shownPrestamo && !prestamoVacio && !prestamoCompleta ? (
                <img src={Warning} />
              ) : (
                ""
              )}
            </div>
            <div
              id="4"
              className={
                shownPrestamo
                  ? "solicitud-b__inciso-content collapsed"
                  : "solicitud-b__inciso-content"
              }
            >
              <div className="solicitud-1__input-group">
                <div>
                  <h3>Datos de Empleo</h3>
                  <label htmlFor="nombre_compania">Nombre de la compañía</label>
                  <input
                    tabIndex={22}
                    type="text"
                    id="nombre_compania"
                    name="nombre_compania"
                    placeholder="Ej."
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <label htmlFor="antiguedad">Antigüedad Años</label>
                  <input
                    tabIndex={25}
                    type="text"
                    id="antiguedad"
                    name="antiguedad"
                    placeholder="Ej."
                    pattern="[0-9]+"
                    value={datoEmpleo.antiguedad}
                    onChange={handleInputChangePattern}
                  />
                </div>

                <div>
                  <h3>2 Referencias</h3>
                  <label htmlFor="nombre_referencia1">Nombre completo</label>
                  <input
                    tabIndex={28}
                    type="text"
                    id="nombre_referencia1"
                    name="nombre_referencia1"
                    placeholder="Ej."
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <label htmlFor="nombre_referencia2">Nombre completo</label>
                  <input
                    tabIndex={31}
                    type="text"
                    id="nombre_referencia2"
                    name="nombre_referencia2"
                    placeholder="Ej."
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="solicitud-1__input-group">
                <div>
                  <h3></h3>
                  <label htmlFor="sueldo">Sueldo *</label>
                  <input
                    tabIndex={23}
                    type="text"
                    id="sueldo"
                    name="sueldo"
                    placeholder="Ej."
                    pattern="[0-9]+\.?[0-9]{0,2}"
                    value={datoEmpleo.sueldo}
                    onChange={handleInputChangePattern}
                  />
                </div>
                {/* 
                <div>
                  <label htmlFor="actividad_economica">
                    Actividad Económica *
                  </label>
                  <input
                    tabIndex={26}
                    type="text"
                    id="actividad_economica"
                    name="actividad_economica"
                    placeholder="Ej."
                    onChange={handleInputChange}
                  />
                </div> */}

                <div>
                  <label htmlFor="actividad_economica">
                    Actividad Económica *
                  </label>
                  <select
                    tabIndex={26}
                    className="input-select"
                    id="actividad_economica"
                    name="actividad_economica"
                    onChange={handleInputChange}
                  >
                    <option value="">Seleccione una opción</option>
                    {economicActivities.map((pais: any) => (
                      <option key={pais.id} value={pais.name}>
                        {pais.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <h3></h3>
                  <label htmlFor="parentesco">Parentesco *</label>
                  <select
                    tabIndex={29}
                    className="input-select"
                    id="parentesco"
                    name="parentesco"
                    onChange={handleInputChange}
                  >
                    <option value="">Seleccione una opción</option>
                    <option value="Familiar">Familiar</option>
                    <option value="Amistad">Amistad</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="parentesco2">Parentesco *</label>
                  <select
                    tabIndex={32}
                    className="input-select"
                    id="parentesco2"
                    name="parentesco2"
                    onChange={handleInputChange}
                  >
                    <option value="">Seleccione una opción</option>
                    <option value="Familiar">Familiar</option>
                    <option value="Amistad">Amistad</option>
                  </select>
                </div>
              </div>

              <div className="solicitud-1__input-group">
                <div>
                  <h3></h3>
                  <label htmlFor="puesto">Puesto de trabajo *</label>
                  <input
                    tabIndex={24}
                    type="text"
                    id="puesto"
                    name="puesto"
                    placeholder="Ej."
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <label htmlFor="telefono_oficina">Telefono *</label>
                  <input
                    tabIndex={27}
                    type="text"
                    id="telefono_oficina"
                    name="telefono_oficina"
                    value={datoEmpleo.telefono_oficina}
                    onChange={handleInputChangePattern}
                    placeholder="Ejemplo: 9999999999"
                    pattern="[0-9]{0,10}"
                  />
                </div>

                <div>
                  <h3></h3>
                  <label htmlFor="telefono_contacto">
                    Teléfono de contacto*
                  </label>
                  <input
                    tabIndex={30}
                    type="text"
                    id="telefono_contacto"
                    name="telefono_contacto"
                    value={datoEmpleo.telefono_contacto}
                    onChange={handleInputChangePattern}
                    placeholder="Ejemplo: 9999999999"
                    pattern="[0-9]{0,10}"
                  />
                </div>

                <div>
                  <label htmlFor="telefono_contacto2">
                    Teléfono de contacto*
                  </label>
                  <input
                    tabIndex={33}
                    type="text"
                    id="telefono_contacto2"
                    name="telefono_contacto2"
                    value={datoEmpleo.telefono_contacto2}
                    onChange={handleInputChangePattern}
                    placeholder="Ejemplo: 9999999999"
                    pattern="[0-9]{0,10}"
                  />
                </div>
              </div>
              <img className="decoration-image" src={Work} />
            </div>

            <div>
              <div></div>
              {!shownPrestamo ? (
                <button className="btn green" onClick={scrollToTop}>
                  Validar
                </button>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="solicitud-b__bottom">
            <h4>*Campos obligatorios</h4>
            <button className="btn blue" onClick={registerUserLoan}>
              Completar mi Perfil
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestForm4;
