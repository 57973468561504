import React, {useState} from 'react';
import Header from '../header.Nomina.component';
import AlertMessage from "../alertNomina.component";
import {useHistory, useParams} from "react-router-dom";
import ApiClient from '../../../services/apiclient';
//function RequestForm2() {
const RequestForgotPasswordCode = (props: any) => {

  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [email, setEmail] = useState(null)
  const [disabledButton, setDisabledButton] = useState(false);

  const apiClient = ApiClient.getInstance();
  const history = useHistory();
  let vCode = "";
    


  const requestForgotPasswordCode = () => {

    console.log(vCode);

    apiClient.resetPasswordValue(vCode).then(res => {

      setOpenMessage(true);
      setMessage('Su codigo es correcto');

      setTimeout( () => {
            history.push(`/forgot-password/${vCode}`); 
      }, 3000);

  }).catch(error => {
    console.log("err",error);
    setOpenMessage(true);
    setMessage('Ocurrio un error al enviar la petición, verifique el codigo de verificación');
    setSeverity('error');
  });;
    
    };

  const handleClose = () => {
    setOpenMessage(false);
  }

  const handleInputChange = (e: {target: {name: any; value: any}}) => {
    vCode=e.target.value;console.log(vCode);
  };

  return (
    <div>
      <Header />
      <AlertMessage
          open={openMessage}
          message={message}
          handleClose={handleClose}
          severity={severity}
      />
      <div className="solicitud__container">
        <div className="solicitud-2__content">

          <h2 className="code-h2">Ingrese codigo de verificación</h2>

          <input
              type="email"
              onChange={handleInputChange}
              placeholder="123456"
          />
          <button disabled={disabledButton} onClick={requestForgotPasswordCode} className="blue">Enviar</button>

        </div>
      </div>
    </div>
  );
}

export default RequestForgotPasswordCode;
