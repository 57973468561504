import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import eye from '../../assets/eye.png';
import AlertMessage from '../alert.component';
import Header from '../header.component';
import FormRegister from "../form.register.component";
import { UserContext } from '../../context/UserProvider';

function RequestForm1() {
  const history = useHistory();
  const {userMain} = React.useContext(UserContext);
  
/**
 * 
 * La implemento esta validadicion de la siguiente manera ya que con el "const {userMain, updateUser} = React.useContext(UserContext);" tenia valores por default antes de cargar la pagina 
 * y hacia un refresh la pgaina y cargaba la siguiente vista
 */


useEffect(() => {
    var nombre = '';
    if(localStorage.getItem('userLocal')){
      var userLocal = JSON.parse(localStorage.getItem('userLocal'));
      nombre = userLocal.nombre;
    }else{
      nombre = 'Iniciar Sesión'
    }
    console.log('user: ', nombre);
    if (nombre != 'Iniciar Sesión') {
      return history.push('/');
    } 
  }, []);

  return (
    <div>
      <Header />
      <div className="solicitud__container">
        <div className="solicitud-1__form-solicitud">
          <h1>¡Solicita tu prestamo!</h1>
          <span>
            Te prestamos desde $2,000 hasta $10,000 pesos, en tan solo dos
            sencillos pasos:
          </span>
          <div className="solicitud-1__tabs">
          <Link to="#" className="active">
              1. Regístrate
            </Link>
            <Link to="#">2. Llena tu información personal</Link>
          </div>
          <FormRegister type="step-register" />
        </div>
      </div>
    </div>
  );
}

export default RequestForm1;
