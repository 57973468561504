import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import oxxo from "../../../assets/oxxo.png";
import { useHistory } from "react-router";
import { TramiteCreditoContext } from "../../../context/TramiteCreditoProvider";
import { UserContext } from "../../../context/UserProvider";
import ApiClient from "../../../services/apiclient";

const useStyles = makeStyles({
  root: {
    minWidth: 150,
  },
  imgShow: {
    maxWidth:'100%',
   maxHeight:'100%',
  },
  cat: {
    width:'auto',
    height:'300px',
  }
});

export default function ImgMediaCard(props: any) {
    const history = useHistory();
  const classes = useStyles();
  const { userMain, updateUser } = React.useContext(UserContext);
  const { credito, updateCredito } = React.useContext(TramiteCreditoContext);
  const [showSolicita, setShowSolicita] = useState(false);
  const apiClient = ApiClient.getInstance();
  const [statusPrestamo, setStatusPrestamo] = useState("");

  const user = userMain.nombre;
  function redirecComponent(e: { preventDefault: () => void }) {
    e.preventDefault();

    if (credito.estatusSolicitud === "aprobado") {
      if (credito.isDelivered) {
        return history.push("/credito-detalle-nomina");
      } else if (!credito.isDelivered) {
        return history.push("/dashboard2-nomina");
      }
    } else if (credito.estatusSolicitud === "revision") {
      apiClient.getSteps().then((res) => {
        if (res.step === "FINISHED") {
          return history.push("/proceso-credito-nomina");
        } else {
          return history.push("/productos-nomina");
        }
      });
    } else if (credito.estatusSolicitud === "enviar-solicitud") {
      return history.push("/credito-config-nomina");
    } else {
      // return history.push('/sin-credito');
      return history.push("/productos-nomina");
    }
  }

  function redirecEspacioSolicitud(e: { preventDefault: () => void }) {
    e.preventDefault();
      redirecComponent(e);
  }

   const rediRegister =  (lugar : string,e: { preventDefault: () => void }) => {
    if (user != "Iniciar Sesión") {
        redirecEspacioSolicitud(e);
      } else if(lugar === "nomina"){
        updateUser({ ...userMain, nombre: 'Iniciar Sesión', apellidoPaterno: '', creditoNomina:"nomina" });
        return history.push({
            pathname:"/step1-nomina",
            state: {tipo: "nomina"}
        });
      } else if(lugar === "solarum"){
        updateUser({ ...userMain, nombre: 'Iniciar Sesión', apellidoPaterno: '', creditoNomina:"solarum" });
        return history.push({
            pathname:"/step1-nomina",
            state: {tipo: "solarum"}
        });
      } else if(lugar === "celular"){
        updateUser({ ...userMain, nombre: 'Iniciar Sesión', apellidoPaterno: '', creditoNomina:"celular" });
        return history.push({
            pathname:"/step1-nomina",
            state: {tipo: "celular"}
        });
      }
  }

  const rediLogin =  (lugar : string,e: { preventDefault: () => void }) => {
    if (user != "Iniciar Sesión") {
        redirecEspacioSolicitud(e);
      } else if(lugar === "nomina"){
        updateUser({ ...userMain, nombre: 'Iniciar Sesión', apellidoPaterno: '', creditoNomina:"nomina" });
        return history.push({
            pathname:"/login-nomina",
            state: {tipo: "nomina"}
        });
      } else if(lugar === "solarum"){
        updateUser({ ...userMain, nombre: 'Iniciar Sesión', apellidoPaterno: '', creditoNomina:"solarum" });
        return history.push({
            pathname:"/login-nomina",
            state: {tipo: "solarum"}
        });
      } else if(lugar === "celular"){
        updateUser({ ...userMain, nombre: 'Iniciar Sesión', apellidoPaterno: '', creditoNomina:"celular" });
        return history.push({
            pathname:"/login-nomina",
            state: {tipo: "celular"}
        });
      }
  }

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={(e) => rediLogin(props.lugar,e)} >

      <Typography  variant="h4" component="h1">
        {props.title}
          </Typography>
          <div className= {classes.cat}>
          <CardMedia
          component="img"
          alt="Contemplative Reptile"
          //height="140"
          image={props.image}
          title="Contemplative Reptile"
          className = {classes.imgShow}
        />
          </div>
        
        <CardContent>
          <Typography  variant="h5" component="p">
            {props.valueInit}
          </Typography>
          <Typography variant="inherit" color="textPrimary"  component="li">
            {props.plazo}<br/>
            Te prestamos en base al promedio de tus ingresos<br/>
            Pagos via nómina
        </Typography> 
        </CardContent>
      </CardActionArea>
      <CardActions>
      <Button    variant="outlined" size="large" color = "inherit"  onClick={(e) => rediRegister(props.lugar,e)}> 
          Registrarme
        </Button>
        <Button    variant="outlined" size="large" color = "inherit"  onClick={(e) => rediLogin(props.lugar,e)}> 
          Ingresar
        </Button>
      </CardActions>
    </Card>
  );
}
