import File from '../../../assets/file.png';
import upload from '../../../assets/upload.png';
import check from '../../../assets/check-mark.png';
import { useDropzone } from "react-dropzone";
import React, { SetStateAction, useCallback } from "react";
import { FileUpload } from '../../../interfaces/fileInterace';

interface UploadBoxParams {
    propKey: string,
    mimeType: string,
    setter: React.Dispatch<SetStateAction<FileUpload>>,
    message: string,
    file: FileUpload
}

export default function UploadBox({
    mimeType,
    propKey,
    setter,
    message,
    file
}: UploadBoxParams) {


    /**
   * Function that is executed when the user drops a file into
   * the box container.
   */
    const onDrop = useCallback((acceptedFiles: File[]) => {
        setter({ key: propKey, file: acceptedFiles[0] });
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: mimeType });

    return (
        <div>

            <div className="drag_and_drop" {...getRootProps()}>
                <i className="fas fa-file-upload">
                    {
                        file ?
                            file.file.type === 'application/pdf'  ? <i className="fas fa-file-pdf" style={{margin:' 5px', color: '#ef6666'}}></i>: <img src={URL.createObjectURL(file.file)} alt="INE icon" />
                            :
                            <img src={File} alt="INE icon" />
                    }
                </i>
                <span>
                    <label>
                        {isDragActive ? (
                            <p>Dejalo caer aqui</p>
                        ) : (
                            <p>Elegir un archivo </p>
                        )}
                        <img src={upload} alt="upload icon" />
                    </label>
                    <input
                        type="file"
                        id={propKey}
                        {...getInputProps()}
                    />
                </span>
            </div>
            <p>{message}</p>
        </div>
    )
}