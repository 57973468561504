import React, { useEffect, useState } from "react";
import Paper from "../../../assets/paper.png";
import AlertMessage from "../../alert.component";
import HeaderDashboard from "../../headerDashboard.component";
import ApiClient from "../../../services/apiclient";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ProgressCircular } from "../Progress";
import { UserContext } from "../../../context/UserProvider";
import "reactjs-popup/dist/index.css";
import Popup from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import "./register-form.css";
import { Position, ToolbarItem } from "devextreme-react/popup";
import { Button } from "devextreme-react/button";
import AvisoPrivacidad from '../../pages/avisoPrivacidad'
import avisopng from '../../pages/avisoimg.png'
import AvisoPrivacidadView from "../avisoPrivacidadView";
import TerminosCondicionesView from "../terminosCondicionesView";

export default function RegisterForm() {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const { userMain, updateUser } = React.useContext(UserContext);

  const [fechaMax, setFechaMax] = useState("");

  const apiClient = ApiClient.getInstance();

  const history = useHistory();

  const [userLoan, setUserLoan] = useState({
    firstName: "",
    secondName: "",
    lastFName: "",
    lastMName: "",
    birthDate: "",
    email: "",
    sex: "",
  });

  useEffect(() => {
    var firstName = "";
    if (localStorage.getItem("userLocal")) {
      var userLocal = JSON.parse(localStorage.getItem("userLocal"));
      firstName = userLocal.firstName;

      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear() - 18;

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      var format: string = [year, month, day].join("-");
      setFechaMax(format);
    } else {
      firstName = "Iniciar Sesión";
    }

    var creditoLocal = "";
    if (localStorage.getItem("creditoLocal")) {
      var userLocal = JSON.parse(localStorage.getItem("creditoLocal"));
      creditoLocal = userLocal.estatusSolicitud;
    }
  }, []);

  //setUserLoan({...userLoan, nombre: userMain.nombre});

  const [shownPerfil, setShownPerfil] = React.useState(false);
  const [perfilCompleta, setPerfilCompleta] = React.useState(false);
  const [perfilVacio, setPerfilVacio] = React.useState(false);

  const [paises, setPaises] = useState([]);
  const [estados, setEstados] = useState([]);

  const [acceptedTermAndPrivacy, setAcceptedTermAndPrivacy] = useState(false);


  useEffect(() => {
    getValidationTerms();
    obtenerPais();
    obtenerEstados();
  }, []);

  const getValidationTerms = () => {
    apiClient.getValidationTerms().then(resp => {
      const { acceptedTermAndPrivacy } = resp;

      if (acceptedTermAndPrivacy == false) {
      } else {
        setAcceptedTermAndPrivacy(true);
      }
    }).catch(err => {
      if (err.message.includes('Entity not found: User with id')) {
        localStorage.clear();
        history.push('/login')
      }
    })
  }
  //obtener pais
  const obtenerPais = async () => {
    // await fetch("https://restcountries.eu/rest/v2/all")
    //   .then((res) => res.json())
    //   .then((json) => {
    //     setPaises(paises);
    //   })
    //   .catch((e) => console.log(e));
  };

  var obtenerEstados = function () { };

  function getAge(birthDate: string) {
    var fechaNace = new Date(birthDate);
    var fechaActual = new Date();

    var edad = fechaActual.getFullYear() - fechaNace.getFullYear();
    var m = fechaActual.getMonth() - fechaNace.getMonth();

    if (m < 0 || (m === 0 && fechaActual.getDate() < fechaNace.getDate())) {
      edad--;
    }

    return edad;
  }

  const onsubmitForm = async(values) => {
    if (avisoDePrivacidad == false) {
      setOpenMessage(true);
      setMessage("Para poder continuar necesitas aceptar el aviso de privacidad.");
      setSeverity("info");
      return;
    }
    
    if (contratoLicencia == false) {
      setOpenMessage(true);
      setMessage("Para poder continuar necesitas aceptar el contrato de licencia.");
      setSeverity("info");
      return;
    }
    setLoading(true);

    try {
      await apiClient.termsConditions({ publicidad: publicidad });
      values.birthDate = new Date(values.birthDate).toISOString();

    } catch (error) {
      setLoading(false);
      setOpenMessage(true);
      setMessage("Ha ocurrido un error al guardar los datos.");
      setSeverity("error");
      return;
    }

      

      apiClient
        .createUserDetails(values)
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            setOpenMessage(true);
            setMessage(data.error.message);
            setSeverity("error");
          }
          const fullName = `${values.firstName} ${values.secondName} ${values.lastFName} ${values.lastMName}`;
          updateUser({
            ...userMain,
            nombre: fullName,
          });
          return history.push("/productos");
        })
        .catch((err) => {
          setLoading(false);
          setOpenMessage(true);
          setMessage("Favor de verificar que la informacion sea correcta.");
          setSeverity("error");
        });
  };

  var validarSeccionDos = function () {
    //validamos datos requeridos

    if (userLoan.firstName === "") {
      setOpenMessage(true);
      setMessage("Nombre es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.lastFName === "") {
      setOpenMessage(true);
      setMessage("Apellido paterno es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.lastMName === "") {
      setOpenMessage(true);
      setMessage("Apellido materno es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.sex === "") {
      setOpenMessage(true);
      setMessage("El genero es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    if (userLoan.birthDate === "") {
      setOpenMessage(true);
      setMessage("La fecha de nacimiento es requerido. Favor de verificar.");
      setSeverity("error");
      return;
    }

    //dar formato fecha nacimiento
    var d = new Date(userLoan.birthDate),
      month = "" + (d.getMonth() + 1),
      day = "" + (d.getDate() + 1),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var formatoFecha: string = [year, month, day].join("-");

    var edad: number = getAge(formatoFecha);

    if (edad <= 21) {
      setOpenMessage(true);
      setMessage(
        "Debe ser mayor a 21 años de edad para realizar proceso de solicitud. Favor de verificar."
      );
      setSeverity("error");
      return;
    }
  };

  function validateForm() {
    const isEmpty = Object.values(userLoan).every((x) => x === "");
    const isPartial = Object.values(userLoan).some((x) => x === "");

    if (isEmpty) {
      setPerfilVacio(true);
      setPerfilCompleta(false);
    } else if (isPartial) {
      setPerfilVacio(false);
      setPerfilCompleta(false);
    } else {
      setPerfilVacio(false);
      setPerfilCompleta(true);
    }
  }

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    setUserLoan({
      ...userLoan,
      [e.target.name]: e.target.value.toUpperCase(),
    });
  };

  const handleInputChangePattern = (e: {
    target: { name: any; value: any; validity: any };
  }) => {
    const esValido = e.target.validity.valid;
    if (esValido == true) {
      setUserLoan({
        ...userLoan,
        [e.target.name]: e.target.value.toUpperCase(),
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);

  };
  const [avisoDePrivacidad, setavisoDePrivacidad] = useState(false);
  const handleChange = () => {
    setavisoDePrivacidad(!avisoDePrivacidad);
  };

  const [contratoLicencia, setcontratoLicencia] = useState(false);
  const handleChangeContrato = () => {
    setcontratoLicencia(!contratoLicencia);
  };

  const [publicidad, setPublicidad] = useState(false);
  const handleChangePubliccidad = () => {
    setPublicidad(!publicidad);
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Requerido"),
    secondName: Yup.string(),
    lastFName: Yup.string().required("Requerido"),
    lastMName: Yup.string().required("Requierdo"),
    birthDate: Yup.string().required("Requerido"),
    email: Yup.string(),
    sex: Yup.string().required("Requerido"),
  });

  const [isPopupVisible, setPopupVisibility] = useState(false);

  const aceptarTerminos = () => {

  };


  const rechazar = () => {
    localStorage.clear()
    history.push("/login");
  }

  return (
    <div>
      <AlertMessage
          open={openMessage}
          message={message}
          handleClose={handleClose}
          severity={severity}
        />
      <HeaderDashboard />

      <Popup
        visible={isPopupVisible}
        closeOnOutsideClick={false}
        width={1000}
        height={550}
        showTitle={false}
        onHiding={aceptarTerminos}
      >
        <AlertMessage
          open={openMessage}
          message={message}
          handleClose={handleClose}
          severity={severity}
        />
        <ScrollView width="100%" height="100%">
          <div id="textBlock">

            <AvisoPrivacidadView />

            <TerminosCondicionesView />

            <div className="avisoContent">
              <label>
                <input style={{ float: 'left', width: '10px' }} type="checkbox"
                  checked={avisoDePrivacidad}
                  onChange={handleChange}
                />
              </label>
              <span style={{ fontSize: 14, marginLeft: 5 }}>
                Consiento al tratamiento de mis datos personales en términos del
                <a href="/aviso-de-privacidad" target="_blank" style={{ fontSize: 14, marginLeft: 5 }}>AVISO DE PRIVACIDAD</a>
                .
              </span>
            </div>
            <br />
            <div className="avisoContent">
              <label>
                <input style={{ float: 'left', width: '10px' }} type="checkbox"
                  checked={contratoLicencia}
                  onChange={handleChangeContrato}
                />
              </label>
              <span style={{ fontSize: 14, marginLeft: 5 }}>
                Me obligo a cumplir con el <a href="/contrato-licencia" target="_blank" style={{ fontSize: 14, marginLeft: 5 }}>CONTRATO DE LICENCIA</a>
              </span>
            </div>
            <br />
            <div className="avisoContent">
              <label>
                <input style={{ float: 'left', width: '10px' }} type="checkbox"
                  checked={publicidad}
                  onChange={handleChangePubliccidad}
                />
              </label>
              <span style={{ fontSize: 14, marginLeft: 5 }}>
                Acepto recibir noticias, ofertas e informacion relevantes sobre mi cuenta.
              </span>
            </div>


            <div className="solicitud-b__bottom button-center">
              <button className="btn blue marginAviso" type="submit" onClick={aceptarTerminos}>
                Continuar
              </button>
              <button className="btn blue" type="submit" onClick={rechazar}>
                Rechazar
              </button>
            </div>

          </div>
        </ScrollView>
      </Popup>

      <div className="solicitud__container">
        <Formik
          initialValues={userLoan}
          onSubmit={onsubmitForm}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, errors, touched }) => (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="solicitud-b__container">
                <div id="div-perfil" className="solicitud-b__inciso">
                  <div className="incisio-header">
                    <div className="inciso-header-text">
                      <h4>Formulario de Registro</h4>
                    </div>
                  </div>

                  <div
                    id="4"
                    className={
                      shownPerfil
                        ? "solicitud-b__inciso-content collapsed"
                        : "solicitud-b__inciso-content"
                    }
                  >
                    <div className="form-co"></div>
                    <div className="solicitud-4__input-group">
                      <div>
                        <label htmlFor="firstName">Nombre *</label>
                        <Field
                          tabIndex={4}
                          type="text"
                          id="firstName"
                          name="firstName"
                          /*  onChange={handleInputChange} */
                          placeholder="Ej."
                        />
                        <ErrorMessage name={`firstName`}>
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <div>
                        <label htmlFor="secondName">Segundo nombre *</label>
                        <Field
                          tabIndex={4}
                          type="text"
                          id="secondName"
                          name="secondName"
                          /*  onChange={handleInputChange} */
                          placeholder="Ej."
                        />
                      </div>

                      <div>
                        <label htmlFor="lastFName">Apellido Paterno *</label>
                        <Field
                          tabIndex={5}
                          type="text"
                          id="lastFName"
                          name="lastFName"
                          /* onChange={handleInputChange} */
                          placeholder="Ej. Hernández"
                        />
                        <ErrorMessage name={`lastFName`}>
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label htmlFor="lastMName">Apellido Materno *</label>
                        <Field
                          tabIndex={6}
                          type="text"
                          id="lastMName"
                          name="lastMName"
                          /* onChange={handleInputChange} */
                          placeholder="Ej."
                        />
                        <ErrorMessage name={`lastMName`}>
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <div>
                        <label htmlFor="birthDate">Fecha Nacimiento *</label>
                        <Field
                          tabIndex={9}
                          type="date"
                          id="birthDate"
                          name="birthDate"
                          /* onChange={handleInputChange} */
                          max={fechaMax}
                        />
                        <ErrorMessage name={`birthDate`}>
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label htmlFor="sex">Genero *</label>
                        <Field
                          as="select"
                          className="input-select"
                          id="sex"
                          name="sex"
                        /* onChange={handleInputChange} */
                        >
                          <option value="">Seleccione una opción</option>
                          <option value="Masculino">Masculino</option>
                          <option value="Femenino">Femenino</option>
                        </Field>
                        <ErrorMessage name={`sex`}>
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>

                      <div>
                        <label htmlFor="email">Email (Opcional)</label>
                        <Field
                          type="text"
                          id="email"
                          name="email"
                          placeholder="abcd@gmail.com"

                        /* onChange={handleInputChangePattern} */
                        />

                        <ErrorMessage name={`email`}>
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>

                    <img
                      className="decoration-image"
                      src={Paper}
                      alt="docs percapita"
                    />
                  </div>

                  <div className="solicitud-4__input-group"></div>
                </div>
                <div id="textBlock" className="aceptarPAVISO">

                  <div className="avisoContent">
                    <label>
                      <input style={{ float: 'left', width: '10px' }} type="checkbox"
                        checked={avisoDePrivacidad}
                        onChange={handleChange}
                      />
                    </label>
                    <span style={{ fontSize: 14, marginLeft: 5 }}>
                      Consiento al tratamiento de mis datos personales en términos del
                      <a href="/aviso-de-privacidad" target="_blank" style={{ fontSize: 14, marginLeft: 5 }}>AVISO DE PRIVACIDAD</a>
                      .
                    </span>
                  </div>
                  <br />
                  <div className="avisoContent">
                    <label>
                      <input style={{ float: 'left', width: '10px' }} type="checkbox"
                        checked={contratoLicencia}
                        onChange={handleChangeContrato}
                      />
                    </label>
                    <span style={{ fontSize: 14, marginLeft: 5 }}>
                      Me obligo a cumplir con el <a href="/contrato-licencia" target="_blank" style={{ fontSize: 14, marginLeft: 5 }}>CONTRATO DE LICENCIA</a>.
                    </span>
                  </div>
                  <br />
                  <div className="avisoContent">
                    <label>
                      <input style={{ float: 'left', width: '10px' }} type="checkbox"
                        checked={publicidad}
                        onChange={handleChangePubliccidad}
                      />
                    </label>
                    <span style={{ fontSize: 14, marginLeft: 5 }}>
                      Acepto recibir noticias, ofertas e informacion relevantes sobre mi cuenta.
                    </span>
                  </div>
                </div>
                <div className="solicitud-b__bottom button-center">
                  <h4>*Campos obligatorios</h4>
                  <button className="btn blue" type="submit" disabled={loading}>
                    {loading ?
                      <ProgressCircular
                        loading={true}
                        title="Completar mi Perfil"
                      />
                      :
                      "Completar mi Perfil"
                    }
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
