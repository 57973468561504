import React, { useEffect,useState } from 'react';
import Header from '../header.component';
import Footer from './footer';
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import avisopng from './avisoimg.png'

export default function DerechosArco() {

  useEffect(() => {
    document.querySelector('body').scrollTo(0,0)
  }, []);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  

  return (
    <div>
      <Header />

   

     

      <div className="avisoPrivacidad">

        <h1>Derechos ARCO</h1>

        </div>

        <div className="avisoContent">

        <br /><br />   El presente es el <b>MANUAL DE PROCEDIMIENTOS DE EJERCICIO DE DERECHOS</b> , de Probenso Group, Sociedad Anónima, 
        Sociedad Financiera de Objeto Múltiple, Entidad No Regulada (en adelante la Sociedad), 
        que se expide con el objetivo de fijar las reglas claras y precisas para dar trámite a las solicitudes
         de derechos de autodeterminación informativa que formulen los titulares de datos personales que estén 
         sujetos a tratamiento, incluido los Derechos ARCO. Para los efectos del presente documento serán aplicables
          las definiciones establecidas en el citado Código de Políticas de Privacidad de la Sociedad. La parte que sigue 
          de este documento deberá de ser publicada para consulta de los titulares de datos personales.<b></b>

          <br /><br /><b>I. PROCEDIMIENTO PARA EJERCER LOS DERECHOS ARCO</b>
          <hr />
          <br /><br />
         <b> 1.-</b> El procedimiento de ejercicio de derechos ARCO se iniciará, a instancia de parte interesada, mediante solicitud por escrito que deberá contener, por lo menos, los siguientes requisitos:
          <br /><br /><b>A.</b>          	El nombre completo del titular y de su representante legal, en su caso;

          <br /><br /><b>B.	</b>Correo electrónico para efectos de comunicarle la respuesta a la solicitud.

          <br /><br /><b>C.</b>	Descripción clara y precisa de los datos personales respecto de los cuales se busca ejercer alguno de los derechos ARCO;

          <br /><br /><b>D.</b>	Descripción clara y precisa del derecho que se pretende ejercer;

          <br /><br /><b>E.</b>	En caso de solicitudes de rectificación de datos personales, se deberán indicar, con toda precisión, las modificaciones a realizarse y;

          <br /><br /><b>F.</b>	La información que lo identifique como cliente de nuestros productos o servicios, en caso de contar con ella.
              <br /><br />A dicha solicitud deberá agregarse lo siguiente, en copia escaneada a color:
          <br /><br /><b>A.</b>	Identificación Oficial que acredite la identidad del interesado y su representante legal (e.g credencial para votar, pasaporte, licencia de conducir, cédula profesional, cédula de identidad ciudadana, cartilla del SMN);

          <br /><br /><b>B.</b> Documento en donde conste la representación y personalidad del representante legal, en su caso;

          <br /><br /><b>C.</b>	En caso de solicitudes de Rectificación de datos personales, la documentación que sustente la petición y;

          <br /><br /><b>D.</b>	Cualquier documentación que el solicitante considere necesaria para sustentar su solicitud.

          <br /><br />
          Si dentro del plazo de 10 días hábiles contados a partir de la fecha en que se le notifique la prevención requerida el solicitante no desahoga o resuelve la prevención requerida por nuestro Departamento de Protección de Datos Personales (en adelante DDP), se desechará su solicitud. El desechamiento de una solicitud de derechos ARCO, podrá ser impugnado mediante nuestro procedimiento de queja interno de conformidad con lo establecido en este documento.<b></b>
          <br /><br />
        <b>2.- </b>  La solicitud podrá presentarse por cualquiera de los medios que se establecen en nuestro aviso privacidad integral al amparo del cual se obtuvieron los datos personales. 
          <br /><br /> <b>3.- </b> Una vez recibida la solicitud, el DDP podrá prevenirla, aceptarla o desecharla. 
          <br /><br /><b> 3.1 Prevención:</b>           En caso de que la solicitud presentada no cumpla con alguno de los requisitos establecidos en los incisos anteriores, o en caso de que la información contenida en la solicitud sea errónea o insuficiente para atenderla, nuestro DDP prevendrá al solicitante, dentro de los 5 días hábiles posteriores a la fecha de recepción de la solicitud, para que subsane los errores, imprecisiones u omisiones que la misma contenga. Si dentro del plazo de 10 días hábiles contados a partir de la fecha en que se le notifique la prevención requerida el solicitante no desahoga o resuelve la prevención requerida por nuestro DDP, se desechará su solicitud, situación de la cual nuestro DDP dejará constancia.

          <br /><br /><b>3.2 Desechamiento:</b> El desechamiento procederá en caso de que se actualicen alguna de las causales para tal efecto establecidas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su reglamento o normatividad conexa.

          <br /><br /><b>3.3 Admisión: </b>La admisión procederá siempre y cuando la solicitud cumpla con todos y cada uno de los requisitos exigidos por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su reglamento o normatividad conexa y por el punto 1 del presente documento

          <br /><br /><b>4.- </b>          Admitida la solicitud, nuestro DDP le notificará al titular sobre la admisión de la misma, enviándole un acuse de recepción dentro de los 5 días hábiles posteriores a la fecha de presentación de la solicitud respectiva. Dicho acuse se le hará llegar por el mismo medio por el que este presentó su solicitud. Acto seguido, nuestro DDP analizará la solicitud y dictará la resolución que conforme a derecho proceda.

          <br /><br /><b>5.- </b>La resolución que se dicte con motivo de la solicitud de derechos ARCO de que se trate podrá conceder o negar las pretensiones del solicitante. En ambos casos la resolución deberá estar plena y absolutamente justificada conforme a al derecho aplicable y vigente y conforme a nuestros códigos, políticas y prácticas de privacidad. La resolución que se dicte deberá de ser notificada al solicitante en un tiempo máximo de 20 días hábiles siguientes a la fecha en que nuestro DDP recibió la solicitud de ejercicio de derechos.

          <br /><br /><b>6.- </b>Si la resolución definitiva resulta favorable a los intereses del solicitante, nuestro DDP hará efectiva dicha resolución dentro de los 15 días hábiles siguientes a la fecha en que se comunique la respuesta al solicitante. Si la resolución definitiva resulta desfavorable a los intereses del solicitante, esta podrá ser combatida mediante nuestro procedimiento de queja interno de conformidad con lo establecido en este documento o bien, el titular podrá iniciar los procedimientos de protección de derechos que considere conducentes ante el INAI.

          <br /><br /><b>7.-</b> Nuestro DDP notificará al solicitante la ejecución de la resolución, dentro de los 15 días hábiles a que se referencia en el primer párrafo del punto 6 del presente. En caso de que se trate de solicitudes de acceso de datos personales, la entrega de los mismos se realizará previo acreditamiento de personalidad del solicitante. En la notificación de la resolución, se establecerá la forma y términos en que se ejecutará la resolución.

          <br /><br /><b>8.- </b>Cualquier notificación que nuestro DDP deba de hacerle al solicitante se hará mediante correo electrónico enviado a la dirección que el solicitante proporcione en su solicitud. En caso de que el solicitante no proporcione dirección de correo electrónico en su solicitud la notificación será enviada a cualquier otro correo electrónico del titular de los datos sobre los cuales se ejerce el derecho ARCO de que se trate, que nuestro DDP tenga registrado en nuestras bases de datos. En caso de que no contemos con alguna dirección de correo electrónico en nuestras bases de datos, nuestro DDP podrá contactar al solicitante o al titular de los datos sobre los cuales se ejercen los derechos ARCO por cualquier otro medio que tenga a su disponibilidad en nuestras bases de datos, para solicitarle de manera expresa el nombramiento de una dirección de correo electrónico.
          <br />
          En caso de que, habiendo agotado todos los esfuerzos razonables anteriormente citados, no sea posible identificar o contactar al solicitante, la solicitud será desechada de plano por nuestro departamento de protección de datos personales. De dicha situación se dejará constancia.

          <br /><br /><b>II. PROCEDIMIENTO PARA REVOCAR EL CONSENTIMIENTO</b>
          <hr />
          <br /><br /><b>1.-</b>           El procedimiento para revocar el consentimiento se podrá iniciar a instancia de parte interesada mediante solicitud por escrito que deberá contener, por lo menos, los siguientes requisitos:

          <br /><br /><b>A.</b>	Nombre completo del titular y su representante legal en su caso;

          <br /><br /><b>B.</b>	Correo electrónico para efectos de comunicarle la respuesta del trámite de revocación de consentimiento;

          <br /><br /><b>C.</b>	Mención expresa de que es voluntad del titular revocar su consentimiento para el tratamiento de sus datos personales y;

          <br /><br /><b>D.</b>	El número que lo identifique como cliente de nuestros productos o servicios en caso de contar con él.
          <br /><br />A dicha solicitud deberá agregarse lo siguiente, en copia escaneada a color:
          <br /><br /><b>A.</b>	Identificación Oficial que acredite la identidad del interesado y su representante legal (e.g credencial para votar, pasaporte, licencia de conducir, cédula profesional, cédula de identidad ciudadana, cartilla del SMN);

          <br /><br /><b>B.</b>	Documento en donde conste la representación y personalidad del representante legal, en su caso y;

          <br /><br /><b>C.</b>	Cualquier documentación que el solicitante considere idónea para apoyar o fundamentar su petición.
            <br /><br />
            La solicitud podrá presentarse por cualquiera de los medios que se establecen en el aviso de privacidad integral al amparo del cual se obtuvieron los datos personales. 

          <br /><br /><b>2.-</b> Para el trámite del procedimiento de revocación de consentimiento serán aplicables todas y cada una de las reglas procedimentales aplicables al procedimiento de protección de derechos ARCO establecidos en el punto I anterior en todo lo que no se oponga al presente apartado.

          <br /><br /><b>3.-</b> Nuestro DDP podrá resolver la solicitud de revocación de consentimiento (i) concediendo la revocación del consentimiento o (ii) negando la revocación del consentimiento. 

          <br /><br /><b>4.-</b> Nuestro DDP podrá negar la revocación de consentimiento por cualquiera de las siguientes razones:

          <br /><br /><b>A.</b>	En caso de que alguna Ley, Tratado, norma, reglamento, disposición o cualquier otro de naturaleza similar o análoga prohíba la revocación del consentimiento del tratamiento de los datos personales;

          <br /><br /><b>B.</b>	Si el consentimiento del titular no es necesario para realizar el tratamiento de los datos personales en cuestión de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su regulación conexa y;

          <br /><br /><b>C.</b>	En caso de que existan obligaciones pendientes de cumplimiento al momento de la interposición de la solicitud de revocación del consentimiento para el tratamiento de los datos personales del interesado, salvo que nuestro DDP disponga lo contrario.

          <br /><br /><b>5.- </b>Si la resolución del procedimiento de revocación de consentimiento resulta favorable a los intereses del solicitante, nuestro DDP deberá dejar de tratar dichos datos personales de manera inmediata y acto seguido procederá a cancelar mismos, de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares. De dicha situación se dejará constancia.

          <br /><br /><b>6.-</b> Si la resolución definitiva resulta desfavorable a los intereses del solicitante, esta podrá ser combatida mediante nuestro procedimiento de queja interno de conformidad con lo establecido en este documento, o bien, podrá interponer cualquier otro recurso que ponga a su disposición la Ley Federal de Protección de Datos Personales en Posesión de los Particulares o su disposición conexa.

          <br /><br /> <b>III. PROCEDIMIENTO PARA LIMITAR EL USO DE DATOS PERSONALES.</b>
          <hr />

          <br /><br /><b>1.- </b>Cualquier persona podrá, en cualquier momento, limitar el uso de sus datos personales para fines mercadotécnicos y de prospección comercial y para aquellos fines que no son necesarios o indispensables para cumplir con una relación jurídica celebrada con nosotros. 

          <br /><br /><b>2.-</b> La solicitud se presentará en la forma y a través de los medios establecidos en el aviso de privacidad integral. 

          <br /><br /><b>3.-</b> Para asegurar que el tratamiento de los datos personales para los fines enumerados en el punto 1 de este apartado ha sido limitado correctamente, el DDP deberá de inscribir en un listado de exclusión a los titulares que hayan ejercido su derecho de limitante de conformidad con este capítulo.  Dicho listado de exclusión (i) será propio de la Sociedad, (ii) Llevará por nombre “Listado de exclusión interno para el tratamiento de datos personales con fines de mercadotecnia, publicidad, prospección comercial y finalidades secundarias” y (iii) estará a cargo del DDP quien responderá de su existencia.   

          <br /><br /><b>4.-</b> Nuestro DDP limitará el uso de los datos personales en un periodo que nunca excederá de los 20 días hábiles siguientes a la fecha en que el interesado solicitó la limitante de sus datos personales.

          <br /><br /><b>5.-</b> Si por cualquier forma el interesado considera que se han lesionado sus derechos o que nuestro DDP no ha dado cumplimiento a su solicitud de limitante del uso de sus datos personales, podrá iniciar el procedimiento de queja interno de conformidad con lo establecido en el presente documento.
        
          <br /><br /><b>IV. PROCEDIMIENTO DE QUEJA</b>
          <hr />

          <br /><br /><b>1.-</b> El procedimiento de queja interno es un recurso que tiene por objeto confirmar o revocar las resoluciones de nuestro DDP en materia de procedimiento para ejercer derechos ARCO, procedimiento de revocación del consentimiento y/o procedimiento para limitar el uso de datos personales. Este procedimiento es opcional. 

          <br /><br /><b>2.-</b> Cuando algún solicitante considere que alguna resolución emitida por el DDP le causa perjuicio o que la misma es infundada o contraria a derecho, podrá iniciar el procedimiento interno de queja mediante la interposición de un escrito enviado mediante correo electrónico a nuestro DDP por cualquiera de los medios establecidos en el aviso de privacidad para iniciar el procedimiento de ejercicio de derechos ARCO. Dicho escrito deberá de contener:

          <br /><br /><b>A.</b>	Datos de identificación del procedimiento cuya resolución se controvierte;

          <br /><br /><b>B.</b>	Nombre completo del solicitante o de su representante legal en su caso y;

          <br /><br /><b>C.</b>	Una descripción clara y precisa en donde fundamente y enliste las razones de porqué considera que la resolución emitida por el DDP es incorrecta, ilegal o le causa perjuicio.

          <br /><br /><b>3.-</b> El DDP turnará la queja, dentro de los 5 día hábiles siguientes a la fecha de recepción, al Órgano de Administración de la Sociedad, con copia a su órgano de vigilancia, si lo hubiere.

          <br /><br /><b>4.-</b> Nuestro Órgano de Administración resolverá la queja formulada a más tardar 7 días hábiles posteriores a la fecha en que reciba la queja de conformidad con el párrafo anterior.

          <br /><br /><b>5.-</b> De todos y cada uno de los envíos y recepciones a que se hace referencia en los 2 párrafos anteriores se dejará constancia.

          <br /><br /><b>6.-</b> La resolución de la queja podrá (i) confirmar la resolución del DDP o (ii) revocar la resolución del DDP. En caso de revocación de la resolución, el Órgano de Administración indicará al DDP el sentido que deberá de indicarse en la resolución, para efectos de que la misma sea dictada nuevamente.

          <br /><br /><b>7.-</b> Cualquiera que sea el sentido de la resolución de la queja interpuesta, le será notificada al interesado por el DDP de conformidad con las normas procedimentales establecidas para efectos de la notificación en el procedimiento de ejercicio de derechos ARCO.

          <br /><br /><b>8.-</b> Cualquier titular podrá iniciar el presente procedimiento de queja sin perjuicio de iniciar, incluso de manera simultánea un procedimiento de protección de derechos ante el INAI, en términos de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares. 

          <br /><br /><b></b>





      
        </div>

 

      <Footer />
    </div>
  );
}
