import { Oval } from '@agney/react-loading';
import React from 'react';
import { useHistory } from 'react-router';
import { TramiteCreditoContext } from '../../../../context/TramiteCreditoProvider';
import ApiClient from '../../../../services/apiclient';
import EstadoDeCuentaAccordeon from '../../EstadoDeCuentaAccordeonNomina';
import HeaderDashboard from '../../headerDashboardNomina.component';

export default function AccountStatus() {
  const history = useHistory();
  const [today, setToday] = React.useState<string>(undefined);
  const [isInApprovalProcess, setIsInApprovalProcess] = React.useState(
    undefined,
  );

  const [status, setStatus] = React.useState(
    undefined
  );

  const [loading, setLoading] = React.useState(true);
  const [isDelivered, setIsDelivered] = React.useState(true);
  const apiClient = ApiClient.getInstance();

  const { credito, updateCredito } = React.useContext(TramiteCreditoContext);



  const getCreditData = async () => {

    try {
      const { creditType } = await apiClient.getActiveCredit();

      const credit = await apiClient.getCredit(creditType);

      switch (credit.status) {
        case 'IN_PROGRESS':
          setStatus('Revisión')
          break;

        case 'APROOVED':
          setStatus('Aprobado');
          break;

        case 'DENIED':
          setStatus('Rechazado')
          break;
        default:

          break;
      }

      setIsDelivered(credit.isDelivered);

      if (credit.status === 'DENIED') {
        setIsInApprovalProcess(undefined);
      } else {
        setIsInApprovalProcess(credit.status === 'IN_PROGRESS' ? true : false);
      }

      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const date = new Date();

    setToday(
      date.toLocaleDateString('es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
    );
  }, []);
  React.useEffect(() => {
    getCreditData();
  }, []);

  const verificarPayments = () => {
    var payments = credito.detalles;
    var foundpaymentWasMade = payments.find(val => val.paymentWasMade == true);
    console.log("estatus pago: " + foundpaymentWasMade)
    if (foundpaymentWasMade) {
      return true;
    }
    return false

  }

  const creditoAprobado = () => {
    if (credito.estatusSolicitud === 'sin-solicitud') {
      return history.push('/type-credit-application-nomina');
    } else if (credito.estatusSolicitud === 'solicitud-no-aprobada') {
      return history.push('/sin-permiso-tramite-nomina');
    }
  }

  const approvalProcess = () => {

    if (isInApprovalProcess === undefined) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {' '}
          <h1
            style={{ textAlign: 'center', fontSize: '24px', marginTop: '50px' }}
          >
            ¡Aun no haz realizado ningun credito!
          </h1>
          <span className="estado-cuenta">
            <button
              className="blue landing-first-section-btn"
              type="button"
              onClick={creditoAprobado}
            >
              Solicitar mi prestamo
            </button>
          </span>
        </div>

      )
    } else if (isInApprovalProcess === true && (status === 'Aprobado' || status === 'Revisión') && isDelivered === false && verificarPayments() === false) {
      return (
        <div className="dashboard-estado-cuenta__container">
          <EstadoDeCuentaAccordeon title="Enero 2020">
            <table className="blueTable">
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>N&uacute;mero</th>
                  <th>Concepto</th>
                  <th>Cantidad a pagar</th>
                  <th>Monto total acumulado</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Nombre mes</td>
                  <td>cell2_1</td>
                  <td>cell3_1</td>
                  <td>cell4_1</td>
                  <td>cell5_1</td>
                </tr>
                <tr>
                  <td>Nombre mes</td>
                  <td>cell2_2</td>
                  <td>cell3_2</td>
                  <td>cell4_2</td>
                  <td>cell5_2</td>
                </tr>
                <tr>
                  <td>Nombre mes</td>
                  <td>cell2_3</td>
                  <td>cell3_3</td>
                  <td>cell4_3</td>
                  <td>cell5_3</td>
                </tr>
                <tr>
                  <td>Nombre mes</td>
                  <td>cell2_4</td>
                  <td>cell3_4</td>
                  <td>cell4_4</td>
                  <td>cell5_4</td>
                </tr>
              </tbody>
            </table>
          </EstadoDeCuentaAccordeon>
          <EstadoDeCuentaAccordeon title="Febrero 2020">
            <table className="blueTable">
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>N&uacute;mero</th>
                  <th>Concepto</th>
                  <th>Cantidad a pagar</th>
                  <th>Monto total acumulado</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Nombre mes</td>
                  <td>cell2_1</td>
                  <td>cell3_1</td>
                  <td>cell4_1</td>
                  <td>cell5_1</td>
                </tr>
                <tr>
                  <td>Nombre mes</td>
                  <td>cell2_2</td>
                  <td>cell3_2</td>
                  <td>cell4_2</td>
                  <td>cell5_2</td>
                </tr>
                <tr>
                  <td>Nombre mes</td>
                  <td>cell2_3</td>
                  <td>cell3_3</td>
                  <td>cell4_3</td>
                  <td>cell5_3</td>
                </tr>
                <tr>
                  <td>Nombre mes</td>
                  <td>cell2_4</td>
                  <td>cell3_4</td>
                  <td>cell4_4</td>
                  <td>cell5_4</td>
                </tr>
              </tbody>
            </table>
          </EstadoDeCuentaAccordeon>
          <EstadoDeCuentaAccordeon title="Marzo 2020">
            <table className="blueTable">
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>N&uacute;mero</th>
                  <th>Concepto</th>
                  <th>Cantidad a pagar</th>
                  <th>Monto total acumulado</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Nombre mes</td>
                  <td>cell2_1</td>
                  <td>cell3_1</td>
                  <td>cell4_1</td>
                  <td>cell5_1</td>
                </tr>
                <tr>
                  <td>Nombre mes</td>
                  <td>cell2_2</td>
                  <td>cell3_2</td>
                  <td>cell4_2</td>
                  <td>cell5_2</td>
                </tr>
                <tr>
                  <td>Nombre mes</td>
                  <td>cell2_3</td>
                  <td>cell3_3</td>
                  <td>cell4_3</td>
                  <td>cell5_3</td>
                </tr>
                <tr>
                  <td>Nombre mes</td>
                  <td>cell2_4</td>
                  <td>cell3_4</td>
                  <td>cell4_4</td>
                  <td>cell5_4</td>
                </tr>
              </tbody>
            </table>
          </EstadoDeCuentaAccordeon>
        </div>

      )
    } else {
      return (
        <>
          <h1
            style={{ textAlign: 'center', fontSize: '24px', marginTop: '50px' }}
          >
            ¡Aun no se han realizado pagos!
          </h1>
          <span className="estado-cuenta">
            Una vez realizado algún pago podrás ver tus estados de cuenta aquí.
          </span>
        </>
      )
    }

  };


  return (
    <div>
      <HeaderDashboard />
      <div className="dashboard__container">
        <div className="dashboard-user__header">
          <div>
            <h1>Tus estados de cuenta</h1>
            <span style={{ marginTop: '10px' }}>al {today}</span>
          </div>
        </div>
        {loading ? (
          <div style={{ width: '50px', marginTop: '100px' }}>
            <Oval />
          </div>
        ) :
          approvalProcess()



        }
      </div>
    </div>
  );
}
