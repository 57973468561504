export class Amortizacion {

  interest_amount: number;
  amount_payable: number;
  commission: number;
  iva_commission: number;
  capital_payment: number;
  concepto: string;

}

export class ArmotizacionReq {
  form_of_payment: number;
  dia: number;
  pago: number;
  saldoInsoluto: number;
  plazo: number;
  score: number;
}

export class PersonalLoan {
  loan_amount: number;
  interest_amount: number;
  total_amount: number;
  payment_breakdown: Array<LoanBreakdownDTO>;

}

export class LoanBreakdownDTO {

  payment_number: number;
  payment_date: string;
  concept: string;
  begining_of_period: number;
  capital_payment: number;
  interest_amount: number;
  commission: number;
  iva_commission: number;
  amount_payable: number;
  end_of_period: number;
  paymentWasMade: boolean;
  date_make_payment: string;

}

export class PersonalLoanDTO {

  loan_amount: number; // prestamo
  number_term_payment: number;//numero de pagos a realizar
  form_of_payment: number;//1 = mensual, 2 = quincenal. 3 = semanal
  score: number;

}


export class CreditLoanService {

  getLoanBreakdown(loan: PersonalLoanDTO): PersonalLoan {

    let breakdown: Array<LoanBreakdownDTO> = new Array<LoanBreakdownDTO>();

    // calulo importe
    // estos ignoralos ya no van en el proceso
    /*
    var tasa_iva = 0.1547;
    var result: number = 0;
    var suma_iva: number = 1 + tasa_iva;
    result = (loan.loan_amount * tasa_iva) / (1 - (Math.pow(suma_iva, -loan.number_term_payment)));
    var redondeo: number = Math.round(result);
    */

    var plazo: number = 0;

    if (loan.form_of_payment === 2) {
      plazo = loan.number_term_payment * 2;
    } else if (loan.form_of_payment === 3) {
      plazo = loan.number_term_payment * 4.3;
    }

    var redondeo_plazo = Math.round(plazo);

    var fecha: Date = new Date();

    var diaPrestamo = fecha.getDate();

    var begining_of_period: number = loan.loan_amount;

    var interest_amount: number = 0;

    // por el momento algunos datos van fijos para la prueba
    // despues se obtendran en el tramite y consulta de score de usuario
    let armReq = new ArmotizacionReq();
    armReq.form_of_payment = loan.form_of_payment; // indica el plazo en semanas(3), 2 = pago quinsenal
    armReq.dia = diaPrestamo; // fecha en que se hace la autorizacion
    armReq.pago = 0; // por el momento esto no indica nada, ya que quedo todo del lado de metodo de amortizacion.
    armReq.saldoInsoluto = loan.loan_amount; // monto solicitado por el usaurio
    armReq.plazo = redondeo_plazo; // pendiente rescatar segun el mes y pago sea quincenal o semanal
    armReq.score = loan.score; // pendiente rescatar en solicitud


    //Setear fecha de pago y numero de pagos a realizar
    let tbl_amortizacion: Array<Amortizacion> = this.getAmortizacionpago(armReq);
    var cont = 1;
    for (const iterator of tbl_amortizacion) {
      let dto = new LoanBreakdownDTO();

      // Obtener fecha pago
      // la forma de pago en plazos se obtendra de la solicitud
      // por el momento esta por default mensual, despues se modificara segun la vista.
      if (loan.form_of_payment === 1) {
        fecha = this.paymentDate(fecha, diaPrestamo);
      } else if (loan.form_of_payment == 2) {
        fecha = this.paymentDateFortnightly(fecha);
      } else if (loan.form_of_payment == 3) {
        fecha = this.paymentDateWeekly(fecha);
      }

      dto.payment_date = new Date(fecha).toISOString();
      dto.concept = iterator.concepto;
      dto.payment_number = cont++;
      dto.begining_of_period = begining_of_period;
      dto.capital_payment = iterator.capital_payment;
      dto.interest_amount = iterator.interest_amount;
      dto.commission = iterator.commission;
      dto.iva_commission = iterator.iva_commission;
      dto.amount_payable = iterator.amount_payable;
      dto.paymentWasMade = false;


      begining_of_period = Number((begining_of_period - iterator.capital_payment).toFixed(2));

      interest_amount = Number((interest_amount + iterator.interest_amount).toFixed(2));

      if (begining_of_period < 0) {
        dto.end_of_period = 0;
      } else {
        dto.end_of_period = begining_of_period;
      }

      breakdown.push(dto);

    }

    let personal_Loan = new PersonalLoan();

    personal_Loan.loan_amount = loan.loan_amount;
    personal_Loan.interest_amount = interest_amount;
    personal_Loan.total_amount = Number((loan.loan_amount + interest_amount).toFixed(2));
    personal_Loan.payment_breakdown = breakdown;

    return personal_Loan;

  }

  getAmortizacionpago(req: ArmotizacionReq): Array<Amortizacion> {

    let tbl_amortizacion: Array<Amortizacion> = new Array<Amortizacion>();
    var tasa_anual: number = 0;

    // nivel B de usuario
    if (req.score >= 645 && req.score <= 720) {
      tasa_anual = 1.10;
      //nivel A
    } else if (req.score >= 721) {
      tasa_anual = 0.70;
      // nivel C
    } else {
      tasa_anual = 1.50;
    }

    var tasa_neta: number = 0;

    // obtener limite de 6 decimales.
    // pago quincenal
    if (req.form_of_payment === 2) {
      tasa_neta = tasa_anual / 24 * 1.16;

      //pago semanal
    } else if (req.form_of_payment === 3) {
      tasa_neta = tasa_anual / 52 * 1.16;
    }

    var redondeo_tasa = Number(tasa_neta.toFixed(6));

    var operacion_tasa = 1 + redondeo_tasa;
    // aplicando formula pc = monto * ((tasa neta * ((1+tasa neta))^pazo) / (((1+tasa neta)^pazo)-1))
    var pago = req.saldoInsoluto * ((redondeo_tasa * (Math.pow(operacion_tasa, req.plazo))) / ((Math.pow(operacion_tasa, req.plazo) - 1)));

    var redondeo_pago = Number(pago.toFixed(2));

    var comision = req.saldoInsoluto * 0.05 / req.plazo;
    var redondeo_comison = Number(comision.toFixed(2));
    var iva_comision = Number(redondeo_comison * 0.16).toFixed(2);

    var pago_pendiente: number = req.saldoInsoluto;

    for (let index = 1; index <= req.plazo; index++) {

      var interes = pago_pendiente * redondeo_tasa;
      var redondeo_interes = Number(interes.toFixed(2));

      var pago_capital = Number((redondeo_pago - interes).toFixed(2));
      var redondeo_pago_capital = Number(pago_capital.toFixed(2));

      var total_pago: number = Number((redondeo_pago_capital + redondeo_interes + redondeo_comison + Number(iva_comision)).toFixed(2));
      pago_pendiente = Number((pago_pendiente - redondeo_pago_capital).toFixed(2));

      if (pago_pendiente < 0) {
        pago_pendiente = 0;
      }

      let armotizacionDTO = new Amortizacion();

      armotizacionDTO.interest_amount = redondeo_interes;
      armotizacionDTO.commission = redondeo_comison;
      armotizacionDTO.iva_commission = Number(iva_comision);
      armotizacionDTO.amount_payable = total_pago;
      armotizacionDTO.capital_payment = redondeo_pago_capital;
      armotizacionDTO.concepto = 'Pago ' + index + ' de ' + req.plazo;

      tbl_amortizacion.push(armotizacionDTO);

    }

    return tbl_amortizacion;

  }


  calculoArmotizacionMensual(req: ArmotizacionReq): Amortizacion {

    let armotizacionDTO = new Amortizacion();

    var tasa_C_IVA = 0.1547;

    var calculo_interes: number = 0;
    calculo_interes = req.saldoInsoluto * tasa_C_IVA;
    var redondeo_interes: number = Math.round(calculo_interes);

    var abono_capital: number = req.pago - calculo_interes;

    armotizacionDTO.interest_amount = redondeo_interes
    armotizacionDTO.amount_payable = req.pago;
    armotizacionDTO.capital_payment = Math.round(abono_capital);

    return armotizacionDTO;

  }

  paymentDate(fechaPago: Date, dia: number): Date {

    var fecha = fechaPago;

    //obtener el numero de dias trascuridos para el proceso de pago
    var dias: number = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0).getDate();

    //calcular la fecha a realizar el pago:
    let monthEnMilisegundos = 1000 * 60 * 60 * 24 * dias;

    // sumatoria para obtener fecha pago
    let suma = fecha.getTime() + monthEnMilisegundos;
    // obtenemos la fecha
    let fechaPorMeses = new Date(suma);


    // si dia de pago = domingo
    var day = fechaPorMeses.getDay();
    if (day == 0) {
      // si cae domingo pasamos al dia siguiente.
      var aumentar_dia = dias + 1;
      monthEnMilisegundos = 1000 * 60 * 60 * 24 * aumentar_dia;
      suma = fecha.getTime() + monthEnMilisegundos;
      fechaPorMeses = new Date(suma)

    }

    return fechaPorMeses;

  }

  paymentDateFortnightly(fechaPago: Date): Date {

    var fecha = fechaPago;

    var dias: number = 15;

    // calculo para proximo pago de 30 dias
    let monthEnMilisegundos = 1000 * 60 * 60 * 24 * dias;

    var month: number = fecha.getMonth();

    let suma = fecha.getTime() + monthEnMilisegundos;

    let fechaPorFortnightly = new Date(suma);

    // si dia de pago = domingo
    var day = fechaPorFortnightly.getDay();
    if (day == 0) {
      // si cae domingo pasamos al dia siguiente.
      var aumentar_dia = dias + 1;
      monthEnMilisegundos = 1000 * 60 * 60 * 24 * aumentar_dia;
      suma = fecha.getTime() + monthEnMilisegundos;
      fechaPorFortnightly = new Date(suma)

    }

    return fechaPorFortnightly;
  }

  paymentDateWeekly(fechaPago: Date): Date {

    var fecha = fechaPago;

    var dias: number = 7;

    // calculo para proximo pago de 30 dias
    let monthEnMilisegundos = 1000 * 60 * 60 * 24 * dias;

    var month: number = fecha.getMonth();

    let suma = fecha.getTime() + monthEnMilisegundos;

    let fechaPorSemana = new Date(suma);

    // si dia de pago = domingo
    var day = fechaPorSemana.getDay();
    if (day == 0) {
      // si cae domingo pasamos al dia siguiente.
      var aumentar_dia = dias + 1;
      monthEnMilisegundos = 1000 * 60 * 60 * 24 * aumentar_dia;
      suma = fecha.getTime() + monthEnMilisegundos;
      fechaPorSemana = new Date(suma)

    }

    return fechaPorSemana;

  }

}

export default CreditLoanService;
