import {Oval, useLoading} from '@agney/react-loading';
import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import check_mark from '../../../assets/check-mark.png';
import {TramiteCreditoContext} from '../../../context/TramiteCreditoProvider';
import {UserContext} from '../../../context/UserProvider';
import HeaderDashboard from '../headerDashboardNomina.component';

function RequestForm5() {
  const history = useHistory();

  const {credito, updateCredito} = React.useContext(TramiteCreditoContext);
  const {userMain, updateUser} = React.useContext(UserContext);

  console.log(credito, userMain);

  if (!userMain.userId) {
    // history.push("/"); // TODO
  }

  React.useEffect(() => {
    setTimeout(() => {
      history.push('/');
    }, 5000);
  });

  const checkLoan = () => {
    const userId = userMain.userId;
    fetch(process.env.REACT_APP_URL_API + '/user-loan/' + userId, {
      method: 'GET',
    })
      .then(res => res.json())
      .then(data => {
        console.log(data);

        if (data && data.length) {
          console.log(data);
          const dataScore = data[0].calculo_metadata;
          console.log(dataScore);

          if (dataScore.status_riesgo !== 'knockout') {
            updateCredito({
              ...credito,
              limitAmountCredit: dataScore.max_amount,
              limitTermCredit: dataScore.max_term,
              tasa: dataScore.tasa_amortizacion,
            });

            history.push('/credito-config-nomina');
          } else {
            history.push('/solicitud-no-aprobada-nomina');
          }
        } else {
          history.push('/step4-nomina');
        }
      });
  };

  const {containerProps, indicatorEl} = useLoading({
    loading: true,
    indicator: <Oval />,
  });
  return (
    <div>
      <HeaderDashboard />
      <div className="solicitud__container">
        <div className="solicitud-5__form-solicitud">
          <div className="solicitud-5__tabs">
            <Link to="#">
              1. Regístrate
              <span>
                Listo <img src={check_mark} />
              </span>
            </Link>
            <Link to="#" className="active">
              2. Espera la confirmación de tu crédito
            </Link>
          </div>
          <h1>¡Tu solicitud se ha enviado con éxito!</h1>
          <span>
            A continuación, analizaremos tu solicitud. Te llamaremos/ enviaremos
            un correo para darte más detalles.
          </span>
          <div className="animation-container">
            <Oval />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestForm5;
