import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Header from '../header.component';
import Footer from './footer';

const FAQ = () => {
  return (
    <div>
      <Header />
      <div className="faq-content">
        <h1>Preguntas frecuentes</h1>
        <div className="questions-container">
          <div className="pregunta">
            <Accordion>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="stilojm2">
                  ¿Qué requiero para el préstamo?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Requiere de un paquete funerario completamente liquidado o con
                  un adeudo menor a los 2 mil pesos.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="pregunta">
            <Accordion>
              <AccordionSummary
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className="stilojm2">
                  ¿El título tiene que ser a mi nombre?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Es necesario que el título esté a nombre de la persona que
                  tramite el prestamo, el cual tambien puede cambiar de nombre
                  acudiendo a las oficinas de la funeraria.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="pregunta">
            <Accordion>
              <AccordionSummary
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography className="stilojm2">
                  ¿Qué cantidad prestan?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Los prestamos que otorgamos son de los 2 mil a los 7 mil
                  quinientos pesos, con diferentes formas de pago cada uno.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="pregunta">
            <Accordion>
              <AccordionSummary
                aria-controls="panel4a-content"
                id="panel4a-header"
              >
                <Typography className="stilojm2">
                  ¿Qué tasa de interés manejan?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  El interes que manejamos es el más bajo en el mercado con un 3
                  a un 6% mensual esto varia dependiendo la cantidad que
                  solicite y forma de pago.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="pregunta">
            <Accordion>
              <AccordionSummary
                aria-controls="panel5a-content"
                id="panel5a-header"
              >
                <Typography className="stilojm2">
                  ¿Qué documentos necesito para el préstamo?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Requiere el título de servicios funerarios el cual se queda
                  con nosotros en original mientras esté vigente el préstamo,
                  identificado vigente, comprobante de domicilio no mayor a los
                  90 días de impreso, comprobante de ingresos (si no cuenta con
                  nóminas fiscales puede realizar una carta de ingresos donde se
                  especifique a qué se dedica y cuánto son sus ingresos
                  mensuales.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default FAQ;
