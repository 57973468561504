
import "./popup.css";
import {
  Link,
  useHistory,
  useParams,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import Popup from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";

import React, { useEffect, useState } from "react";
import ApiClient from "../../services/apiclient";
import { boolean } from "joi";

import ContratoPABS from "../pages/contratos/contratoPABS";
import ContratoNomina from "../pages/contratos/contratoNomina";
import ContratoPersonal from "../pages/contratos/ContratoPersonal";
import { ProgressCircular } from "../pages/Progress";
import AlertMessage from "../alert.component";
import { TramiteCreditoContext } from "../../context/TramiteCreditoProvider";

export default function PopUp({isPopupVisible, setPopupVisibility}) {

  const history = useHistory();
  const apiClient = ApiClient.getInstance();
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const { credito, updateCredito, deleteCredito } = React.useContext(TramiteCreditoContext);

  

  const contratoRes = {accepted : false};
  const [loading, setLoading] = useState(false);
  const [aceptarAnexos, setaceptarAnexos] = useState(false);
  
  let contrato;
  const [tipoContratro, setTipoContrato] = useState(null);
  

  const [contractInfo, setContractInfo] = useState([]);
  

  
  useEffect(() => {

    creditTypeP();
  }, []);

  const infoContrato = async  () =>{
    const { creditType } = await apiClient.getActiveCredit();
    if(creditType === "pabs"){
      apiClient
      .getContractPabs()
      .then((data) => {
        setContractInfo(data);
        console.log(contractInfo);   
      })
      .catch((error) => {
        setMessage("Esperando aprobación para cargar Tu Contrato.");
        setSeverity("error");
        setOpenMessage(true);
      });
      }else if(creditType === "nomina"){
        apiClient.getContractNomina()
        .then((data) => {
          setContractInfo(data);
          console.log(contractInfo);   
        })
        .catch((error) => {
          setMessage("Esperando aprobación para cargar Tu Contrato.");
          setSeverity("error");
          setOpenMessage(true);
        });
      }else if(creditType ==="personal"){
        apiClient.getContractPersonal()
        .then((data) => {
          setContractInfo(data);
          console.log(contractInfo);   
        })
        .catch((error) => {
          setMessage("Esperando aprobación para cargar Tu Contrato.");
          setSeverity("error");
          setOpenMessage(true);
        });
      }
  }
  const creditTypeP = async ()=>{
    const { creditType } = await apiClient.getActiveCredit();
    console.log('PopUp: ', creditType)
    if(creditType === "nomina"){

     contrato = setTipoContrato('nomina');
     infoContrato();
    }

    if(creditType === "personal"){

      contrato = setTipoContrato('personal');
      infoContrato();
    }

    if(creditType === "pabs"){

      contrato = setTipoContrato('pabs');
      infoContrato();
    }
  }

  const  aceptar  = async () =>{
    console.log('aceptarAnexos: ',aceptarAnexos)
    if(aceptarAnexos == true){
      setLoading(true);
      const { creditType } = await apiClient.getActiveCredit();
  
      contratoRes.accepted = true;
  
  
      if(creditType === "nomina"){
        apiClient.contratoNomina(contratoRes).then(r =>{
  
          history.push('/dashboard2');
          setPopupVisibility(false);
        }).catch(err =>{
          setLoading(false);
          setMessage('Ha ocurido un error al aceptar el contrato.');
          setSeverity('error');
          setOpenMessage(true);
        })
      }
  
      if(creditType === "personal"){
  
        apiClient.contratoPersonal(contratoRes).then(r =>{
      
          history.push('/dashboard2');
          setPopupVisibility(false);
  
        }).catch(err =>{
          setLoading(false);
          setMessage('Ha ocurido un error al aceptar el contrato.');
          setSeverity('error');
          setOpenMessage(true);
        })
  
      }
  
      if(creditType === "pabs"){
  
        apiClient.contratoPABS(contratoRes).then(r =>{
  
          history.push('/dashboard2');
          setPopupVisibility(false);
          
        }).catch(err =>{
          setLoading(false);
          setMessage('Ha ocurido un error al aceptar el contrato.');
          setSeverity('error');
          setOpenMessage(true);
        })
  
      }
    }else{

    }

  };
 

  const rechazar = async  () =>{
    setLoading(true);
    const { creditType } = await apiClient.getActiveCredit();

    contratoRes.accepted = false;

    if(creditType === "nomina"){
      apiClient.contratoNomina(contratoRes).then(r =>{
        deleteCredito();
        history.push('/solicitud-rechazada-aprobada/nomina');
        setPopupVisibility(false);
      }).catch(err =>{
        setLoading(false);
        setMessage('Ha ocurido un error al aceptar el contrato.');
        setSeverity('error');
        setOpenMessage(true);
      })
    }

    if(creditType === "personal"){

      apiClient.contratoPersonal(contratoRes).then(r =>{
        deleteCredito();
        history.push('/solicitud-rechazada-aprobada/personal');
        setPopupVisibility(false);

      }).catch(err =>{
        setLoading(false);
        setMessage('Ha ocurido un error al aceptar el contrato.');
        setSeverity('error');
        setOpenMessage(true);
      })

    }

    if(creditType === "pabs"){

      apiClient.contratoPABS(contratoRes).then(r =>{
        deleteCredito();
        history.push('/solicitud-rechazada-aprobada/pabs');
        setPopupVisibility(false);
        
      }).catch(err =>{
        setLoading(false);
        setMessage('Ha ocurido un error al aceptar el contrato.');
        setSeverity('error');
        setOpenMessage(true);
      })

    }
   
  }
  const onclickDescrgar = async() =>{
    
    const { creditType, id } = await apiClient.getActiveCredit();

    contratoRes.accepted = false;

    if(creditType === "nomina"){
      apiClient.dowloandNomina(id).then(r =>{
        const link = document.createElement('a');
        const url = URL.createObjectURL(r);
        console.log(url);
        link.href = url;
        link.download = 'contrato.pdf';
        link.click();
      }).catch(err=>{
        console.log('err', err)
      })

      apiClient.dowloandNominaAmortizacion(id).then(r =>{
        const link = document.createElement('a');
        const url = URL.createObjectURL(r);
        console.log(url);
        link.href = url;
        link.download = 'tablaAmortizacion.pdf';
        link.click();

      }).catch(err=>{
        console.log('err', err)
      })
    }

    if(creditType === "personal"){

      apiClient.dowloandPersonal(id).then(r =>{
        const link = document.createElement('a');
        const url = URL.createObjectURL(r);
        console.log(url);
        link.href = url;
        link.download = 'contrato.pdf';
        link.click();

      }).catch(err=>{
        console.log('err', err)
      })

      apiClient.dowloandPersonalAmortizavion(id).then(r =>{
        const link = document.createElement('a');
        const url = URL.createObjectURL(r);
        console.log(url);
        link.href = url;
        link.download = 'tablaAmortizacion.pdf';
        link.click();

      }).catch(err=>{
        console.log('err', err)
      })

    }

    if(creditType === "pabs"){

      apiClient.dowloandPabs(id).then(r =>{
        const link = document.createElement('a');
        const url = URL.createObjectURL(r);
        console.log(url);
        link.href = url;
        link.download = 'contrato.pdf';
        link.click();


        
      }).catch(err=>{
        console.log('err', err)
      })

      apiClient.dowloandPabsAmortizacion(id).then(r =>{
        const link = document.createElement('a');
        const url = URL.createObjectURL(r);
        console.log(url);
        link.href = url;
        link.download = 'tablaAmortizacion.pdf';
        link.click();

        
        
      }).catch(err=>{
        console.log('err', err)
      })

    }
  }
  const handleChange = () => {
    setaceptarAnexos(!aceptarAnexos);
  };
  const handleClose = () => {
    setOpenMessage(false);
  };
  return (
    <div>
       <AlertMessage
            open={openMessage}
            message={message}
            handleClose={handleClose}
            severity={severity}
          />
      <Popup
        visible={isPopupVisible}
        closeOnOutsideClick={false}
        width={1000}
        height={350}
        showTitle={false}
        onHiding={aceptar}
      >
        <ScrollView width="100%" height="100%">
          <div id="textBlock">

          {/* {
            tipoContratro == null
            ?
            null
            : 
            tipoContratro == 'nomina' ? <ContratoNomina contractInfo={contractInfo}/> : 
            tipoContratro == 'personal' ? <ContratoPersonal contractInfo={contractInfo}/> :
            tipoContratro == 'pabs' ?  <ContratoPABS contractInfo={contractInfo}/>: null
          } */}

              <h3 style={{textAlign: 'center', paddingBottom: 20}}>Para continuar necesitar aceptar el contrato.</h3>
              <div className="avisoContent">
              <span style={{ fontSize: 14, marginLeft: 5 }}>
                Probenso Group, Sociedad Anónima, Sociedad Financiera  de Objeto Múltipe, Entidad No Regulada (E.N.R.), con domicilio en Ures Sur 137, Colonia Centro, Tepic, 63000, Nayarit, México es el responsable de sus datos personales.Utilizaremos tus datos personales para los siguientes fines indispensables: (i) Crear tu cuenta de usuario en la plataforma alojada en https://percapita.mx/; (ii) Identificarte como usuario de la plataforma alojada en https://percapita.mx/; (iii) Generar el expediente de solicitud de tu crédito; (iv) Consultar tu historial y capacidad crediticia;  (v) Decidir si se aprueba o rechaza tu solicitud  de crédito, y; (vi) Firmar los contratos de crédito que sean aprobados.Para conocer el aviso de privacidad integral, por favor haz <a href="/aviso-de-privacidad" target="_blank" style={{ fontSize: 14, marginLeft: 5 }}>click aquí.</a>
              </span>

              </div>
              <div className="avisoContent" style={{marginTop: 20}}>
              <label  style={{ marginLeft: 5}}>
                <input style={{float: 'left', width: '10px'}} type="checkbox"checked={aceptarAnexos}
                onChange={handleChange}
                />
                <span> Confirmo que he leído y me obligo a cumplir con el </span>
              </label>
                <a onClick={onclickDescrgar} style={{textDecoration: 'underline', cursor: 'pointer', paddingLeft: '5px'}}>Contrato de Crédito.</a>
              </div>
            <div className="b__bottom2 button-center">
            <button className="btn blue marginAviso" type="submit" onClick={aceptar} disabled={loading}>
                  <ProgressCircular loading={loading} title='Aceptar'/>
                  </button>
                  <button className="btn blue" type="submit" onClick={rechazar} disabled={loading}>
                    Rechazar 
                  </button>
         

            </div>
          </div>
        </ScrollView>
      </Popup>

    </div>
  );
}
