import { ContactSupportOutlined } from "@material-ui/icons";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import ApiClient, { Payment } from "../../services/apiclient";
import AlertMessage from "../alert.component";
import HeaderDashboard from "../headerDashboard.component";
import { Grid, Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import { Console } from "console";
import Popup from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import { ProgressCircular } from "../pages/Progress";

export default function Dashboard2() {
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [nextPayment, setNextPayment] = useState("");
  const [nextTotal, setNextTotal] = useState(0);
  const [loanAmmount, setLoanAmmount] = useState(0);
  const [loanPayments, setLoanPayments] = useState(0);
  const [amortizacion, setAmortizacion] = useState<Payment[]>([]);
  const [payed, setPayed] = useState(0);
  const [paymentMethod, setPaymetMethod] = useState("check");
  const [idBanco, setIdBanco] = useState("");
  const [clave, setClave] = useState("");
  const [tarjeta, setTarjeta] = useState("");
  const [contrato, setcontrato] = useState("");
 
  
  const [bancos, setBancos] = useState([]);
  const history = useHistory();

  const apiClient = ApiClient.getInstance();

  const handleChange = (event) => {
    setPaymetMethod(event.target.value);
  };

  const handleClose = () => {
    setOpenMessage(false);
  };

  const [loadingPage, setLoadingPage] = useState(true);

  const getEstatusLoan = async () => {
    apiClient
      .getValidationTerms()
      .then((resp) => {
        const {
          acceptedTermAndPrivacy,
          userDetails,
          loanActive,
          acceptedContract,
        } = resp;
        console.log("Dashboard - getValidationTerms: ", resp);
        if (loanActive == true && acceptedContract == true) {
          setLoadingPage(false);
          apiClient
            .getActiveCredit()
            .then((data) => {
              console.log(data);
              setcontrato(data.creditType);
              apiClient
                .getTablaAmortizacion(data.creditType, data.id)
                .then((table) => {
                  console.log(table);
                  const date = new Date(table[0].paymentDate);
                  setNextPayment(date.toLocaleDateString());
                  setNextTotal(table[0].amountPayable);
                  setLoanAmmount(table[0].cumulativeTotal);

                  const payments = table.filter(
                    (payment) => payment.paidOut === false
                  );
                  setLoanPayments(payments.length);
                  setAmortizacion(table);

                  setPayed(
                    table.filter((payment) => payment.paidOut === true).length
                  );
                })
                .catch((err) => console.error(err));
            })
            .catch((err) => {
              history.push("/");
            });
        } else if (!acceptedContract) {
          history.push("/proceso-credito");
        }
      })
      .catch((err) => {
        if (err.message.includes("Entity not found: User with id")) {
          localStorage.clear();
          history.push("/login");
        }

        if (err.status === 401) {
          localStorage.clear();
          history.push("/login");
        }
      });
  };

  useEffect(() => {
    getEstatusLoan();
  }, []);

  const renderRows = amortizacion.map((row) => (
    <tr key={row.id}>
      <td>{moment(row.paymentDate).format("DD/MM/YYYY")}</td>
      <td>{row.paymentNumber}</td>
      <td>{row.concept}</td>
      <td>{row.amountPayable}</td>
      <td>{row.cumulativeTotal}</td>
      <td>{row.paidOut === true ? "Pagado" : "Pendiente de pago"}</td>
    </tr>
  ));

    const [isPopupVisible, setPopupVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const validateCuentaBancaria = (banckInfo: any) =>{
    const {cuentaBeneficiario, institucionContraparte, isClave} = banckInfo;
    if(cuentaBeneficiario == null || cuentaBeneficiario == '' || (isClave ? (cuentaBeneficiario.length <18 || cuentaBeneficiario.length >20):(cuentaBeneficiario.length <16 || cuentaBeneficiario.length >16) ) || institucionContraparte == null || institucionContraparte == '' ){
      setMessage('Favor de completar la información.');
      setSeverity('error');
      setOpenMessage(true);
      return false;
    }
    return true;
  }
  const handleBanckInfo = () => {
    console.log('click');
    console.log("contrato", contrato,
    "metodo", paymentMethod,"institucion", idBanco,
   "clave", clave,"tarjeta", tarjeta);
    if(contrato==='pabs'){
      if(paymentMethod === "check"){
        let backInfo = {
          "isCheque":true
        }
        apiClient.pabsBankInfo(backInfo)
         .then(data => {
          setMessage('Informacion guardada correctamente..');
          setSeverity('success');
          setOpenMessage(true);
           console.log(data);
        } ).catch(error => {
          setMessage('Ha ocurrido un error al guardar la información.');
          setSeverity('error');
          setOpenMessage(true);
        });

      } else {
        const banckInfo = {      
          "cuentaBeneficiario":  clave.length > 0 ? (clave):(tarjeta),
          "institucionContraparte": idBanco,
          "isClave": clave.length > 0 ? (true):(false),
          "isCheque" : false
        }
        if(validateCuentaBancaria(banckInfo) == false){
          return;
        }
        // if(avisoDePrivacidad == false){
        //   setOpenMessage(true);
        //   setMessage("Para poder continuar necesitas aceptar el aviso de privacidad.");
        //   setSeverity("info");
        //   return;
        // }
         apiClient.pabsBankInfo(banckInfo)
         .then(data => {
          console.log(data);
          setMessage('Informacion guardada correctamente..');
          setSeverity('success');
          setOpenMessage(true);
          setPopupVisibility(false);
       } ).catch(error => {
        setMessage('Ha ocurrido un error al guardar la información.');
        setSeverity('error');
        setOpenMessage(true);
       });
        }
      } else if(contrato==='nomina'){
      if(paymentMethod === "check"){
        let backInfo = {
          "isCheque":true
        }
        apiClient.nominaBankInfo(backInfo)
        .then(data => {
          console.log(data);
          setMessage('Informacion guardada correctamente..');
          setSeverity('success');
          setOpenMessage(true);
       } ).catch(error => {
        setMessage('Ha ocurrido un error al guardar la información.');
        setSeverity('error');
        setOpenMessage(true);
       });

      } else {
        const banckInfo = {      
          "cuentaBeneficiario":  clave.length > 0 ? (clave):(tarjeta),
          "institucionContraparte": idBanco,
          "isClave": clave.length > 0 ? (true):(false),
          "isCheque" : false
        }
        console.log(banckInfo);
        if(validateCuentaBancaria(banckInfo) == false){
          return;
        }
        // if(avisoDePrivacidad == false){
        //   setOpenMessage(true);
        //   setMessage("Para poder continuar necesitas aceptar el aviso de privacidad.");
        //   setSeverity("info");
        //   return;
        // }
         apiClient.nominaBankInfo(banckInfo)
         .then(data => {
          setMessage('Informacion guardada correctamente..');
          setSeverity('success');
          setOpenMessage(true);
          setPopupVisibility(false);
          console.log(data);
       } ).catch(error => {
        setMessage('Ha ocurrido un error al guardar la información.');
        setSeverity('error');
        setOpenMessage(true);
       });
        }
      }else if(contrato==='personal'){
      if(paymentMethod === "check"){
        let backInfo = {
          "isCheque":true
        }
        apiClient.personalBankInfo(backInfo)
        .then(data => {
          setMessage('Informacion guardada correctamente..');
          setSeverity('success');
          setOpenMessage(true);
          console.log(data);
       } ).catch(error => {
        setMessage('Ha ocurrido un error al guardar la información.');
        setSeverity('error');
        setOpenMessage(true);
       });
      } else {
        const banckInfo = {      
          "cuentaBeneficiario":  clave.length > 0 ? (clave):(tarjeta),
          "institucionContraparte": idBanco,
          "isClave": clave.length > 0 ? (true):(false),
          "isCheque" : false
        }
        if(validateCuentaBancaria(banckInfo) == false){
          return;
        }
        // if(avisoDePrivacidad == false){
        //   setOpenMessage(true);
        //   setMessage("Para poder continuar necesitas aceptar el aviso de privacidad.");
        //   setSeverity("info");
        //   return;
        // }
         apiClient.personalBankInfo(banckInfo)
         .then(data => {
          setMessage('Informacion guardada correctamente..');
          setSeverity('success');
          setOpenMessage(true);
          setPopupVisibility(false);
          console.log(data);
       } ).catch(error => {
        setMessage('Ha ocurrido un error al guardar la información.');
        setSeverity('error');
        setOpenMessage(true);
       });
        }
      }
  };
  const [avisoDePrivacidad, setavisoDePrivacidad] = useState(false);
  const handleChangeAviso = () => {
    setavisoDePrivacidad(!avisoDePrivacidad);
  };

  return (
    <div>
      <Popup
        visible={isPopupVisible}
        closeOnOutsideClick={false}
        width={1000}
        height={340}
        showTitle={false}
      >
        <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <ScrollView width="100%" height="100%">
        <div className="avisoContent">
        Probenso Group, Sociedad Anónima, Sociedad Financiera de Objeto Múltipe, Entidad No Regulada (E.N.R.), con domicilio en Ures Sur 137, Colonia Centro, Tepic, 63000, Nayarit, México es el responsable de sus datos personales.Utilizaremos tus datos personales para los siguientes fines indispensables: (i) Crear tu cuenta de usuario en la plataforma alojada en https://percapita.mx/; (ii) Identificarte como usuario de la plataforma alojada en https://percapita.mx/; (iii) Generar el expediente de solicitud de tu crédito; (iv) Consultar tu historial y capacidad crediticia; (v) Decidir si se aprueba o rechaza tu solicitud de crédito, y; (vi) Firmar los contratos de crédito que sean aprobados.Para conocer el aviso de privacidad integral, por favor haz <a href="/aviso-de-privacidad" target="_blank" style={{fontSize: 14, marginLeft: 5}}>click aquí.</a>
        <div className="solicitud-b__bottom button-center">
          <button className="btn blue marginAviso" type="submit" onClick={() => setPopupVisibility(false)} disabled={loading}>
            Cerrar 
          </button>
          <button className="btn blue" type="submit" onClick={handleBanckInfo} disabled={loading}>
            <ProgressCircular loading={loading} title='Aceptar'/> 
          </button>
        </div>
        </div>
      </ScrollView>
      </Popup>
      {loadingPage == true ? null : (
        <>
          <HeaderDashboard />
          <AlertMessage
            open={openMessage}
            message={message}
            handleClose={handleClose}
            severity={severity}
          />
          <div className="dashboard__container">
            <div className="dashboard-user__header">
              <div className="name-date">
                <h1>¡Hola! Bienvenido a tu espacio</h1>
                <span>{moment().format("LL")}</span>
              </div>
              <div className="dashboard-usuario-1__header-banner-en-proceso">
                <div>
                  <i className="far fa-calendar-alt"></i>
                  <label> Próxima fecha de pago: </label>
                  <span>{nextPayment}</span>
                </div>
                <div>
                  <i className="fas fa-exclamation-triangle"></i>
                  <label>Pago para no generar intereses:</label>
                  <span>${nextTotal} </span>
                </div>
              </div>
            </div>
            <div className="dashboard-usuario-1__content">
              <div className="dashboard-usuario-1__card">
                <h2>¡Felicidades por tu aprobación de crédito!</h2>

                <span>¿A dónde quieres que te depositemos tu crédito?</span>

                <Grid container justify="center">
                  <RadioGroup
                    row
                    aria-label="payment"
                    name="payment"
                    value={paymentMethod}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="check"
                      control={<Radio color="primary" />}
                      label="Recoger Cheque"
                    />
                    <FormControlLabel
                      value="transfer"
                      control={<Radio color="primary" />}
                      label="Transferencia electronica"
                    />
                  </RadioGroup>
                </Grid>
                {paymentMethod === "transfer" && (
                  <div className="dashboard__container">
                    <div className="dashboard-banck__cards">
                      <label>Institución bancaria</label>
                      <select
                        className="input-select"
                        onChange={(e) => setIdBanco(e.target.value)}
                      >
                        <option value="0">Seleccione un banco</option>
                        {instituciones.map((banco: any) => (
                          <option value={banco.code}>{banco.label}</option>
                        ))}
                      </select>
                    </div>
                    <div className="dashboard-banck__cards-container">
                      <div className="dashboard-banck__cards">
                        <label>Cuenta bancaria</label>
                        <input
                          minLength={18}
                          maxLength={20}
                          placeholder="CLABE interbancaria"
                          disabled={tarjeta.length > 0 ? true : false}
                          type="text"
                          id="clabe"
                          name="clabe"
                          onChange={(e) => setClave(e.target.value)}
                        />
                      </div>
                      <div className="dashboard-banck__cards">
                        <label>Número de tarjeta</label>
                        <input
                          minLength={16}
                          maxLength={16}
                          placeholder="12348598798"
                          type="text"                    
                          disabled={clave.length > 0 ? true : false}
                          id="tarjeta"
                          name="tarjeta"
                          onChange={(e) => setTarjeta(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* <div>
                        <label>
                          <input style={{float: 'left', width: '10px'}} type="checkbox"
                          checked={avisoDePrivacidad}
                          onChange={handleChangeAviso}
                          />
                        </label>
                        <span style={{fontSize: 14, marginLeft: 5}}>
                          He leído y acepto el
                          <a href="/aviso-de-privacidad" target="_blank" style={{fontSize: 14, marginLeft: 5}}>AVISO DE PRIVACIDAD</a>
                          .
                        </span>
                    </div> */}
                  </div>
                )}
                <button className="blue center-div"  onClick={() =>setPopupVisibility(true) }
                >Continuar</button>
              </div>
              <div className="dashboard-usuario-1__small-cards-container">
                <div className="dashboard-usuario-1__small-cards">
                  <h3>${loanAmmount}</h3>
                  <span>Préstamo total</span>
                </div>
                {/* <div className="dashboard-usuario-1__small-cards">
              <h3>${credito.importe_interes}</h3>
              <span>Intereses totales a pagar</span>
                      </div> */}
                <div className="dashboard-usuario-1__small-cards">
                  <h3>{loanPayments} pagos</h3>
                  <span>Pendientes</span>
                </div>
              </div>
              <progress max={loanAmmount} value={0}></progress>
              <div className="progress-labels">
                <span>$0</span>
                <span>${0} </span>
              </div>
              <span>
                <b>
                  {payed} de {amortizacion.length} plazos pagados
                </b>
              </span>
              <div className="tabla-pagos">
                <h4>Desgloce de pagos</h4>
                <table className="blueTable">
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>N&uacute;mero</th>
                      <th>Concepto</th>
                      <th>Cantidad a pagar</th>
                      <th>Monto total acumulado</th>
                      <th>Estatus</th>
                    </tr>
                  </thead>
                  <tfoot></tfoot>
                  <tbody>{renderRows}</tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const instituciones = [
  { code: "2001", label: "BANXICO" },
  { code: "37006", label: "BANCOMEXT" },
  { code: "37009", label: "BANOBRAS" },
  { code: "37019", label: "BANJERCITO" },
  { code: "37135", label: "NAFIN" },
  { code: "37166", label: "BANSEFI" },
  { code: "37168", label: "HIPOTECARIA FED" },
  { code: "40002", label: "BANAMEX" },
  { code: "40012", label: "BBVA BANCOMER" },
  { code: "40014", label: "SANTANDER" },
  { code: "40021", label: "HSBC" },
  { code: "40030", label: "BAJIO" },
  { code: "40036", label: "INBURSA" },
  { code: "40042", label: "MIFEL" },
  { code: "40044", label: "SCOTIABANK" },
  { code: "40058", label: "BANREGIO" },
  { code: "40059", label: "INVEX" },
  { code: "40060", label: "BANSI" },
  { code: "40062", label: "AFIRME" },
  { code: "40072", label: "BANORTE" },
  { code: "40103", label: "AMERICAN EXPRES" },
  { code: "40106", label: "BANCK OF AMERICA" },
  { code: "40108", label: "MUFG" },
  { code: "40110", label: "JP MORGAN" },
  { code: "40112", label: "BMONEX" },
  { code: "40113 ", label: "VE POR MAS" },
  { code: "40124", label: "DEUTSCHE" },
  { code: "40126", label: "CREDIT SUISSE" },
  { code: "40127", label: "AZRECA" },
  { code: "40128", label: "AUTOFIN" },
  { code: "40129", label: "BARCLAYS" },
  { code: "40130", label: "COMPARTAMOS" },
  { code: "40132", label: "MULTIVA BANCO" },
  { code: "40133", label: "ACTINVER" },
  { code: "40136", label: "INTERCAM BANCO" },
  { code: "40137", label: "BANCOPPEL" },
  { code: "40138", label: "ABC CAPITAL" },
  { code: "40140", label: "CONSUBANCO" },
  { code: "40141", label: "VOLKSWAGEN" },
  { code: "40143", label: "CIBANCO" },
  { code: "40145", label: "BBASE" },
  { code: "40147", label: "BANKAOOL" },
  { code: "40148", label: "PAGATODO" },
  { code: "40150", label: "INMOBILIARIO" },
  { code: "40151", label: "DONDE" },
  { code: "40152", label: "BANCREA" },
  { code: "40154", label: "BANCO FINTERRA" },
  { code: "40155", label: "ICBC" },
  { code: "40156", label: "SABADELL" },
  { code: "40157", label: "SHINHAN" },
  { code: "40158", label: "MIZUHO BANK" },
  { code: "40160", label: "BANCO S3" },
  { code: "90600", label: "MONEXCB" },
  { code: "90601", label: "GBM" },
  { code: "90602", label: "MASARI" },
  { code: "90605", label: "VALUE" },
  { code: "90606", label: "ESTRUCTURADORES" },
  { code: "90608", label: "VECTOR" },
  { code: "90613", label: "MULTIVA CBOLSA" },
  { code: "90616", label: "FINAMEX" },
  { code: "90617", label: "VALMEX" },
  { code: "90620", label: "PROFUTURO" },
  { code: "90630", label: "CB INTERCAM" },
  { code: "90631", label: "CI BOLSA" },
  { code: "90634", label: "FINCOMUN" },
  { code: "90636", label: "HDI SEGUROS" },
  { code: "90638", label: "AKAL" },
  { code: "90642", label: "REFORMA" },
  { code: "90646", label: "STP" },
  { code: "90648", label: "EVERCORE" },
  { code: "90652", label: "CREDICAPITAL" },
  { code: "90653", label: "KUSPIT" },
  { code: "90656", label: "UNAGRA" },
  { code: "90659", label: "ASP INTEGA OPC" },
  { code: "90670", label: "LIBERTAD" },
  { code: "90677", label: "CAJA POP MEXICA" },
  { code: "90680", label: "CRISTOBAL COLON" },
  { code: "90683", label: "CAJA TELEFONIST" },
  { code: "90684", label: "TRANSFER" },
  { code: "90685", label: "FONDO (FIRA)" },
  { code: "90686", label: "INVERCAP" },
  { code: "90689", label: "FOMPED" },
  { code: "90902", label: "INDEVAL" },
];
