import Header from '../header.component';

const PAS = () => {
  return (
    <div className="pasos-a-seguir">
      <Header />
      <div className="solicitud-1__form-solicitud solicitud-1__form-solicitud2">
        <h1>
          Pasos a seguir <br /> para solicitar tu crédito
        </h1>
        <div className="pasos-container">
          <div className="paso">
            <h4>Paso 1</h4>
            <span>Crea tu cuenta o inicia sesión</span>
          </div>
          <i className="fas fa-long-arrow-alt-right"></i>
          <div className="paso">
            <h4>Paso 2</h4>
            <span>Llena tus datos</span>
          </div>
          <i className="fas fa-long-arrow-alt-right"></i>
          <div className="paso">
            <h4>Paso 3</h4>
            <span>Solicita tu credito</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PAS;
