import "./uploadBox.css";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useState } from "react";

export default function UploadBox({
  image,
  descripton,
  fileSetter,
  fileKey,
  callback
}) {

  /**
   * Function that is executed when the user drops a file into
   * the box container.
   */
  const onDrop = useCallback((acceptedFiles) => {

    fileSetter(acceptedFiles[0])
    setFileName(acceptedFiles[0].name)

    callback();
  }, []);

  const [fileName, setFileName] = useState();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="wrapper">
      <div className="uploadBox" {...getRootProps()}>

        {
          fileName ?
            <strong style={{fontSize: 15}}>{fileName}</strong> :
            <>
              <img src={image} alt="upload icon" />
              <span>
                <strong style={{fontSize: 15}}>Elige un archivo</strong>
                <input {...getInputProps()} /> <br />
                {isDragActive ? (
                  <strong style={{fontSize: 15}}>Dejalo caer aqui</strong>
                ) : (
                  <strong style={{fontSize: 15}}>o arrastralo hasta aqui</strong>
                )}
              </span>
            </>

        }

      </div>
      <strong style={{fontSize: 15}}>{descripton}</strong>
    </div>
  );
}
