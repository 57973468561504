import React, { useState, useEffect } from "react";
import ApiClient from "../../../services/apiclient";
import HeaderDashboard from "../../headerDashboard.component";
import AlertMessage from "../../alert.component";
import Ine from "../../credit-forms/ine-form";
import PersonalForm from "../../credit-forms/personal-form";
import Direccion from "../../credit-forms/direccion-form";
import EmpleoForm from "../../credit-forms/empleo-form";
import { FileUpload } from "../../../interfaces/fileInterace";
import { AddressData, LaborData, PersonalData } from "./forms";
import mapIntoRequest from "./utils";
import { useHistory } from "react-router-dom";
import { ProgressCircular } from "../Progress";
import { TramiteCreditoContext } from "../../../context/TramiteCreditoProvider";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { nextStep, setNomidaId } from "../../../store/actions/PrestamosPersonalesActions";
import PersonalDataForm from "./components/PersonalForm/PersonalForm";
import AddressForm from "./components/AddressForm/AddressForm";
import IneForm from "./components/IneForm/IneForm";
import EmployeesForm from "./components/EmployeesForm/EmployeesForm";
import ReferencesForm from "./components/ReferencesForm/ReferencesForm";
import QuestionPeps from "./components/QuestionPeps/QuestionPeps";
import setStepPersonalCredit from "../../../helpers/setStepPersonalCredit";
import TermsConditionsCDC from "../circulo-credito/term-conditions";

const PrestamoPersonalNuevo = ({
  step,
  nextStep,
  setNomidaId,
}) => {

  const apiClient = ApiClient.getInstance();
  const currentUserId = apiClient.getUserId();
  const currentUserToken = apiClient.getUserToken();

  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [ineFiles, setIneFiles] = useState<FileUpload[]>();
  const [personalData, setPersonalData] = useState<PersonalData>();
  const [addressData, setAddressData] = useState<AddressData>();
  const [laborData, setLaborData] = useState<LaborData>();
  const [personalDataFiles, setPersonalDataFiles] = useState<FileUpload[]>();
  const [stepLoaded, setStepLoaded] = useState(false);

  const history = useHistory();
  const { credito, updateCredito } = React.useContext(TramiteCreditoContext);

  const handleClose = (event, reason) => {
    console.log("handleClose: ");
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  // const personalDataCallback = () => {
  //   console.log("YEEE");
  // };

  // console.log(currentUserId);
  // console.log(currentUserToken);

  // const registerPersonalLoan = () => {
  //   if (
  //     !personalData ||
  //     /* !addressData || */
  //     !laborData ||
  //     !personalDataFiles ||
  //     !ineFiles
  //   ) {
  //     setMessage("Debes completar todos los pasos");
  //     setSeverity("error");
  //     setOpenMessage(true);
  //     return;
  //   }

  //   if (avisoDePrivacidad == false) {
  //     setOpenMessage(true);
  //     setMessage("Para poder continuar necesitas aceptar el aviso de privacidad.");
  //     setSeverity("info");
  //     return;
  //   }

  //   const body = mapIntoRequest(personalData, addressData, laborData);

  //   console.log("body", body);
  //   // setLoading(true);
  //   apiClient
  //     .createPersonalLoan(body)
  //     .then((data) => {
  //       console.log(data);

  //       const formData = new FormData();

  //       formData.append("ifeIne", ineFiles[0].file);
  //       formData.append("selfieIne", ineFiles[1].file);
  //       formData.append("proofAddress", personalDataFiles[0].file);
  //       formData.append("proofIncome", personalDataFiles[1].file);

  //       apiClient
  //         .uploadPersonalLoanFiles(formData, data.id)
  //         .then(async (fileData) => {
  //           const activeCredit = await apiClient.getActiveCredit();
  //           const credit = await apiClient.getCredit(activeCredit.creditType);
  //           updateCredito({
  //             ...credito,
  //             idPrestamo: credit.id,
  //             fecha_solicitud: credit.creationDate,
  //             isDelivered: credit.delivered,
  //             isApproved: credit.status === 'ACCEPTED' ? true : false,
  //             importe: credit.amount,
  //             estatusSolicitud: credit.status === 'IN_PROGRESS' ? 'revision' : credit.status === 'ACCEPTED' ? 'aprobado' : credit.status === 'DENIED' ? 'solicitud-no-aprobada' : 'sin-solicitud'
  //           });
  //           return history.push("/credito-config/personal");
  //         });
  //     })
  //     .catch((err) => {
  //       console.log("errorrxd: ", err.message);
  //       setLoading(false);
  //       setMessage("Ha ocurrido un error");
  //       setSeverity("error");
  //       setOpenMessage(true);
  //     });
  // };

  // const checkActiveCredit = async () => {
  //   const activeCredit = await apiClient.getActiveCredit();
  //   return activeCredit;
  // };



  useEffect(() => {
    //const creditID = checkActiveCredit();
    //if (creditID) history.push("/productos");
    const getPersonalLoad = async () => {
      let response = await apiClient.personalLoan(currentUserId)

      const data = await response.json();

      if (data?.step === null) {
        nextStep("PERSONAL-INFORMATION")
      } else {
        setNomidaId(data?.nominaId);
        nextStep(setStepPersonalCredit(data?.step))
      }
      setStepLoaded(true);
    };

    getPersonalLoad();
  }, [step]);

  const [avisoDePrivacidad, setavisoDePrivacidad] = useState(false);
  const handleChange = () => {
    setavisoDePrivacidad(!avisoDePrivacidad);
  };

  return (
    <div>
      <HeaderDashboard />
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <div className="container" style={{ display: "flex" }}>
          {step !== "CDC" ?  
          <div className="header" style={{ maxWidth: "1000px", textAlign: "center" }}>
            <h3>
              ¡Hola! Bienvenido a percápita. Vamos a iniciar con tu solicitud de
              crédito.
            </h3>
            <span>
              ¡No te preocupes! lo terminaremos en un abrir y cerrar de ojos.
            </span>
          </div> : null
          }

        {!stepLoaded ?
          <ProgressCircular
            loading={true}
            title=""
          />
          :
          <div className="cliente_container">
            {step === "PERSONAL-INFORMATION" ?
              <PersonalDataForm /> : null}

            {step === "ADDRESS" ? <AddressForm /> : null}

            {step === "LABOR-DATA" ? <EmployeesForm /> : null}

            {step === "PERSONAL-REFERENCES" ? <ReferencesForm /> : null}
            
            {step === "CDC" ? <TermsConditionsCDC /> : null}

            {step === "QUESTION-PEPS" ? <QuestionPeps /> : null}

            {step === "FINISHED" ?
              <IneForm
                setMessage={setMessage}
                setOpenMessage={setOpenMessage}
                setSeverity={setSeverity}
              /> : null}

          </div>
        }



          {/* <Direccion setAddress={setAddressData} /> */}

          {/* <EmpleoForm setLabor={setLaborData} />  */}

          {/* <div>
              <label>
                <input style={{float: 'left', width: '10px'}} type="checkbox"
                checked={avisoDePrivacidad}
                onChange={handleChange}
                />
              </label>
              <span style={{fontSize: 14, marginLeft: 5}}>
                He leído y acepto el
                <a href="/aviso-de-privacidad" target="_blank" style={{fontSize: 14, marginLeft: 5}}>AVISO DE PRIVACIDAD</a>
                .
              </span>
          </div>

          <div className="solicitud-b__bottom button-center">
            <h4>*Campos obligatorios</h4>
            <button
              className="btn blue"
              onClick={registerPersonalLoan}
              disabled={loading}
            >
              <ProgressCircular loading={loading} title="Completar mi Perfil" />
            </button>
          </div> */}
      </div>
    </div>
  );
}

const mapStateToProps = ({ personalCreditReducer }) => ({
  step: personalCreditReducer?.step ?? 1,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    nextStep,
    setNomidaId,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PrestamoPersonalNuevo);
