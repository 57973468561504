import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams, GridToolbarExport, GridToolbarContainer  } from '@material-ui/data-grid';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';



const  DataTable = (props: any) =>{
  const columns: GridColDef[] = [
    { field: 'id', 
      headerName: 'id',
      sortable: false,
      filterable:false,
      disableColumnMenu: true,
      align:'center',
      hide: true,
  },
    { field: 'paymentDate', 
      headerName: 'Fecha',
      sortable: false,
      filterable:false,
      disableColumnMenu: true,
      align:'center',
  },
    {
      field: 'paymentNumber',
      headerName: 'Número de pago',
      sortable: false,
      disableColumnMenu: true,
      minWidth:150,
      align:'center',
    },
    {
      field: 'concept',
      headerName: 'Concepto',
      sortable: false,
      disableColumnMenu: true,
      align:'center',
    },
    {
      field: 'amountPayable',
      headerName: 'Cantidad a pagar',
      sortable: false,
      disableColumnMenu: true,
      minWidth:150,
      align:'center',
    },
    {
      field: 'comulativeTotal',
      headerName: 'Total acumulado',
      sortable: false,
      disableColumnMenu: true,
      minWidth:150,
      align:'center',
      //description: 'This column has a value getter and is not sortable.',
      // sortable: false,
      // width: 160,
      // valueGetter: (params: GridValueGetterParams) =>
      //   `${params.getValue(params.id, 'firstName') || ''} ${
      //     params.getValue(params.id, 'lastName') || ''
      //   }`,
    },
    {
      field: 'paidOut',
      headerName: 'Estatus',
      sortable: false,
      disableColumnMenu: true,
      align:'center',
    },
  ];
  
  const rows = [
    { id: 1, paymentDate: 1, paymentNumber: 'Snow', concept: 'Jon', amountPayable: 35,comulativeTotal: 35,paidOut: 35 },
    { id: 2, paymentDate: 2, paymentNumber: 'Lannister', concept: 'Cersei', amountPayable: 42,comulativeTotal: 42,paidOut: 42 },
    { id: 3, paymentDate: 3, paymentNumber: 'Lannister', concept: 'Jaime', amountPayable: 45,comulativeTotal: 45,paidOut: 45 },
    { id: 4, paymentDate: 4, paymentNumber: 'Stark', concept: 'Arya', amountPayable: 16,comulativeTotal: 16,paidOut: 16 },
    { id: 5, paymentDate: 5, paymentNumber: 'Targaryen', concept: 'Daenerys', amountPayable: null,comulativeTotal: null,paidOut: null },
    { id: 6, paymentDate: 6, paymentNumber: 'Melisandre', concept: null, amountPayable: 150,comulativeTotal: 150,paidOut: 150 },
    { id: 7, paymentDate: 7, paymentNumber: 'Clifford', concept: 'Ferrara', amountPayable: 44,comulativeTotal: 44,paidOut: 44 },
    { id: 8, paymentDate: 8, paymentNumber: 'Frances', concept: 'Rossini', amountPayable: 36,comulativeTotal: 36,paidOut: 36 },
    { id: 9, paymentDate: 9, paymentNumber: 'Roxie', concept: 'Harvey', amountPayable: 65,comulativeTotal: 65,paidOut: 65 },
  ];
  const useStyles = makeStyles({
    columnHeader: {
        backgroundColor: '#194b74',
        color: 'white',
      },
      body: {
        fontSize: 12,
        padding: '6px'
      },
  });
  const classes = useStyles();
  React.useEffect(() => {
    props.rows.forEach(row => {
      props.columns.forEach(column => {
        console.log(row,column);
      })
      
    });
  },[]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  return (
 
      <DataGrid
        rows={props.rows}
        columns={props.columns}
        pageSize={10}
        //autoPageSize= {true}
        getRowId={(row: any) => row.ID}
        //className={classes.columnHeader}
        classes={{
          columnHeader: classes.columnHeader
        }}
        autoHeight={true}
        density={'compact'}
        // components={{
        //   Toolbar: CustomToolbar,
        // }}
      />
  );
}

export default DataTable;
