import React, { useEffect, useState } from "react";
import ApiClient from "../../../../../services/apiclient";
import AlertMessage from "../../../../alert.component";
import ImgVector from "../../../../../assets/dark-blue-vector.png";
import "./credit.css";
import { useHistory, useParams } from "react-router-dom";
import { TramiteCreditoContext } from "../../../../../context/TramiteCreditoProvider";
import { UserContext } from "../../../../../context/UserProvider";
import { ProgressCircular } from "../../Progress";
import CustomSlider from "../../../sliderNomina.component";
import CustomDropdown from "../../dashboard/dropdown.nomina.component";

interface RouteParams {
  creditType: "nomina";
}

export default function Informacion({
  nextStep,
}) {
  const history = useHistory();

  const { creditType } = useParams<RouteParams>();

  const { credito, updateCredito } = React.useContext(TramiteCreditoContext);
  const { userMain, updateUser } = React.useContext(UserContext);

  const [loanAmount, setLoanAmount] = useState(0);
  const [pagoQuincenal, setPagoQuincenal] = useState(0);

  //este datos serán dinamicos en base a su perfil
  const [maxLoanAmount, setMaxLoanAmount] = useState(credito.limitAmountCredit); // monto maximo
  const [maxTerm, setMaxTerm] = useState(0); //useState(credito.limitTermCredit); // numero de meses
  const [minTerm, setMinTerm] = useState(0);
  const [defaultValue, setDefaultValue] = useState(0);
  const [step, setStep] = useState(2);
  const [minLoanAmount, setMinLoanAmount] = useState(2000);
  const [tasa, setTasa] = useState(credito.tasa);

  const [term, setTerm] = useState(0);
  const [recurrence, setRecurrence] = useState<string>("Quincenal");
  const [recurrenceDisplay, setRecurrenceDisplay] =
    useState<string>("Quincenas");
  const [loading, setLoading] = useState(false);

  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const [phone, setPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [recommendation, setRecommendation] = useState(null);

  const [idNomina, setIdNomina] = useState(null);

  const [minOriginal, setMinOriginal] = useState(0);
  const [maxOriginal, setMaxOriginal] = useState(0);
  const apiClient = ApiClient.getInstance();
  const [recomendationSemanas, setRecomendationSemanas] = useState(null);

  let isSendCodePhone = false;

  const getUserPreautorizado = () => {
    console.log("getUserPreautorizado");
    const userId = userMain.userId;
    fetch(
      process.env.REACT_APP_URL_API + "/user-recommendation-monto/" + userId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setRecommendation(data);
      })
      .catch((err) => console.error(err));
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    var res = event.target.value as string;
    if (res === "Quincenal") {
      setRecurrenceDisplay("Quincenas");
      setStep(2);
      setMinTerm(minOriginal * 2);
      setMaxTerm(maxOriginal * 2);
    } else {
      setRecurrenceDisplay("Semanas");
      setStep(4);
      setMinTerm(minOriginal * 4);
      setMaxTerm(maxOriginal * 4);
    }
    setRecurrence(res);
    recalcularMonto(res);
  };

  const requestCredit = () => {
    if (userMain.token !== "") {
      return history.push("/step4-nomina");
    } else {
      return history.push("/sin-credito-nomina");
    }
  };

  const handleUpdateLoan = () => {
    console.log("update loan");

    if (creditType === "nomina") {
      if (loanAmount <= 0 && loanAmount < 3000) {
        setOpenMessage(true);
        setMessage(
          "La cantidad de prestamo a solicitar debe ser un minimo de $3000.00. Favor de verificar."
        );
        setSeverity("error");
        return;
      }
    }

    updateLoan();
  };

  const updateLoan = () => {
   // nextStep()
    if (idNomina !== null) {
      setLoading(true);
      const body = {
        amount: loanAmount,
        plazo: term,
        frequencyPayments: recurrence,
      };

      apiClient
        .sendNomina(body)
        .then((resp) => {
          setLoading(false);
          nextStep();
          //history.push("/credit-nomina");
        })
        .catch((err) => {
          
          setLoading(false);
          setOpenMessage(true);
          setMessage(err.message);
          setSeverity("error");
        });
    }
  };

  const sendCodePhone = (phone: string) => {
    const body = {
      phone: phone,
    };

    fetch(process.env.REACT_APP_URL_API + "/verification/phone/resend", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        console.log("code phone enviado ", res);
        // showMessage("Se han guardado los datos de su credito");
        isSendCodePhone = true;
      })
      .catch((err) => {
        console.error(err);
        isSendCodePhone = true;
      });
  };

  function recalcularMonto(recurrence: any) {
    if (recurrence === "mensual") {
      calcularPagoMensual();
    } else if (recurrence === "quincenal") {
      calcularPagoQuincenal();
    } else {
      calcularPagoSemanal();
    }
  }

  function valueAmount(value: number) {
    setLoanAmount(value);

    return `${value}`;
  }
  function valueTerm(value: number) {
    setTerm(value);
    recalcularMonto(recurrence);
    return `${value}`;
  }

  const calcularPagoQuincenal = () => {
    var principal = loanAmount;
    var interest = tasa / 100 / 26; // 26 quiencenas al año
    var x = Math.pow(1 + interest, term);
    var total = (principal * x * interest) / (x - 1);
    setPagoQuincenal(total);
  };

  const calcularPagoSemanal = () => {
    var principal = loanAmount;
    var interest = tasa / 100 / 52; //52 semanas por año
    console.log(interest);

    var x = Math.pow(1 + interest, term); //Math.pow computes powers
    var total = (principal * x * interest) / (x - 1);

    setPagoQuincenal(total);
  };

  const calcularPagoMensual = () => {
    var principal = loanAmount;
    var interest = tasa / 100 / 12; // 12 meses por año
    console.log(interest);

    var x = Math.pow(1 + interest, term); //Math.pow computes powers
    var total = (principal * x * interest) / (x - 1);

    setPagoQuincenal(total);
  };

  // Redirect to /Veriff if user is not verified
  // useEffect(() => {
  //   const checkUserVeriffValidated = async () => {
    
  //     const isValidatedRes = await apiClient.checkUserVeriffValidated();
  //     if(isValidatedRes.veriffValidated !== true) {
  //         history.push('/veriff?Nomina');
  //         return;
  //     }
  
  //   };
  //   checkUserVeriffValidated();
  // }, []);

  useEffect(() => {
    setTerm(parseInt(String(maxTerm / 2), 10));
  }, [credito]);

  useEffect(() => {
    apiClient
      .getEmpleadoNumber()
      .then((res) => {
        setRecomendationSemanas(res.maximum * 4);
        setIdNomina(res.id);
        setMaxLoanAmount(res.maximum * 2);
        setMinLoanAmount(res.minimum * 2);

        setMaxTerm(res.maximum * 2)
        setMinTerm(res.minimum * 2)
        setDefaultValue(res.minimum * 2)

        setMaxOriginal(res.maximum);
        setMinOriginal(res.minimum);
        setRecommendation(res.recommendation);
      })
      .catch((err) => {
        console.log(err);
      });

    calcularPagoQuincenal();
    setPhone(userMain?.telefono);
  }, [loanAmount]);
  useEffect(() => {
    
    if (userMain.esEmpleado) {
      getUserPreautorizado();
    }
  }, [userMain]);
  useEffect(() => {
    setTimeout(() => {
      if (!isSendCodePhone) {
        sendCodePhone(userMain.telefono);
      }
    }, 2000);
  }, []);

  const formatMoney = (value: number) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const showMessage = (message: string, type = "success") => {
    setOpenMessage(true);
    setMessage(message);
    setSeverity(type);
  };

  const handleClose = () => {
    setOpenMessage(false);
  };
  return (
    <div>
      <AlertMessage open={openMessage} />
      
      <div className="solicitud__container">
        <div className="solicitud-b__container">
          <div className="solicitud-credito">
            <h2 style={{ textAlign: "center" }}>
              *En base a tus ingresos se te recomienda solicitar un monto de $
              {new Intl.NumberFormat("en-US").format(recommendation)} a{" "}
              {recomendationSemanas} semanas.
            </h2>

            <h2>
              Selecciona la cantidad, el plazo y la recurrencia de pago para
              terminar tu solicitud.
            </h2>

            <div className="solicitud-credito__content">
              <section className="solicitud-credito__credito">
                <div className="solicitud-credito__amount solicitud-credito__item ">
                  <div className="solicitud-credito__recurrence solicitud-credito__item ">
                    <h4>¿Cuánto dinero necesitas?</h4>
                    <input
                      placeholder="3000"
                      type="text"
                      id="loanAmount"
                      name="loanAmount"
                      onChange={(e) => setLoanAmount(Number(e.target.value))}
                    />
                  </div>
                </div>
                <div className="solicitud-credito__termn solicitud-credito__item ">
                  <CustomSlider
                    headerText="¿A qué plazo?"
                    min={minTerm}
                    max={maxTerm}
                    minLabel={`${minTerm} ${recurrenceDisplay}`}
                    maxLabel={`${maxTerm} ${recurrenceDisplay}`}
                    step={step}
                    valueText={valueTerm}
                    defaultValue={defaultValue}
                    valueLabelFormat={(value: number) => value}
                  />
                </div>

                <div className="solicitud-credito__recurrence solicitud-credito__item ">
                  <h4>Selecciona tu recurrencia de pago</h4>

                  <CustomDropdown
                    placeholder={"Recurrencia"}
                    handleChange={handleChange}
                    value={recurrence}
                  >
                    <option aria-label="None" value="" />
                    <option value={"Quincenal"}>Quincenal</option>
                    <option value={"Semanal"}>Semanal</option>
                  </CustomDropdown>
                </div>
              </section>

              <section className="solicitud-credito__buttons">
                <button
                  className="blue"
                  onClick={handleUpdateLoan}
                  disabled={loading}
                >
                  <ProgressCircular
                    loading={loading}
                    title="Enviar solicitud"
                  />
                </button>
              </section>
            </div>
          </div>
          <img className="img-back-credito" src={ImgVector} />
        </div>
      </div>
    </div>
  );
}
